import { InjectionKey, ref, watch } from 'vue'
import { inject } from 'src/compositions/common'
import { refStore } from 'stores/__common'
import useUserDetails from 'stores/userDetails'
import useEnvironment from 'src/compositions/environment'

const appTopBannerKey: InjectionKey<ReturnType<typeof useAppTopBanner_>> = Symbol('qeepl_appTopBanner')

function useAppTopBanner_() {
  const { isAnonymous } = refStore(useUserDetails())
  const { app } = useEnvironment()

  const shown = ref<boolean>(isAnonymous.value && !app)

  const hide = () => shown.value = false

  watch(() => isAnonymous.value, newAnonymous => !newAnonymous && shown.value ? shown.value = false : void 0)

  return {
    shown,
    hide
  }
}

export default function useAppTopBanner() {
  return inject(appTopBannerKey, () => useAppTopBanner_())
}
