
import { computed, defineComponent, PropType, Ref, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { VueModel } from 'components/models'
import { QCard, QCardSection, QDialog, QResizeObserver, useQuasar } from 'quasar'
import CloseBtn from 'components/simple/close-btn.vue'
import { useI18n } from 'boot/i18n'

export default defineComponent({
  name: 'dialog-template',
  components: {
    CloseBtn,
    QDialog,
    QCard,
    QCardSection,
    QResizeObserver,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    contentClass: {
      default: ''
    },
    minHeight: {
      type: String,
      default: undefined
    },
    maxHeight: {
      type: String,
      default: undefined
    },
    minWidth: {
      type: String,
      default: '376px'
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: undefined
    },
    noPaddings: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    nonPersistent: {
      type: Boolean,
      default: false
    },
    enableBack: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 6000
    },
    confirmMessage: {
      type: String,
      required: false
    },
    iosPadding: {
      type: Boolean,
      default: true
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      required: false
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: false
    },
  },
  emits: [
    VueModel,
    'open',
    'close',
    'back'
  ],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { screen, dialog } = useQuasar()

    const cardRef = ref(null) as unknown as Ref<QCard>
    const cardSectionRef = ref(null) as unknown as Ref<QCardSection>
    const cardSectionStyle = ref()

    if (props.enableBack) {
      onBeforeRouteLeave((to, from, next) => {
        if (from.name !== to.name && from.path?.startsWith(to.path) && props.modelValue) {
          const action = (resolve: (value: void) => void) => {
            emitClose()
            setTimeout(() => {
              props.onConfirm?.()
              onBack()
              next()
              resolve()
            }, 300)
          }
          const cancel = (reject: () => void) => {
            props.onCancel?.()
            reject()
          }
          return new Promise((resolve, reject) => {
            if (props.confirmMessage) {
              showConfirm(
                () => action(resolve),
                () => cancel(reject)
              )
            } else {
              action(resolve)
            }
          })
        } else {
          next()
        }
      })
    }

    const showMobileLandscape = computed(() => screen.sm && screen.height < screen.width)
    const showMobile = computed(() => screen.xs || showMobileLandscape.value)

    const emitClose = () => emit(VueModel, false)
    const closeDialog = () => {
      props.confirmMessage ?
        showConfirm(
          () => {
            props.onConfirm?.()
            emitClose()
          },
          () => props.onCancel?.()
        )
        : emitClose()
    }
    const showConfirm = (onOk: () => void, onCancel: () => void = () => {
    }) => {
      dialog({
        title: props.confirmMessage,
        persistent: true,
        color: 'warning',
        html: true,
        ok: t('action.yes'),
        cancel: t('action.no'),
      })
        .onOk(() => onOk())
        .onCancel(() => onCancel())
    }
    const onOpen = () => {
      emit('open')
    }
    const onClose = () => {
      emit('close')
    }
    const onBack = () => {
      emit('back')
    }
    const onResize = () => {
      const cardHeight = cardRef.value?.$el?.clientHeight ?? 0
      const cardSectionHeight = cardSectionRef.value?.$el?.clientHeight ?? 0
      if (cardHeight && cardHeight !== cardSectionHeight) {
        if (cardHeight && cardSectionHeight > cardHeight && cardHeight >= screen.height - 48) {
          cardSectionStyle.value = { height: `${ cardHeight }px!important` }
        } else {
          cardSectionStyle.value = undefined
        }
      }
    }
    const onDialogResize = () => {
      cardSectionStyle.value = undefined
      onResize()
    }

    return {
      cardRef,
      cardSectionRef,
      showMobile,
      showMobileLandscape,
      cardSectionStyle,
      closeDialog,
      onOpen,
      onClose,
      onResize,
      onDialogResize,
    }
  }
})
