
import { computed, defineComponent } from 'vue'
import AttractionTemplate from 'components/template/attraction-template.vue'
import attractionPrefetch from 'src/compositions/prefetch/attractionPrefetch'
import { ATTRACTION_ALIAS } from 'pages/names'
import { useRouter } from 'vue-router'
import { ServiceTypeAlias } from 'src/model/common.model'
import { pathToKey } from 'src/functions/utils'

const name = ATTRACTION_ALIAS

export default defineComponent({
  name,
  components: {
    AttractionTemplate,
  },
  async preFetch(options) {
    await attractionPrefetch(options)
  },
  setup() {
    const { currentRoute } = useRouter()

    const path = computed(() => currentRoute.value.params.serviceTypeAlias as string)
    const name = computed(() => `attraction-${path.value}`)
    const alias = computed(() => pathToKey(path.value) as ServiceTypeAlias)

    return {
      name,
      alias
    }
  }
})
