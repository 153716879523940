import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12 col-md-9 col-lg-6 q-px-md" }
const _hoisted_2 = {
  key: 0,
  class: "text-grey-7 q-pb-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_content_selector = _resolveComponent("content-selector")!
  const _component_markdown = _resolveComponent("markdown")!
  const _component_error_handle_template = _resolveComponent("error-handle-template")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    "preview-dense": _ctx.$q.screen.xs,
    title: _ctx.t('global.pages.terms'),
    class: "terms",
    column: "",
    position: "start"
  }, {
    breadcrumbs: _withCtx(() => [
      _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
    ]),
    "after-preview": _withCtx(() => [
      _createVNode(_component_content_selector, {
        modelValue: _ctx.chosenFilter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chosenFilter) = $event)),
        options: _ctx.contentSelectorOptions,
        "btn-col-class": "col-sm-3 col-lg-2 col-xl-1 text-center q-pa-sm",
        class: "q-pt-lg col-auto",
        color: "accent"
      }, null, 8, ["modelValue", "options"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_error_handle_template, {
        success: !!_ctx.content.id,
        reload: _ctx.loadOne
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([{'q-pt-md': _ctx.$q.screen.gt.xs}, "row justify-center q-pb-xl q-mb-lg"])
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.content?.createdDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.fdf(_ctx.content.createdDate)), 1))
                : _createCommentVNode("", true),
              _createVNode(_Transition, {
                "enter-active-class": "animated fadeIn",
                "leave-active-class": "animated fadeOut",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock("div", {
                    key: _ctx.content.id
                  }, [
                    _createVNode(_component_markdown, { text: _ctx.body }, null, 8, ["text"])
                  ]))
                ]),
                _: 1
              })
            ])
          ], 2)
        ]),
        _: 1
      }, 8, ["success", "reload"])
    ]),
    _: 1
  }, 8, ["preview-dense", "title"]))
}