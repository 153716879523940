import { App } from 'vue'
import { Router } from 'vue-router'
import { Pinia } from 'pinia'
import { Notify } from 'quasar'
import { AxiosError, AxiosHeaders, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { Container } from 'src/model/common.model'
import useUserDetails from 'stores/userDetails'
import { matErrorOutline, matRefresh } from 'src/config/icons'

declare module 'pinia' {
  export interface Pinia {
    api: Api
  }
}

export type AxiosCallback<D> = ((res: AxiosResponse<D>) => void)

declare module 'axios' {
  export interface AxiosRequestConfig<D = any> {
    callback?: AxiosCallback<D>
  }
}

const addAuthorizationHeaders = (store: Pinia) => {
  return (config: InternalAxiosRequestConfig) => {
    const { token } = useUserDetails(store)
    if (token && !config.url?.startsWith('/_self')) {
      if (!config.headers) {
        config.headers = new AxiosHeaders()
      }
      config.headers.Authorization = `Bearer ${ token }`
    }
    return config
  }
}

export const setupAxiosInterceptors = (axios: AxiosInstance, app: App, router: Router, urlPath: string, store: Pinia) => {
  const errorConfigs: Container<InternalAxiosRequestConfig> = {}
  const onResponseError = (err: AxiosError) => {
    console.error(`Error occurred: ${ JSON.stringify(err) }`)
    const status = err.response?.status || 500
    if ((status === 403 || status === 401) && !err?.config?.url?.startsWith('/_self')) {
      return router.push(router.resolve({
        name: 'login',
        query: { returnURL: urlPath }
      }))
    }
    if (status === 406 || status === 429) {
      const { markUserBlocked } = useUserDetails(store)
      markUserBlocked()
    }
    if (status >= 500) {
      const $t = app.config.globalProperties.$t
      const id = `${ err.config?.method } ${ err.config?.url }`
      if (!errorConfigs[id]) {
        Notify.create({
          type: 'negative',
          message: $t('unhandled.title'),
          caption: $t('unhandled.description'),
          position: 'top',
          timeout: 10000,
          progress: true,
          icon: matErrorOutline,
          actions: [{
            icon: matRefresh,
            flat: true,
            color: 'white',
            size: 'lg',
            padding: 'xs',
            handler: () => {
              try {
                Object.values(errorConfigs)
                  .filter(c => !!c.callback)
                  .forEach(c => {
                    axios.request(c)
                      .then(res => c.callback!(res))
                  })
              } finally {
                Object.keys(errorConfigs).forEach(k => delete errorConfigs[k])
              }
            }
          }],
          onDismiss: () => {
            Object.keys(errorConfigs).forEach(k => delete errorConfigs[k])
          }
        })
      }
      errorConfigs[id] = err.config!
    }
    return Promise.reject(err)
  }
  if (axios.interceptors) {
    axios.interceptors.request.use(addAuthorizationHeaders(store))
    axios.interceptors.response.use(res => res, onResponseError)
  }
}

export interface Api {
  self: AxiosInstance
}
