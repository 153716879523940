
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import { QBtn } from 'quasar'
import IndexTemplate from 'components/template/index-template.vue'
import indexPrefetch from 'src/compositions/prefetch/indexPrefetch'
import { INDEX_ALIAS } from 'pages/names'
import { pathToKey } from 'src/functions/utils'
import { ServiceTypeAlias } from 'src/model/common.model'

const name = INDEX_ALIAS

export default defineComponent({
  name,
  components: {
    QBtn,
    IndexTemplate,
  },
  async preFetch(options) {
    await indexPrefetch(options)
  },
  setup() {
    const { currentRoute } = useRouter()

    const path = computed(() => currentRoute.value.params.serviceTypeAlias as string)
    const name = computed(() => `index-${ path.value }`)
    const alias = computed(() => pathToKey(path.value) as ServiceTypeAlias)

    return {
      name,
      alias
    }
  }
})
