import { ref } from 'vue'

interface ScriptLoadListener {
  onLoad: () => void
  onError: (error: any) => void
}

const scriptListeners: Record<string, ScriptLoadListener[]> = {}
const scriptLoaded: Record<string, boolean> = {}

export interface LoadScriptConfiguration {
  url: string
  id: string
  first?: boolean
}

export default function useLoadScript(configuration: LoadScriptConfiguration) {
  const loading = ref(false)

  const load = () => {
    if (window) {
      return new Promise<void>((resolve, reject) => {
        if (document.readyState === 'complete' || document.readyState === 'interactive') {
          doLoad(resolve, reject)
        } else {
          window.addEventListener('load', () => doLoad(resolve, reject))
        }
      })
    }
    return Promise.resolve()
  }

  const doLoad = (resolve: () => void, reject: () => void) => {
    if (scriptLoaded[configuration.id]) {
      resolve()
    } else {
      loading.value = true
      const listener: ScriptLoadListener = {
        onLoad: () => {
          loading.value = false
          resolve()
        },
        onError: (error) => {
          loading.value = false
          console.error(error)
          reject()
        }
      }
      if (scriptListeners[configuration.id]) {
        scriptListeners[configuration.id].push(listener)
      } else {
        scriptListeners[configuration.id] = [listener]
      }
      if (!exists()) {
        let scriptElement = document.createElement('script')
        scriptElement.setAttribute('id', configuration.id)
        scriptElement.setAttribute('src', configuration.url)
        scriptElement.setAttribute('async', 'true')
        scriptElement.setAttribute('defer', 'true')
        scriptElement.setAttribute('type', 'text/javascript')
        scriptElement.addEventListener('load', () => {
          scriptListeners[configuration.id]?.forEach(listener => listener.onLoad())
          scriptLoaded[configuration.id] = true
        })
        scriptElement.addEventListener('error', (event) => {
          scriptListeners[configuration.id]?.forEach(listener => listener.onError(event.error))
          reject()
        })
        if (configuration.first) {
          const firstScript = document.getElementsByTagName('script')[0]
          firstScript?.parentNode?.insertBefore(scriptElement, firstScript)
        } else {
          document.head.appendChild(scriptElement)
        }
      }
    }
  }

  const exists = () => {
    return !!document.getElementById(configuration.id)
  }

  const reload = () => {
    const element = document.getElementById(configuration.id)
    if (element) {
      element.parentNode?.removeChild(element)
    }
    return load()
  }

  return {
    loading,
    load,
    reload,
    exists,
  }
}
