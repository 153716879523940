import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_breadcrumbs_el = _resolveComponent("q-breadcrumbs-el")!
  const _component_q_breadcrumbs = _resolveComponent("q-breadcrumbs")!

  return (_openBlock(), _createBlock(_component_q_breadcrumbs, {
    align: "center",
    class: "breadcrumbs",
    i18n: _ctx.$options.name
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (v) => {
        return (_openBlock(), _createBlock(_component_q_breadcrumbs_el, {
          key: v.to,
          class: "text-grey-7 text-underline",
          label: v.label,
          to: v.to
        }, null, 8, ["label", "to"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["i18n"]))
}