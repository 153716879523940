
import { defineComponent } from 'vue'
import { useI18n } from 'boot/i18n'
import { QAvatar, QSkeleton, QToolbarTitle } from 'quasar'
import IconImage from 'components/simple/icon-image.vue'
import useRoutes from 'src/compositions/routes'

const name = 'toolbar-title'

export default defineComponent({
  name,
  components: {
    QToolbarTitle,
    QAvatar,
    QSkeleton,
    IconImage
  },
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const { toRoot } = useRoutes()
    const { language } = useI18n()

    return {
      language,
      toRoot
    }
  }
})
