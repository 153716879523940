
import { defineComponent, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import PageTemplate from 'components/template/page-template.vue'
import LargeBtn from 'components/simple/large-btn.vue'
import { useI18n$ } from 'boot/i18n'
import { CONTACT } from 'pages/names'
import useChat from 'src/compositions/chat'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import usePageExtensions from 'src/compositions/pageExtensions'
import basePrefetch from 'src/compositions/prefetch/basePrefetch'

const name = CONTACT

export default defineComponent({
  name,
  components: {
    PageTemplate,
    LargeBtn,
    Breadcrumbs
  },
  async preFetch(options) {
    await basePrefetch(options)
  },
  setup() {
    const { tp, t } = useI18n$(name)
    const { loading, loaded, open } = useChat()
    const { currentRoute } = useRouter()

    onMounted(() => {
      tryOpen()
    })

    const tryOpen = () => {
      if ('open' in currentRoute.value.query) {
        open()
      }
    }

    watch(loaded, newLoaded => newLoaded ? tryOpen() : void 0)

    return {
      t,
      tp,
      loading,
      loaded,
      open,
      ...usePageExtensions({
        page: name,
        breadcrumbs: () => ([
          { path: 'contact', label: t('global.pages.contact') }
        ])
      })
    }
  }
})
