import { PreFetchOptions } from '@quasar/app-webpack'
import useReviews from 'stores/reviews'
import useUserDetails from 'stores/userDetails'
import useAppData from 'stores/appData'

export default async function reviewsPrefetch(options: PreFetchOptions<any>) {
  const { language } = useUserDetails(options.store)
  const { loadNext } = useReviews(options.store)
  const { loadReviews } = useAppData(options.store)

  await Promise.all([
    loadNext(language),
    loadReviews(language),
  ])
}
