import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["alt", "height", "src", "width"]
const _hoisted_2 = { class: "col text-ellipsis" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.color ? `text-${ _ctx.color }` : '', "row items-center icon-label"])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          alt: _ctx.icon,
          class: _normalizeClass([{'q-mr-sm': !_ctx.noPadding && _ctx.$q.screen.width > 320, 'q-mr-xs': !_ctx.noPadding && _ctx.$q.screen.width <= 320}, "col-auto"]),
          height: _ctx.dimension,
          src: `/images/other/${ _ctx.icon }.${ _ctx.type }`,
          width: _ctx.dimension,
          style: _normalizeStyle({height: `${_ctx.dimension}px`, width: `${_ctx.dimension}px`}),
          loading: "lazy"
        }, null, 14, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("span", { innerHTML: _ctx.label }, null, 8, _hoisted_3)
      ])
    ])
  ], 2))
}