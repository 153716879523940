
import { computed, defineComponent } from 'vue'
import { QBtn } from 'quasar'
import PageTemplate from 'components/template/page-template.vue'
import BlogPreview from 'components/simple/blog-preview.vue'
import { useI18n$ } from 'boot/i18n'
import blogListPrefetch from 'src/compositions/prefetch/blogListPrefetch'
import useBlogListHome from 'stores/blogListHome'
import { refStore } from 'stores/__common'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import usePageExtensions from 'src/compositions/pageExtensions'
import { BLOG_LIST } from 'pages/names'

const name = BLOG_LIST

export default defineComponent({
  name,
  components: {
    QBtn,
    PageTemplate,
    BlogPreview,
    Breadcrumbs
  },
  async preFetch (options) {
    await blogListPrefetch(options)
  },
  setup () {
    const { page, noMore, loading, loadNext } = refStore(useBlogListHome())
    const {
      language,
      tp,
      t
    } = useI18n$(name)

    const list = computed(() => page.value.data)

    const doLoadNext = () => {
      loadNext(language.value)
    }

    return {
      list,
      noMore,
      loading,
      loadNext: doLoadNext,
      tp,
      t,
      ...usePageExtensions({
        page: name,
        breadcrumbs: () => ([
          { path: 'blog', label: t('global.pages.blog') }
        ])
      })
    }
  }
})
