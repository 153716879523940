import { Ref, ref } from 'vue'

export interface UseSelectOptions<T> {
  minTextFilterCount?: number
  initOptions?: () => Promise<T[]>
  filterOptions?: (text: string, options: T[]) => Promise<T[]>
}

export default function useSelect<T>(useSelectOptions: UseSelectOptions<T>) {
  if (!useSelectOptions.initOptions && !useSelectOptions.filterOptions) {
    throw new Error('initOptions or filterOptions must be set')
  }

  const allOptions = ref<T[]>([])
  const internalOptions = ref<T[]>([])
  const loadingAllOptions = ref(false)
  const loadOptionsOccurred = ref(false)

  const updateOptions = (newOptions: T[]) => {
    // @ts-ignore
    internalOptions.value = newOptions
  }
  const doLoadOptions = () => {
    if (useSelectOptions.initOptions) {
      loadingAllOptions.value = true
      return useSelectOptions.initOptions()
        .finally(() => loadingAllOptions.value = false)
    }
    return Promise.resolve([])
  }

  const doSetInitValues = (res?: T[]) => {
    // @ts-ignore
    allOptions.value = res || []
    updateOptions(allOptions.value as T[])
  }

  const loadOptions = (): Promise<void> => {
    return doLoadOptions()
      .then(doSetInitValues)
  }

  const filterOptions = (text: string, update: (exec: () => void) => void, abort: () => void) => {
    const minTextFilterCount = useSelectOptions.minTextFilterCount === undefined ? 3 : useSelectOptions.minTextFilterCount
    if (!text && useSelectOptions.initOptions) {
      if (allOptions.value.length) {
        update(() => updateOptions(allOptions.value as T[]))
      } else {
        doLoadOptions()
          .then(res => (update(() => doSetInitValues(res))))
      }
    } else if (text.length >= minTextFilterCount && useSelectOptions.filterOptions) {
      useSelectOptions.filterOptions!(text, allOptions.value as T[])
        .then((res: T[]) => {
          update(() => {
            loadOptionsOccurred.value = true
            updateOptions(res || [])
          })
        })
    } else {
      abort()
    }
  }

  return {
    allOptions,
    options: internalOptions as Ref<T[]>,
    loadOptionsOccurred,
    loadingAllOptions,
    loadOptions,
    filterOptions,
  }
}
