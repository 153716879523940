import { ref } from 'vue'
import { defineStore } from 'pinia'
import { AnonymousCompanyProjection } from 'src/model/company.model'

const useCompaniesMap = defineStore('companiesMap', () => {
  const openCompany = ref<AnonymousCompanyProjection | null>(null)

  const setOpenCompany = (newOpenCompany: AnonymousCompanyProjection | null) => {
    openCompany.value = newOpenCompany
  }

  return {
    openCompany,
    setOpenCompany
  }
})

export default useCompaniesMap
