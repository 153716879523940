import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_phone_input = _resolveComponent("phone-input")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_login_check_code = _resolveComponent("login-check-code")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isPhone)
      ? (_openBlock(), _createBlock(_component_phone_input, {
          key: 0,
          modelValue: _ctx.phone,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
          valid: _ctx.validPhone,
          "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.validPhone) = $event)),
          class: "login-input-check",
          checked: _ctx.checkSuccess,
          "default-country": _ctx.countryKey,
          disable: _ctx.disable || _ctx.blocked,
          hint: _ctx.hint,
          i18n: _ctx.$options.name,
          readonly: _ctx.readonly,
          required: _ctx.required
        }, null, 8, ["modelValue", "valid", "checked", "default-country", "disable", "hint", "i18n", "readonly", "required"]))
      : (_openBlock(), _createBlock(_component_q_input, {
          key: 1,
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
          label: 'Email',
          "lazy-rules": true,
          rules: _ctx.validations.email.rules,
          autocomplete: "email",
          hint: _ctx.hint,
          clearable: "",
          "hide-bottom-space": "",
          name: "email",
          outlined: "",
          type: "email"
        }, null, 8, ["modelValue", "rules", "hint"])),
    _createVNode(_component_q_no_ssr, null, {
      default: _withCtx(() => [
        (_ctx.requestDialogLoading)
          ? (_openBlock(), _createBlock(_component_login_check_code, {
              key: 0,
              modelValue: _ctx.dialogModel,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogModel) = $event)),
              phone: _ctx.phone,
              email: _ctx.email,
              onError: _ctx.onError,
              onExpired: _ctx.onExpired,
              onSuccess: _ctx.onSuccess
            }, null, 8, ["modelValue", "phone", "email", "onError", "onExpired", "onSuccess"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}