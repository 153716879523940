
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue'
import PageTemplate from 'components/template/page-template.vue'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import useFormat from 'src/compositions/format'
import { QBtn } from 'quasar'
import termsPrefetch from 'src/compositions/prefetch/termsPrefetch'
import useTermsHome from 'stores/termsHome'
import ContentSelector from 'components/simple/content-selector.vue'
import { refStore } from 'stores/__common'
import { SelectorOption } from 'src/model/common.model'
import usePageExtensions from 'src/compositions/pageExtensions'
import { TERMS } from 'pages/names'
import ErrorHandleTemplate from 'components/template/error-handle-template.vue'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'
import { matArrowBack } from 'src/config/icons'

const name = TERMS

type FilterName = 'client' | 'partner'
const FilterNames: FilterName[] = ['client', 'partner']

const Markdown = defineAsyncComponent(() => import(/* webpackChunkName: "markdown", webpackMode: "lazy" */'components/simple/markdown.vue'))

export default defineComponent({
  name,
  components: {
    QBtn,
    PageTemplate,
    Markdown,
    ContentSelector,
    Breadcrumbs,
    ErrorHandleTemplate,
  },
  async preFetch(options) {
    await termsPrefetch(options)
  },
  setup() {
    const {
      terms: termsHome,
      clientType,
      loadOne
    } = refStore(useTermsHome())
    const {
      tp,
      st,
      t
    } = useI18n$(name)
    const { fdf } = useFormat()
    const { toPrevPage, } = useRoutes()
    const { countryKey } = useUserPreviewExtension()

    const chosenFilter = ref<FilterName>(clientType.value ? 'client' : 'partner')

    const contentSelectorOptions = computed<SelectorOption[]>(() => {
      return FilterNames.map(fn => ({
        value: fn,
        label: tp(`filter.${ fn }`)
      }))
    })

    const terms = computed(() => termsHome.value.required!)
    const body = computed(() => st(termsHome.value.body))

    watch(chosenFilter, newChosenFilter => loadOne(countryKey.value, newChosenFilter === 'client'))
    watch(countryKey, newCountryKey => loadOne(newCountryKey, chosenFilter.value === 'client'))

    return {
      matArrowBack,
      terms,
      content: termsHome,
      body,
      chosenFilter,
      contentSelectorOptions,
      tp,
      t,
      st,
      fdf,
      toPrevPage,
      loadOne: () => loadOne(countryKey.value, chosenFilter.value === 'client'),
      ...usePageExtensions({
        page: name,
        breadcrumbs: () => ([{
          path: 'terms',
          label: t('global.pages.terms')
        }])
      })
    }
  }
})
