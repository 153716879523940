import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: _normalizeClass([{'close-btn__overflow': !_ctx.noOverflow}, "absolute-top-right shadow-5 text-grey-7 close-btn"]),
    disable: _ctx.disable,
    icon: _ctx.matClose,
    to: _ctx.to,
    color: "white",
    padding: "xs",
    rounded: "",
    size: "lg",
    onClick: _ctx.onClick
  }, null, 8, ["class", "disable", "icon", "to", "onClick"]))
}