import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "fit date-time-button overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_btn = _resolveComponent("icon-btn")!
  const _component_date_time_dialog = _resolveComponent("date-time-dialog")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.single)
      ? (_openBlock(), _createBlock(_component_icon_btn, {
          key: 0,
          "bg-color": _ctx.bgColor,
          dense: _ctx.$q.screen.xs,
          disable: _ctx.disable,
          label: _ctx.datesSingleLabel,
          padding: _ctx.padding,
          icon: "calendar-date",
          onClick: _ctx.openDialog
        }, null, 8, ["bg-color", "dense", "disable", "label", "padding", "onClick"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["row date-time-button__container items-center", {'date-time-button__container--large': _ctx.noCheckout}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([{'col-auto': _ctx.dense, 'col-6': !_ctx.dense}, "q-pr-sm"])
          }, [
            _renderSlot(_ctx.$slots, "before-check-in"),
            _createVNode(_component_icon_btn, {
              "bg-color": _ctx.bgColor,
              "description-label": _ctx.checkInTimeRangeLabel,
              disable: _ctx.disable || _ctx.checkinDisabled,
              label: _ctx.checkInDateLabel,
              padding: _ctx.padding,
              icon: "calendar-date",
              onClick: _ctx.openDialog
            }, null, 8, ["bg-color", "description-label", "disable", "label", "padding", "onClick"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([{'col-auto': _ctx.dense, 'col-6': !_ctx.dense}, "q-pl-sm"])
          }, [
            _renderSlot(_ctx.$slots, "before-check-out"),
            _createVNode(_component_icon_btn, {
              "bg-color": _ctx.bgColor,
              "description-label": _ctx.checkOutTimeRangeLabel,
              disable: _ctx.disable,
              label: _ctx.checkOutDateLabel,
              padding: _ctx.padding,
              icon: "calendar-date",
              onClick: _ctx.openDialog
            }, null, 8, ["bg-color", "description-label", "disable", "label", "padding", "onClick"])
          ], 2)
        ], 2)),
    _createVNode(_component_q_no_ssr, null, {
      default: _withCtx(() => [
        (_ctx.requestDialogLoading)
          ? (_openBlock(), _createBlock(_component_date_time_dialog, {
              key: 0,
              modelValue: _ctx.dialogModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogModel) = $event)),
              timezone: _ctx.timezone,
              holidays: _ctx.holidays,
              "checkin-disabled": _ctx.checkinDisabled,
              "disabled-until": _ctx.disabledUntil
            }, null, 8, ["modelValue", "timezone", "holidays", "checkin-disabled", "disabled-until"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}