import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-center q-px-sm" }
const _hoisted_2 = {
  key: 0,
  class: "row items-center justify-center q-px-md q-pb-xl"
}
const _hoisted_3 = { class: "col-12 col-md-10 col-lg-7" }
const _hoisted_4 = { class: "col-4 col-sm-2 text-weight-bold" }
const _hoisted_5 = { class: "col-8 col-sm-10" }
const _hoisted_6 = { class: "row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_object_card = _resolveComponent("object-card")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_home_page_template = _resolveComponent("home-page-template")!

  return (_openBlock(), _createBlock(_component_home_page_template, {
    companies: _ctx.companies,
    "companies-title": _ctx.tp('companiesTitle'),
    description: _ctx.tp('description', _ctx.params),
    "faq-entries": _ctx.faqEntries,
    title: _ctx.tp('title', _ctx.params),
    type: _ctx.type
  }, {
    "after-preview": _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.main5Cities, (c, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: c.key,
              class: _normalizeClass([{'gt-sm': i === 4}, "col-6 col-sm-3 col-md-2 q-pa-sm"])
            }, [
              _createVNode(_component_object_card, {
                src: c.photoURL,
                title: _ctx.st(c.name),
                to: _ctx.toCity(c),
                "fallback-src": "/images/city-fallback.jpg"
              }, null, 8, ["src", "title", "to"])
            ], 2))
          }), 128))
        ])
      ])
    ]),
    "before-how-to": _withCtx(() => [
      _renderSlot(_ctx.$slots, "before-how-to")
    ]),
    "pre-faq": _withCtx(() => [
      _renderSlot(_ctx.$slots, "pre-faq")
    ]),
    "post-articles": _withCtx(() => [
      (_ctx.alias !== 'ALL')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (_ctx.countryCities[country.key]?.length)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: country.key,
                        class: "row items-center q-pt-md"
                      }, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.st(country.name)), 1),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryCities[country.key], (city) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: city.key,
                                class: "col-auto q-pa-sm"
                              }, [
                                _createVNode(_component_router_link, {
                                  to: _ctx.toCity(city.key),
                                  class: "text-grey-8 text-underline"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.st(city.name)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]))
                            }), 128))
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["companies", "companies-title", "description", "faq-entries", "title", "type"]))
}