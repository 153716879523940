import { Request } from 'express'
import { boot } from 'quasar/wrappers'
import useUserLocation from 'stores/userLocation'

export default boot(async ({ store, ssrContext }) => {
  const { setInitialLocation } = useUserLocation(store)
  const req = ssrContext?.req as Request | undefined
  if (req) {
    const countryKey = req.headers['cloudfront-viewer-country'] as string
    const latitude = req.headers['cloudfront-viewer-latitude'] as string
    const longitude = req.headers['cloudfront-viewer-longitude'] as string
    if (countryKey && latitude && longitude) {
      setInitialLocation({
        point: {
          longitude: parseFloat(longitude),
          latitude: parseFloat(latitude),
        },
        countryKey
      })
    }
  }
})
