import { ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { Content } from 'src/model/content.model'
import { Language } from 'src/model/language.model'

const useBlogHome = defineStore('blogHome', () => {
  const api = useAnonymousApi()

  const blog = ref<Content>(null as unknown as Content)

  const loadOne = async (path: string, language: Language) => {
    try {
      const blogResponse = await api.getBlogContent(path)
      if (!blogResponse.body[language]) {
        return Promise.reject({ code: 404 })
      }
      blog.value = blogResponse
    } catch (e: any) {
      return Promise.reject({ code: 404 })
    }
  }

  return {
    blog,
    loadOne
  }
})

export default useBlogHome
