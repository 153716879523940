import { AnonymousCompanyProjection } from 'src/model/company.model'
import useCompanyCache from 'src/compositions/cache/companyCache'
import { computed } from 'vue'
import { useI18n$ } from 'boot/i18n'

const i18Name = 'company-rating'

export default function useCompanyRating(company: AnonymousCompanyProjection) {
  const { tc, t } = useI18n$(i18Name)
  const { totalReview } = useCompanyCache()

  const totalReviewValue = computed(() => totalReview[company.id!])
  const ratingLabel = computed(() => {
    const total = totalReviewValue.value
    if (total) {
      if (total.count === 0) {
        return t('global.no.reviews')
      }
      return tc('short', { reviewsCount: total.count, value: total.value }, total.count)
    } else {
      return null
    }
  })
  const shortRatingLabel = computed(() => {
    const total = totalReviewValue.value
    if (total && total.count > 0) {
      return total.value.toString()
    } else {
      return null
    }
  })

  return {
    totalReviewValue,
    ratingLabel,
    shortRatingLabel
  }
}
