import { InjectionKey, ref } from 'vue'
import { inject } from 'src/compositions/common'

const drawerKey: InjectionKey<ReturnType<typeof useDrawer_>> = Symbol('qeepl_drawer')

function useDrawer_() {
  const opened = ref(false)

  const open = () => opened.value = true
  const close = () => opened.value = false
  const toggle = () => opened.value = !opened.value

  return {
    opened,
    open,
    close,
    toggle
  }
}

export default function useDrawer() {
  return inject(drawerKey, () => useDrawer_())
}
