
import { computed, defineComponent, onMounted } from 'vue'
import DialogTemplate from 'components/template/dialog-template.vue'
import LargeBtn from 'components/simple/large-btn.vue'
import useDialog from 'src/compositions/dialog'
import { QIcon, QSpinner } from 'quasar'
import useAsync from 'src/compositions/async'
import { useRoute, useRouter } from 'vue-router'
import useRoutes from 'src/compositions/routes'
import { useI18n$ } from 'boot/i18n'
import { PAYMENT__SUCCESS } from 'pages/names'
import useAnonymousApi from 'src/api/anonymous.api'

const name = PAYMENT__SUCCESS

export default defineComponent({
  name,
  components: {
    QIcon,
    QSpinner,
    DialogTemplate,
    LargeBtn
  },
  setup() {
    const { checkPayment: doCheckPayment } = useAnonymousApi()
    const { dialogModel } = useDialog()
    const { query } = useRoute()
    const { push } = useRouter()
    const { toMyOrdersOpenInstruction, toMyOrdersOpen } = useRoutes()
    const { tp } = useI18n$(name)

    onMounted(() => {
      checkPayment()
    })

    const { result: checkSuccess, running: checking, run: checkPayment } = useAsync(
      () => doCheckPayment(query.orderId as string)
    )
    const result = computed(() => checkSuccess.value ? 'success' : 'error')

    const toMyOrdersOpenPage = () => {
      return checkSuccess.value ?
        push(toMyOrdersOpenInstruction(query.orderId as string, true)) :
        push(toMyOrdersOpen(query.orderId as string))
    }

    return {
      dialogModel,
      checking,
      result,
      toMyOrdersOpenPage,
      tp
    }
  }
})
