import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!

  return (_openBlock(), _createBlock(_component_q_btn_toggle, {
    "model-value": _ctx.modelValue,
    options: _ctx.options,
    "toggle-color": _ctx.toggleColor,
    "toggle-text-color": _ctx.toggleTextColor,
    color: _ctx.color,
    "text-color": _ctx.textColor,
    class: "tariff-buttons",
    size: "sm",
    unelevated: "",
    "no-caps": "",
    rounded: "",
    i18n: _ctx.$options.name,
    "onUpdate:modelValue": _ctx.onModelValueUpdated
  }, null, 8, ["model-value", "options", "toggle-color", "toggle-text-color", "color", "text-color", "i18n", "onUpdate:modelValue"]))
}