import { RouteLocationNormalizedLoaded } from 'vue-router'
import useGeography from 'stores/geography'
import useUserPreview from 'stores/userPreview'
import useAppData from 'stores/appData'
import { PreFetchOptions } from 'app/node_modules/@quasar/app-webpack'
import useUserLocation from 'stores/userLocation'
import useUserDetails from 'stores/userDetails'
import { Error404, ServiceTypeAlias, ServiceTypeAliases } from 'src/model/common.model'
import { isPath, pathToKey } from 'src/functions/utils'
import { ERROR_404 } from 'pages/names'

export default async function indexPrefetch(options: PreFetchOptions<any>, serviceTypeAlias?: ServiceTypeAlias) {
  const previewState = useUserPreview(options.store)
  const detailsState = useUserDetails(options.store)
  const locationState = useUserLocation(options.store)
  const geographyState = useGeography(options.store)
  const appDataState = useAppData(options.store)

  const path = options.currentRoute.params.serviceTypeAlias as string
  const key = pathToKey(path) as ServiceTypeAlias
  serviceTypeAlias = serviceTypeAlias || key

  if (
    !ServiceTypeAliases.includes(serviceTypeAlias) ||
    ['LUGGAGE', 'SELF'].includes(key) ||
    !(path ? isPath(path) : true)
  ) {
    if (options.ssrContext) {
      throw new Error404()
    } else {
      options.redirect({ name: ERROR_404 })
      return
    }
  }

  await Promise.all([
    previewState.loadAllNearest(locationState.location),
    geographyState.loadAll(),
    appDataState.loadReviews(detailsState.language),
    appDataState.loadBagsCount(),
  ])
}
