import { PropType } from 'vue'
import { AsyncRunnable, ErrorHandler, Runnable, SuccessHandler } from 'src/types/async'

export default {
  runnable: {
    type: Function as PropType<AsyncRunnable | Runnable>,
    default: () => Promise.resolve()
  },
  successHandler: {
    type: Function as PropType<SuccessHandler>,
    required: false
  },
  errorHandler: {
    type: Function as PropType<ErrorHandler>,
    required: false
  },
}
