import { ref } from 'vue'
import useAnonymousApi from 'src/api/anonymous.api'
import { defineStore } from 'pinia'
import { Content } from 'src/model/content.model'
import { ServiceTypeAlias } from 'src/model/common.model'

const useAttractionHome = defineStore('attractionHome', () => {
  const api = useAnonymousApi()

  const content = ref<Content | null>(null)

  const onAttractionLoaded = (attractionContent: Content | null) => {
    content.value = attractionContent
  }
  const loadAll = async (key: string, serviceTypeAlias: ServiceTypeAlias) => {
    onAttractionLoaded(await api.getAttractionContent(key, serviceTypeAlias, res => onAttractionLoaded(res.data)).catch(() => null))
  }

  return {
    content,
    loadAll
  }
})

export default useAttractionHome
