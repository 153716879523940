import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["i18n"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'text-small': _ctx.dense, 'disabled cursor-not-allowed': _ctx.disabled}, "row items-center text-center text-grey-7 service-conditions"]),
    i18n: _ctx.$options.name
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["col-12", {'no-pointer-events': _ctx.disabled}])
    }, [
      _createTextVNode(_toDisplayString(_ctx.tp('youAgree')) + " ", 1),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      (_ctx.app)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: `${ _ctx.siteURL }${ _ctx.termsURL }`,
            class: "text-grey-7",
            target: "_blank"
          }, _toDisplayString(_ctx.tp('terms')), 9, _hoisted_2))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: _ctx.termsURL,
            class: "text-grey-7",
            exact: "",
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.tp('terms')), 1)
            ]),
            _: 1
          }, 8, ["to"])),
      _createTextVNode(" " + _toDisplayString(_ctx.tp('and')) + " ", 1),
      (_ctx.app)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            href: `${ _ctx.siteURL }${ _ctx.policyURL }`,
            class: "text-grey-7",
            target: "_blank"
          }, _toDisplayString(_ctx.tp('policy')), 9, _hoisted_3))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 3,
            to: _ctx.policyURL,
            class: "text-grey-7",
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.tp('policy')), 1)
            ]),
            _: 1
          }, 8, ["to"]))
    ], 2)
  ], 10, _hoisted_1))
}