import { InjectionKey, shallowReactive } from 'vue'
import { inject } from 'src/compositions/common'
import { Container } from 'src/model/common.model'
import useCompanyCache from 'src/compositions/cache/companyCache'
import { AnonymousCompanyProjection } from 'src/model/company.model'

const companyVisibilityKey: InjectionKey<ReturnType<typeof useCompanyVisibility_>> = Symbol('qeepl_companyVisibility')

function useCompanyVisibility_() {
  const {
    loadOccupied,
    loadTotalReview,
    loadLatestOrderOrLastTwoWeeksOrderCountHours,
    clearOccupied,
    occupied
  } = useCompanyCache()

  const visibleCompanies = shallowReactive<Container<boolean>>({})

  const markVisible = (company: AnonymousCompanyProjection) => {
    const id = company.id!
    if (!visibleCompanies[id]) {
      visibleCompanies[id] = true
      loadOccupied(id)
      loadTotalReview(id)
      loadLatestOrderOrLastTwoWeeksOrderCountHours(id)
    }
  }
  const markNonVisible = (company: AnonymousCompanyProjection) => {
    if (visibleCompanies[company.id!]) {
      delete visibleCompanies[company.id!]
    }
  }
  const reloadOccupiedCache = () => {
    clearOccupied()
    Object.keys(visibleCompanies).forEach(companyId => loadOccupied(companyId))
  }

  return {
    occupied,
    markVisible,
    markNonVisible,
    reloadOccupiedCache,
  }
}

export default function useCompanyVisibility() {
  return inject(companyVisibilityKey, () => useCompanyVisibility_())
}
