
import { computed, defineComponent } from 'vue'
import { useI18n } from 'boot/i18n'
import { QBtn, QItem, QItemLabel, QItemSection, QNoSsr, useQuasar } from 'quasar'
import useRoutes from 'src/compositions/routes'
import useUserDetails from 'stores/userDetails'
import { refStore } from 'stores/__common'
import { useRouter } from 'vue-router'
import { PARTNERS } from 'pages/names'

const name = 'page-links'

export default defineComponent({
  name,
  components: {
    QNoSsr,
    QItem,
    QItemSection,
    QItemLabel,
    QBtn
  },
  props: {
    btnClass: {
      type: String,
      default: ''
    },
  },
  emits: ['click'],
  setup() {
    const { isUser, isPartner, isAnonymous, isOnboardingUser } = refStore(useUserDetails())
    const { toMyOrders, toPartners, toMyBusiness, toOnboarding } = useRoutes()
    const { currentRoute } = useRouter()
    const { screen } = useQuasar()
    const { t } = useI18n()

    const show = computed(() => {
      return isPartner.value || isOnboardingUser.value || isUser.value || (isAnonymous.value && screen.gt.xs && currentRoute.value.name !== PARTNERS)
    })
    const label = computed(() => {
      if (isPartner.value) {
        return t('global.pages.cabinet')
      } else if (isOnboardingUser.value) {
        return t('action.continueOnboarding')
      } else if (isUser.value) {
        return t('global.pages.orders')
      } else if (isAnonymous.value && screen.gt.xs && currentRoute.value.name !== PARTNERS) {
        return t('partners.joinUs')
      } else {
        return ''
      }
    })
    const to = computed(() => {
      if (isPartner.value) {
        return toMyBusiness()
      } else if (isOnboardingUser.value) {
        return toOnboarding()
      } else if (isUser.value) {
        return toMyOrders()
      } else if (isAnonymous.value && screen.gt.xs) {
        return toPartners()
      } else {
        return ''
      }
    })

    return {
      show,
      label,
      to
    }
  }
})
