export type Language = 'en' | 'ru' | 'it' | 'es' | 'de' | 'fr' | 'zh' | 'ja' | 'ko' | 'tr' | 'pt';

export const Languages: Language[] = ['en', 'ru', 'it', 'es', 'de', 'fr', 'zh', 'ja', 'ko', 'tr', 'pt']

export const DEFAULT_LANGUAGE: Language = 'en'

export interface LanguageContainer<T> {
  en: T;

  [key: string]: T;
}

export namespace Language {
  export const DEFAULT = DEFAULT_LANGUAGE
}

export namespace LanguageContainer {
  export function extract<T>(languageContainer: LanguageContainer<T>, language: Language): T {
    return languageContainer[language] || languageContainer[DEFAULT_LANGUAGE]
  }
}
