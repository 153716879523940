import { getCurrentInstance, inject as vueInject } from 'vue'
import { InjectionKey } from '@vue/runtime-core'

export function inject<T>(key: InjectionKey<T> | string, factory: () => T): T {
  let instance = vueInject(key, null as unknown) as T | null
  if (!instance) {
    const app = getCurrentInstance()?.appContext?.app
    if (!app) {
      throw new Error('inject can be used inside setup() function only!')
    }
    instance = factory()
    app.provide(key, instance)
  }
  return instance
}

export function injectSafe<T>(key: InjectionKey<T> | string, factory: () => T): T {
  let instance = vueInject(key, null as unknown) as T | null
  if (!instance) {
    const app = getCurrentInstance()?.appContext?.app
    instance = factory()
    if (app) {
      app.provide(key, instance)
    }
  }
  return instance
}
