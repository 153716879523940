import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["i18n"]
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = { class: "col-auto q-ml-sm qeepl-rating__text-col" }
const _hoisted_4 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_rating = _resolveComponent("q-rating")!

  return (_ctx.totalReview)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        i18n: _ctx.$options.name,
        class: "text-center row items-center justify-center qeepl-rating"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_rating, {
            modelValue: _ctx.totalReview.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.totalReview.value) = $event)),
            icon: "img:/images/other/rating-unselect.svg",
            "icon-selected": "img:/images/other/rating.svg",
            "icon-half": "img:/images/other/rating-half.svg",
            readonly: "",
            class: _normalizeClass({'qeepl-rating__fill': _ctx.fill}),
            size: _ctx.size
          }, null, 8, ["modelValue", "class", "size"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.dense)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("strong", null, _toDisplayString(`${ _ctx.totalReview.value } `), 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.tp('count', _ctx.totalReview)), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("strong", null, _toDisplayString(`${ _ctx.tp('full.value', _ctx.totalReview) } `), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.tp('full.count', _ctx.totalReview)), 1)
              ], 64))
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}