
import { defineComponent, PropType } from 'vue'
import { QBreadcrumbs, QBreadcrumbsEl } from 'quasar'
import { BreadcrumbEntry } from 'src/model/common.model'

export default defineComponent({
  name: 'breadcrumbs',
  components: {
    QBreadcrumbs,
    QBreadcrumbsEl
  },
  props: {
    values: {
      type: Array as PropType<BreadcrumbEntry[]>,
      required: true
    },
  },
  setup() {
  }
})
