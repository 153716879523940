
import { defineComponent, PropType } from 'vue'
import { VueModel } from 'components/models'
import { QBtn, QTab, QTabs } from 'quasar'
import { SelectorOption } from 'src/model/common.model'

const name = 'content-selector'

export default defineComponent({
  name,
  components: {
    QBtn,
    QTabs,
    QTab
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Array as PropType<SelectorOption[]>,
      required: true
    },
    justify: {
      type: String,
      default: 'center'
    },
    color: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String,
      default: 'white'
    },
    btnColClass: {
      default: 'col-auto'
    },
    btnOnly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  emits: [VueModel],
  setup (props, { emit }) {
    const updateModel = (newModelValue: string) => emit(VueModel, newModelValue)

    return {
      updateModel
    }
  }
})
