import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-center fit" }
const _hoisted_2 = { class: "col-12 col-xl-10 full-height companies-map__main-col" }
const _hoisted_3 = { class: "row items-stretch fit" }
const _hoisted_4 = { class: "col-12 col-sm-6 col-md-7 col-lg-9 col-xl-9 full-height relative-position companies-map__map-col" }
const _hoisted_5 = { class: "col-12 col-sm-12 col-md col-lg-8 col-xl-6" }
const _hoisted_6 = { class: "row items-center q-col-gutter-x-lg" }
const _hoisted_7 = { class: "col-12 col-sm-auto text-right" }
const _hoisted_8 = {
  key: 0,
  class: "col-12 q-pt-lg row justify-center"
}
const _hoisted_9 = { class: "col-0 col-sm-6 col-md-5 col-lg-3 col-xl-3 full-height overflow-hidden companies-map__companies-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apple_web_map = _resolveComponent("apple-web-map")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_location_select = _resolveComponent("location-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    class: "companies-map",
    "no-chat": "",
    "no-footer": "",
    "no-overflow": ""
  }, {
    page: _withCtx(() => [
      _createVNode(_component_q_page, {
        "style-fn": (offset, height) => ({ height: `${ height - (_ctx.$q.screen.sm ? 50 : offset) }px` })
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_no_ssr, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_apple_web_map, {
                        ref: "webMapRef",
                        "chosen-company": _ctx.chosenCompany,
                        "user-location": _ctx.location,
                        center: _ctx.center,
                        companies: _ctx.filteredCompanies,
                        "unavailable-companies": _ctx.nonFilteredCompanies,
                        paddings: _ctx.paddings,
                        "no-path": "",
                        onChoose: _ctx.setOrOpenCompany
                      }, null, 8, ["chosen-company", "user-location", "center", "companies", "unavailable-companies", "paddings", "onChoose"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_no_ssr, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["row items-center absolute-top companies-map__location-select--container", {'justify-end': _ctx.$q.screen.sm}])
                      }, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_location_select, {
                            ref: "locationSelectRef",
                            class: "all-pointer-events",
                            "no-leave-button": ""
                          }, null, 512)
                        ]),
                        _createElementVNode("div", {
                          class: "col-auto companies-map__buttons--container no-pointer-events",
                          style: _normalizeStyle(_ctx.buttonsContainerBottom)
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_q_btn, {
                                class: "shadow-10 text-grey-7 z-fab col-12 all-pointer-events",
                                color: "white",
                                rounded: "",
                                padding: "sm",
                                size: "lg",
                                icon: _ctx.matMyLocation,
                                onClick: _cache[0] || (_cache[0] = () => _ctx.resolveGpsLocation())
                              }, null, 8, ["icon"])
                            ])
                          ])
                        ], 4),
                        _createVNode(_Transition, {
                          mode: "out-in",
                          "enter-active-class": "animated fadeIn",
                          "leave-active-class": "animated fadeOut"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.showReloadButton)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  _createVNode(_component_q_btn, {
                                    class: "col-auto text-primary bg-white all-pointer-events text-transform-none shadow-10 companies-map__location-select--reload",
                                    flat: "",
                                    icon: _ctx.matRefresh,
                                    size: "md",
                                    padding: "sm md",
                                    rounded: "",
                                    label: _ctx.tp('search'),
                                    onClick: _ctx.updateLocation
                                  }, null, 8, ["icon", "label", "onClick"])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ], 2)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_router_view)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["style-fn"])
    ]),
    _: 1
  }))
}