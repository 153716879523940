
import { computed, defineComponent, nextTick, PropType, ref, Ref, watch } from 'vue'
import { Phone, PhoneOption } from 'src/model/common.model'
import useSelect from 'src/compositions/select'
import { useI18n$ } from 'boot/i18n'
import { Language } from 'src/model/language.model'
import { ValidModel, VueModel } from 'components/models'
import { QIcon, QInput, QItem, QItemSection, QNoSsr, QSelect, QSkeleton } from 'quasar'
import useValidation from 'src/compositions/validation/validation'
import useValidationRules from 'src/compositions/validation/validationRules'
import { DEFAULT_COUNTRY_KEY } from 'src/model/constants'
import { formatPhone } from 'src/functions/formatting'
import { filterLowerCase } from 'src/functions/utils'

async function doLoadPhoneOptions (language: Language): Promise<PhoneOption[]> {
  const module = await import(/* webpackChunkName: "phone-data" */'src/modules/phone-data')
  return module.loadPhoneOptions(language)
}

const EMPTY: PhoneOption = {} as PhoneOption

const name = 'phone-input'

const matDone = 'M0 0h24v24H0z@@fill:none;&&M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'
const matBlock = 'M0 0h24v24H0z@@fill:none;&&M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z'

export default defineComponent({
  name,
  components: {
    QNoSsr,
    QInput,
    QItem,
    QItemSection,
    QSelect,
    QSkeleton,
    QIcon
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    validValue: {
      type: Boolean,
      default: true
    },
    defaultCountry: {
      type: String,
      default: DEFAULT_COUNTRY_KEY
    },
    disable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean as PropType<boolean | null>,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
  },
  emits: [VueModel, ValidModel, 'blur'],
  setup (props, { emit }) {
    const {
      language,
      tp
    } = useI18n$(name)
    const {
      allOptions,
      options,
      filterOptions,
      loadingAllOptions,
      loadOptions
    } = useSelect<PhoneOption>({
      minTextFilterCount: 1,
      initOptions: async () => await doLoadPhoneOptions(language.value),
      filterOptions: (text, options) => Promise.resolve(options.filter(o => filterLowerCase(o.label, text)))
    })
    const {
      required,
      none
    } = useValidationRules()

    const inputRef = ref(null) as unknown as Ref<QInput>
    const option = ref<PhoneOption>(EMPTY)
    const number = ref('')
    const filterText = ref('')

    const format = computed(() => {
      if (option.value?.format) {
        let left = option.value.format.substring(`+${option.value.code}`.length - 1)
        if (left && left.length > 0) {
          if (left.charAt(0) === ' ' || left.charAt(0) === '-') {
            left = left.substring(1)
          }
          return left
        }
        return ''
      }
      return ''
    })
    const maskMax = computed(() => {
      return format.value.replace(/\./g, '#').replace(/\?/g, '#')
    })
    const maskMin = computed(() => {
      return format.value.replace(/\./g, '#').replace(/\?/g, '')
    })
    const placeholder = computed(() => {
      if (maskMin.value) {
        return maskMin.value.replace(/#/g, '_')
      }
      return ''
    })
    const prioritySort = (o1: PhoneOption, o2: PhoneOption) => o1.priority >= o2.priority ? 1 : -1
    const createPhone = (phone: string, option?: PhoneOption) => {
      return option ? {
        code: option.code,
        number: phone.substring(option.code.length)
      } : null
    }
    const searchByCodeAndPhone = (phone: string) => {
      return allOptions.value
        .filter(o => phone.startsWith(o.code) && phone.substring(o.code.length).length === o.numbersCount)
        .sort(prioritySort)[0] || null
    }
    const searchByCode = (phone: string) => {
      return allOptions.value
        .filter(o => phone.startsWith(o.code))
        .sort(prioritySort)[0] || null
    }
    const searchByNumberAndCurrentOption = (phone: string) => {
      return allOptions.value
        .filter(o => phone.length === o.numbersCount && o.code === option.value!.code)
        .sort(prioritySort)[0] || null
    }
    const searchByNumber = (phone: string) => {
      return allOptions.value
        .filter(o => phone.length === o.numbersCount && o.iso === (props.defaultCountry || DEFAULT_COUNTRY_KEY))
        .sort(prioritySort)[0] || null
    }
    const extractPhone = (phone: string): Phone | null => {
      if (!phone) {
        return null
      } else {
        let newOption
        if (phone.startsWith('+')) {
          newOption = searchByCodeAndPhone(phone)
          if (!newOption) {
            newOption = searchByCode(phone)
          }
          return newOption ? createPhone(phone, newOption) : null
        } else {
          if (option.value) {
            newOption = searchByNumberAndCurrentOption(phone)
          }
          if (!newOption) {
            newOption = searchByNumber(phone)
          }
          if (!newOption) {
            newOption = option.value
          }
          return newOption ? createPhone(`${newOption.code}${phone}`, newOption) : null
        }
      }
    }
    const updateOption = (value: Phone | null) => {
      let filter
      if (value === null) {
        filter = (o: PhoneOption) => o.iso === (props.defaultCountry || DEFAULT_COUNTRY_KEY)
      } else {
        filter = (o: PhoneOption) => o.code === value!.code
      }
      if (value === null || value.code !== option.value?.code) {
        const allMatchingOptions = allOptions.value
          .filter(filter)
          .sort(prioritySort)
        if (allMatchingOptions.length) {
          option.value = allMatchingOptions[0]
        } else {
          option.value = EMPTY
        }
      }
    }
    const updateModels = (value: Phone | null) => {
      updateOption(value)
      number.value = value?.number || ''
    }
    const onOptionChanged = () => {
      inputRef.value?.resetValidation()
    }
    const matchMask = (value?: string) => {
      if (!value || !option.value) {
        return true
      }
      let i = 0
      let j = 0
      const fm = format.value
      while (i < fm.length) {
        const maskChar = fm.charAt(i)
        if (maskChar === '.' || maskChar === '?') {
          const valueChar = value.charAt(j)
          if (!((valueChar >= '0' && valueChar <= '9') || (valueChar === '' && maskChar === '?'))) {
            return tp('mask', { mask: placeholder.value }) as string
          }
          j++
        }
        i++
      }
      emit(VueModel, formatPhone({
        code: option.value!.code,
        number: number.value!
      }))
      return true
    }
    const onPaste = (event: ClipboardEvent) => {
      let clipboardData, phone
      event.stopPropagation()
      event.preventDefault()

      // @ts-ignore
      clipboardData = event.clipboardData || window.clipboardData
      phone = clipboardData.getData('Text')
      if (!phone) {
        emit(VueModel, '')
        return
      }
      const resultPhone = parsePhone(phone)
      if (resultPhone.length === 1) {
        emit(VueModel, '')
      } else {
        const extractedPhone = extractPhone(resultPhone)
        emit(VueModel, extractedPhone ? formatPhone(extractedPhone) : '')
        updateModels(extractedPhone)
      }
    }
    const parsePhone = (phone: string) => {
      if (!phone) {
        return ''
      }
      let resultPhone = ''
      for (let i = 0; i < phone.length; i++) {
        const char = phone.charAt(i)
        if ((char >= '0' && char <= '9') || char === '+') {
          resultPhone += char
        }
      }
      return resultPhone
    }
    const onShow = () => nextTick(() => {
      const input = document.querySelector('.q-select__dialog input') as HTMLInputElement | undefined
      input?.focus()
    })
    const onInput = (value: string) => {
      const parsedPhone = parsePhone(value)
      number.value = parsedPhone
      if (!parsedPhone) {
        emit(VueModel, '')
      }
    }

    const validation = useValidation({
      phone: {
        ref: number,
        rules: [props.required ? required : none, matchMask]
      },
    })

    watch(() => props.modelValue, (newModelValue, oldModelValue) => {
      let phone
      if (!newModelValue) {
        phone = null
      } else if (!oldModelValue) {
        phone = extractPhone(newModelValue)
      } else if (newModelValue.startsWith(oldModelValue) || oldModelValue.startsWith(newModelValue)) {
        phone = {
          code: option.value!.code,
          number: newModelValue.substring(option.value!.code.length)
        }
      } else {
        phone = extractPhone(newModelValue)
      }
      updateModels(phone)
    })
    watch(() => props.defaultCountry, () => {
      if (!number.value) {
        updateModels(null)
      }
    })
    watch(validation.valid, newValue => emit(ValidModel, newValue))

    loadOptions()
      .then(() => {
        updateModels(props.modelValue ? extractPhone(props.modelValue) : null)
        emit(ValidModel, validation.valid.value)
      })

    return {
      inputRef,
      matDone,
      matBlock,
      EMPTY,
      options,
      option,
      filterText,
      mask: maskMax,
      number,
      placeholder,
      loadingAllOptions,
      filterOptions,
      onOptionChanged,
      onPaste,
      onShow,
      onInput, ...validation
    }
  }
})
