import { ref } from 'vue'
import { defineStore, } from 'pinia'

const useServerRender = defineStore('serverRender', () => {
  const code = ref<404 | 500 | 200>(200)

  return {
    code,
  }
})

export default useServerRender
