import { InjectionKey } from 'vue'
import { inject } from 'src/compositions/common'
import { useQuasar } from 'quasar'
import { matCheck, matErrorOutline } from 'src/config/icons'

const notifyKey: InjectionKey<ReturnType<typeof useNotify_>> = Symbol('qeepl_notify')

const matWarningAmber = 'M0 0 H24 V24 H0 V0 z@@fill:none;&&M12,5.99L19.53,19H4.47L12,5.99 M12,2L1,21h22L12,2L12,2z&&M13 16 L11 16 L11 18 L13 18z&&M13 10 L11 10 L11 15 L13 15z'

function useNotify_() {
  const { notify, iconSet } = useQuasar()

  iconSet.type.positive = matCheck
  iconSet.type.negative = matErrorOutline
  iconSet.type.warning = matWarningAmber

  function positive(title: string, description?: string) {
    notify({
      type: 'positive',
      message: title,
      caption: description,
      position: 'top',
      timeout: 3000
    })
  }

  function warning(title: string, description?: string) {
    notify({
      type: 'warning',
      message: title,
      caption: description,
      position: 'top',
      timeout: 3000
    })
  }

  function negative(title: string, description?: string) {
    notify({
      type: 'negative',
      message: title,
      caption: description,
      position: 'top',
      timeout: 5000
    })
  }

  return {
    positive,
    negative,
    warning
  }
}

export default function useNotify() {
  return inject(notifyKey, () => useNotify_())
}
