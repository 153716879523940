import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row q-px-md q-pb-lg heading-section-template"
}
const _hoisted_2 = { class: "col-12 text-center" }
const _hoisted_3 = {
  key: 1,
  class: "row q-px-md q-pb-lg"
}
const _hoisted_4 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'q-mt-xl': _ctx.marginTop})
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.description), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{'heading-section-template__margin-bottom': _ctx.marginBottom, 'q-px-md': !_ctx.noSidePadding}, "row justify-center"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.bodyClass)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2)
  ], 2))
}