
import { defineAsyncComponent, defineComponent, onMounted, PropType, watch } from 'vue'
import SectionTemplate from 'src/components/template/section-template.vue'
import ContainerTemplate from 'src/components/template/container-template.vue'
import PageHeader from 'src/components/parts/common/page-header.vue'
import Chat from 'src/components/simple/chat.vue'
import PageDrawer from 'src/components/parts/common/page-drawer.vue'
import WebPageFooter from 'src/components/parts/common/web/page-footer.vue'
import { QLayout, QNoSsr, QPage, QPageContainer } from 'quasar'
import useEnvironment from 'src/compositions/environment'
import useLazyDialog from 'src/compositions/lazyDialog'
import { refStore } from 'stores/__common'
import usePartnerDetails from 'stores/partnerDetails'

const name = 'page-template'

type Position = 'start' | 'center' | 'end'

export default defineComponent({
  name,
  components: {
    QLayout,
    QNoSsr,
    QPageContainer,
    QPage,
    SectionTemplate,
    ContainerTemplate,
    PageHeader,
    Chat,
    PageDrawer,
    WebPageFooter,
    AppPageFooter: defineAsyncComponent(() => import(/* webpackChunkName: "app-page-footer", webpackMode: "lazy" */'src/components/parts/common/app/page-footer.vue')),
    ConsentDialog: defineAsyncComponent(() => import(/* webpackChunkName: "consent-dialog", webpackMode: "lazy" */'src/components/advanced/consent-dialog.vue')),
    JoinOurCommunityDialog: defineAsyncComponent(() => import(/* webpackChunkName: "consent-dialog", webpackMode: "lazy" */'src/components/advanced/join-our-community-dialog.vue'))
  },
  props: {
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      default: ''
    },
    noOverflow: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    },
    previewDense: {
      type: Boolean,
      default: false
    },
    overflowUp: {
      type: Boolean,
      default: false
    },
    overflowDown: {
      type: Boolean,
      default: false
    },
    position: {
      type: String as PropType<Position>,
      default: 'start'
    },
    titleClass: {
      default: ''
    },
    descriptionClass: {
      default: ''
    },
    noChat: {
      type: Boolean,
      default: false
    },
    noMobileDescription: {
      type: Boolean,
      default: false
    },
    column: {
      type: Boolean,
      default: false
    },
    row: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const { app } = useEnvironment()
    const { partner } = refStore(usePartnerDetails())
    const { requestDialogLoading: requestConsentDialog, openDialog: openConsentDialog } = useLazyDialog()
    const { requestDialogLoading: requestJoinOurCommunityDialog, openDialog: openJoinOurCommunity } = useLazyDialog()

    onMounted(() => {
      if (!app) {
        openConsentDialog()
      }
      if (partner.value) {
        openJoinOurCommunity()
      }
    })

    watch(partner, newPartner => {
      newPartner && openJoinOurCommunity()
    })

    return {
      app,
      requestConsentDialog,
      requestJoinOurCommunityDialog
    }
  }
})
