import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = {
  key: 1,
  class: "col-auto row items-center"
}
const _hoisted_3 = { class: "col-auto q-pt-xs" }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "q-pl-sm col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    ref: "inputRef",
    "model-value": _ctx.number,
    disable: _ctx.disable,
    "hide-bottom-space": !_ctx.hint,
    "hide-hint": !_ctx.hint,
    hint: _ctx.hint ? _ctx.hint : undefined,
    i18n: _ctx.$options.name,
    label: _ctx.label ? _ctx.label : undefined,
    mask: _ctx.mask,
    placeholder: _ctx.placeholder,
    readonly: _ctx.readonly,
    rules: _ctx.validations.phone.rules,
    class: "phone-input",
    "lazy-rules": "",
    outlined: "",
    type: "tel",
    onPaste: _ctx.onPaste,
    "onUpdate:modelValue": _ctx.onInput,
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
  }, _createSlots({
    prepend: _withCtx(() => [
      _createVNode(_component_q_no_ssr, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_select, {
            ref: "select",
            modelValue: _ctx.option,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.option) = $event)),
            disable: _ctx.disable,
            options: _ctx.options,
            readonly: _ctx.readonly,
            behavior: "dialog",
            class: "phone-input__select",
            "hide-bottom-space": "",
            "popup-content-class": "phone-input__popup--content overflow-auto",
            "use-input": "",
            onChange: _ctx.onOptionChanged,
            onFilter: _ctx.filterOptions,
            onPopupShow: _ctx.onShow
          }, {
            option: _withCtx((scope) => [
              _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { avatar: "" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        alt: scope.opt.label,
                        src: scope.opt.img,
                        class: "q-mr-xs",
                        height: "28",
                        loading: "lazy",
                        width: "28"
                      }, null, 8, _hoisted_1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.opt.label) + " (" + _toDisplayString(scope.opt.code) + ") ", 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)
            ]),
            "selected-item": _withCtx((scope) => [
              (_ctx.loadingAllOptions)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_q_skeleton, {
                      height: "28px",
                      square: "",
                      type: "rect",
                      width: "28px"
                    }),
                    _createVNode(_component_q_skeleton, {
                      class: "q-ml-sm",
                      height: "28px",
                      square: "",
                      type: "text",
                      width: "28px"
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("img", {
                        alt: scope.opt.label,
                        src: scope.opt.img,
                        height: "28",
                        loading: "lazy",
                        width: "28"
                      }, null, 8, _hoisted_4)
                    ]),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(scope.opt.code), 1)
                  ]))
            ]),
            _: 1
          }, 8, ["modelValue", "disable", "options", "readonly", "onChange", "onFilter", "onPopupShow"])
        ]),
        _: 1
      })
    ]),
    _: 2
  }, [
    (_ctx.checked !== null)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(_component_q_icon, {
              color: _ctx.checked === true ? 'positive' : 'negative',
              name: _ctx.checked === true ? _ctx.matDone : _ctx.matBlock
            }, null, 8, ["color", "name"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["model-value", "disable", "hide-bottom-space", "hide-hint", "hint", "i18n", "label", "mask", "placeholder", "readonly", "rules", "onPaste", "onUpdate:modelValue"]))
}