import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row justify-center q-pt-md q-px-md" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row justify-between full-width q-mt-md" }
const _hoisted_5 = {
  key: 0,
  class: "col-12 text-center q-mb-md"
}
const _hoisted_6 = { class: "text-grey-7" }
const _hoisted_7 = { class: "text-blue-7" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = {
  key: 1,
  class: "col-auto text-right"
}
const _hoisted_10 = { class: "col-12 q-px-md q-pt-lg q-pb-md" }
const _hoisted_11 = { class: "col-12 q-px-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_firebase_auth = _resolveComponent("firebase-auth")!
  const _component_login_input_check = _resolveComponent("login-input-check")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_large_btn = _resolveComponent("large-btn")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_service_conditions = _resolveComponent("service-conditions")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.t('global.pages.login'),
    "no-chat": _ctx.app,
    class: "login",
    "overflow-up": "",
    position: "start"
  }, {
    breadcrumbs: _withCtx(() => [
      _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
    ]),
    "after-preview": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_card, { class: "q-pa-md shadow-10 rounded-borders-large login__card" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_Transition, {
                  "enter-active-class": "animated fadeIn",
                  "leave-active-class": "animated fadeOut",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (!_ctx.isPhoneOtp && _ctx.otpEnter)
                      ? (_openBlock(), _createBlock(_component_firebase_auth, { key: 0 }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.otpEnter)
                  ? (_openBlock(), _createBlock(_component_login_input_check, {
                      key: 0,
                      ref: "phoneInputCheckRef",
                      "is-phone": _ctx.isPhoneOtp,
                      hint: _ctx.isPhoneOtp ? _ctx.tp('phone.hint') : _ctx.tp('emailHint'),
                      "action-id": "send-code-button",
                      required: "",
                      redirect: ""
                    }, null, 8, ["is-phone", "hint"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_q_input, {
                        modelValue: _ctx.userEmail,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userEmail) = $event)),
                        label: _ctx.tp('email'),
                        "lazy-rules": true,
                        rules: _ctx.validations.userEmail.rules,
                        autocomplete: "email",
                        clearable: "",
                        "hide-bottom-space": "",
                        name: "email",
                        outlined: "",
                        type: "email"
                      }, null, 8, ["modelValue", "label", "rules"]),
                      _createVNode(_component_q_input, {
                        modelValue: _ctx.userPassword,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userPassword) = $event)),
                        label: _ctx.tp('password'),
                        "lazy-rules": true,
                        rules: _ctx.validations.userPassword.rules,
                        autocomplete: "password",
                        class: "q-mt-lg",
                        clearable: "",
                        "hide-bottom-space": "",
                        name: "password",
                        outlined: "",
                        type: "password"
                      }, null, 8, ["modelValue", "label", "rules"])
                    ], 64))
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { class: "row items-center justify-center q-pa-md" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_large_btn, {
                    id: "send-code-button",
                    disable: (_ctx.otpEnter && !_ctx.otpInputValid) || (!_ctx.otpEnter && !_ctx.emailFieldsValid) || _ctx.processing || _ctx.checkSuccess || _ctx.userBlocked,
                    label: _ctx.t('action.signIn'),
                    loading: _ctx.processing,
                    color: "accent",
                    fill: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.otpEnter ? _ctx.authorizeByOtp() : _ctx.authorizeByEmailAndPassword()))
                  }, null, 8, ["disable", "label", "loading"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (!_ctx.otpEnter)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_q_btn, {
                            to: _ctx.toPartners,
                            disable: _ctx.userBlocked,
                            class: "text-transform-none",
                            flat: "",
                            padding: "none"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.tp('account.no')), 1),
                              _cache[5] || (_cache[5] = _createTextVNode(" ")),
                              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.tp('account.create')), 1)
                            ]),
                            _: 1
                          }, 8, ["to", "disable"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_8, [
                      (!_ctx.otpEnter)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            label: _ctx.tp('recovery'),
                            to: _ctx.toRecovery,
                            disable: _ctx.userBlocked,
                            class: "text-transform-none",
                            flat: "",
                            padding: "none",
                            "text-color": "blue-7"
                          }, null, 8, ["label", "to", "disable"]))
                        : (_openBlock(), _createBlock(_component_q_btn, {
                            key: 1,
                            label: _ctx.isPhoneOtp ? _ctx.tp('signIn.email') : _ctx.tp('signIn.phone'),
                            disable: _ctx.userBlocked,
                            class: "text-transform-none",
                            flat: "",
                            padding: "none",
                            "text-color": "blue-7",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isPhoneOtp = !_ctx.isPhoneOtp))
                          }, null, 8, ["label", "disable"]))
                    ]),
                    (!_ctx.app)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_q_btn, {
                            label: _ctx.tp(_ctx.otpEnter ? 'enter.email' : 'enter.phone'),
                            disable: _ctx.userBlocked,
                            class: "text-transform-none",
                            flat: "",
                            padding: "none",
                            "text-color": "blue-7",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.otpEnter ? _ctx.enterByEmailAndPassword() : _ctx.enterByOtp()))
                          }, null, 8, ["label", "disable"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_q_separator)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_service_conditions, {
                    partner: !_ctx.otpEnter,
                    disabled: _ctx.userBlocked
                  }, null, 8, ["partner", "disabled"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title", "no-chat"]))
}