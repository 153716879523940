import { InjectionKey, ref } from 'vue'
import { LocalStorage } from 'quasar'
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signInWithRedirect, onAuthStateChanged } from 'firebase/auth'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { inject } from 'src/compositions/common'
import { FIREBASE_WEB_CONFIG } from 'src/model/constants'
import useEnvironment from 'src/compositions/environment'

const firebaseKey: InjectionKey<ReturnType<typeof useFirebase_>> = Symbol('qeepl_firebase')

export type FirebaseAuthType = 'GOOGLE' | 'APPLE'

export const localStorageAuthWasTried = 'qeepl_authWasTried'
export const localStorageAuthTypePramName = 'qeepl_authType'

function useFirebase_() {
  const { app, safari } = useEnvironment()

  const firebaseReady = ref(false)
  const firebaseApp = ref<FirebaseApp | null>(null)

  const initialize = async () => {
    if (!firebaseReady.value && !app) {
      firebaseApp.value = initializeApp(FIREBASE_WEB_CONFIG as unknown as FirebaseOptions)
    }
    firebaseReady.value = true
  }

  const authenticate = async (type: FirebaseAuthType): Promise<string | void> => {
    if (!app) {
      let provider: any
      switch (type) {
        case 'APPLE':
          provider = new OAuthProvider('apple.com')
          break
        default:
          provider = new GoogleAuthProvider()
          break
      }
      if (safari) {
        LocalStorage.set(localStorageAuthWasTried, 'true')
        LocalStorage.set(localStorageAuthTypePramName, type)
        await signInWithRedirect(getAuth(), provider)
      } else {
        const result = await signInWithPopup(getAuth(), provider)
        return (await result.user.getIdToken()) ?? ''
      }
    } else {
      switch (type) {
        case 'APPLE':
          await FirebaseAuthentication.signInWithApple()
          break
        default:
          await FirebaseAuthentication.signInWithGoogle()
          break
      }
      return (await FirebaseAuthentication.getIdToken()).token ?? ''
    }
  }

  const finishAuthenticate = (): Promise<string> => {
    try {
      if (LocalStorage.getItem(localStorageAuthWasTried) === 'true') {
        return new Promise<string>(resolve => {
          onAuthStateChanged(getAuth()!, (user) => {
            resolve(user?.getIdToken() ?? '')
          })
        })
      } else {
        return Promise.resolve('')
      }
    } catch (error) {
      console.error('Firebase Auth Error:', error)
      throw error
    } finally {
      LocalStorage.remove(localStorageAuthWasTried)
      LocalStorage.remove(localStorageAuthTypePramName)
    }
  }

  return {
    app,
    firebaseReady,
    initialize,
    authenticate,
    finishAuthenticate,
  }
}

export default function useFirebase() {
  return inject(firebaseKey, () => useFirebase_())
}
