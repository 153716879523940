
import { defineComponent } from 'vue'
import { QImg } from 'quasar'

const name = 'icon-image'

export default defineComponent({
  name,
  components: {
    QImg
  },
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'svg'
    },
    alt: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    },
  },
  emits: ['click'],
  setup() {
    return {}
  }
})
