
import { defineComponent } from 'vue'
import { QIcon } from 'quasar'

const name = 'how-to-item'

export default defineComponent({
  name,
  components: {
    QIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    return {}
  }
})
