import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row justify-center q-pt-md q-px-md" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_large_btn = _resolveComponent("large-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.t('global.pages.recovery'),
    class: "recovery",
    "overflow-up": "",
    position: "start"
  }, {
    breadcrumbs: _withCtx(() => [
      _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
    ]),
    "after-preview": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_card, { class: "q-pa-md shadow-10 rounded-borders-large recovery__card" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                (_ctx.keyCheck)
                  ? (_openBlock(), _createBlock(_component_q_input, {
                      key: 0,
                      modelValue: _ctx.userPassword,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userPassword) = $event)),
                      hint: _ctx.tp('hint.password'),
                      label: _ctx.tp('password'),
                      "lazy-rules": true,
                      readonly: _ctx.checkSuccess,
                      rules: _ctx.passwordValidations.userPassword.rules,
                      autocomplete: "password",
                      class: "q-mt-xs",
                      clearable: "",
                      name: "password",
                      outlined: "",
                      type: "password"
                    }, null, 8, ["modelValue", "hint", "label", "readonly", "rules"]))
                  : (_openBlock(), _createBlock(_component_q_input, {
                      key: 1,
                      modelValue: _ctx.userEmail,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userEmail) = $event)),
                      hint: _ctx.tp('hint.email'),
                      label: _ctx.tp('email'),
                      "lazy-rules": true,
                      readonly: _ctx.sendSuccess,
                      rules: _ctx.emailValidations.userEmail.rules,
                      autocomplete: "email",
                      clearable: "",
                      name: "email",
                      outlined: "",
                      type: "email"
                    }, null, 8, ["modelValue", "hint", "label", "readonly", "rules"]))
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, { class: "row items-center justify-center q-pa-md" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_large_btn, {
                    disable: (_ctx.keyCheck && !_ctx.passwordFieldValid) || (!_ctx.keyCheck && !_ctx.emailFieldValid) || _ctx.processing || _ctx.checkSuccess || _ctx.sendSuccess || _ctx.blocked,
                    label: _ctx.keyCheck ? _ctx.tp('update') : _ctx.tp('send'),
                    loading: _ctx.processing,
                    color: "accent",
                    fill: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.keyCheck ? _ctx.checkCode() : _ctx.sendCode()))
                  }, null, 8, ["disable", "label", "loading"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}