import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["i18n"]
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row items-center" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row items-center xs" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = { class: "col-auto" }
const _hoisted_10 = { class: "gt-xs col-auto" }
const _hoisted_11 = { class: "row items-center" }
const _hoisted_12 = {
  key: 1,
  class: "col-auto"
}
const _hoisted_13 = {
  key: 3,
  class: "col-auto q-py-sm"
}
const _hoisted_14 = {
  key: 4,
  class: "col-auto q-pl-xs location-select__luggage-button__col-sm"
}
const _hoisted_15 = {
  key: 0,
  class: "row q-pt-md xs"
}
const _hoisted_16 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_btn = _resolveComponent("icon-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_date_time_button = _resolveComponent("date-time-button")!
  const _component_bags_button = _resolveComponent("bags-button")!
  const _component_luggage_button = _resolveComponent("luggage-button")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", {
    i18n: _ctx.$options.name,
    class: "row location-select"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_select, {
            ref: "selectRef",
            class: _normalizeClass([{'text-small': _ctx.$q.screen.xs}, "location-select__select q-pa-xs rounded-borders bg-white"]),
            "input-debounce": 300,
            "model-value": _ctx.modelValue,
            options: _ctx.options,
            placeholder: _ctx.placeholder || _ctx.tp('placeholder'),
            borderless: "",
            dense: "",
            "fill-input": "",
            "hide-dropdown-icon": "",
            "hide-selected": "",
            "option-label": "description",
            "use-input": "",
            clearable: "",
            onFilter: _ctx.filterOptions,
            "onUpdate:modelValue": _ctx.onSelect,
            onInputValue: _ctx.onInput,
            onPopupShow: _ctx.onShow,
            onClear: _ctx.onClear
          }, _createSlots({
            before: _withCtx(() => [
              _createVNode(_component_icon_btn, {
                icon: "search",
                padding: "xs"
              })
            ]),
            after: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (!_ctx.noDatesChooser)
                      ? (_openBlock(), _createBlock(_component_q_separator, {
                          key: 0,
                          color: "grey-4",
                          vertical: ""
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_8, [
                      (!_ctx.noDatesChooser)
                        ? (_openBlock(), _createBlock(_component_date_time_button, {
                            key: 0,
                            dense: "",
                            single: "",
                            timezone: _ctx.city?.timezone,
                            holidays: _ctx.country?.holidays
                          }, null, 8, ["timezone", "holidays"]))
                        : _createCommentVNode("", true)
                    ]),
                    (!_ctx.noBagsChooser)
                      ? (_openBlock(), _createBlock(_component_q_separator, {
                          key: 1,
                          color: "grey-4",
                          vertical: ""
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_9, [
                      (!_ctx.noBagsChooser)
                        ? (_openBlock(), _createBlock(_component_bags_button, {
                            key: 0,
                            dense: ""
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    (!_ctx.noDatesChooser)
                      ? (_openBlock(), _createBlock(_component_q_separator, {
                          key: 0,
                          color: "grey-4",
                          vertical: ""
                        }))
                      : _createCommentVNode("", true),
                    (!_ctx.noDatesChooser)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createVNode(_component_date_time_button, {
                            single: "",
                            timezone: _ctx.city?.timezone,
                            holidays: _ctx.country?.holidays
                          }, null, 8, ["timezone", "holidays"])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.noBagsChooser)
                      ? (_openBlock(), _createBlock(_component_q_separator, {
                          key: 2,
                          color: "grey-4",
                          vertical: ""
                        }))
                      : _createCommentVNode("", true),
                    (!_ctx.noBagsChooser)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createVNode(_component_bags_button)
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.noLeaveButton)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createVNode(_component_luggage_button, {
                            query: _ctx.query,
                            fill: ""
                          }, null, 8, ["query"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            option: _withCtx((scope) => [
              _createVNode(_component_q_item, _mergeProps(scope.itemProps, { class: "q-pt-xs" }), {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(scope.opt.description), 1)
                        ]),
                        _: 2
                      }, 1024),
                      (scope.opt.caption)
                        ? (_openBlock(), _createBlock(_component_q_item_label, {
                            key: 0,
                            caption: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.opt.caption), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1040)
            ]),
            _: 2
          }, [
            (_ctx.loadOptionsOccurred)
              ? {
                  name: "no-option",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { class: "text-grey" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('global.no.results')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["class", "model-value", "options", "placeholder", "onFilter", "onUpdate:modelValue", "onInputValue", "onPopupShow", "onClear"])
        ])
      ]),
      (!_ctx.noLeaveButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_luggage_button, {
                query: _ctx.query,
                fill: "",
                onClick: _ctx.onClick
              }, null, 8, ["query", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}