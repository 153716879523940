
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'boot/i18n'
import { QBtn } from 'quasar'
import { matRefresh } from 'src/config/icons'

const name = 'error-handle-template'

export default defineComponent({
  name,
  components: {
    QBtn
  },
  props: {
    success: {
      type: Boolean,
      default: true
    },
    reload: {
      type: Function as PropType<() => void>,
      required: false
    }
  },
  setup() {
    const { t } = useI18n()
    return {
      matRefresh,
      t
    }
  }
})
