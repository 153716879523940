
import { computed, defineComponent } from 'vue'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import LargeBtn from 'src/components/simple/large-btn.vue'
import HeadingSectionTemplate from 'src/components/template/heading-section-template.vue'
import { IMGKIT_URL } from 'src/model/constants'
import { QImg } from 'quasar'

const name = 'become-partner-card'

export default defineComponent({
  name,
  components: {
    QImg,
    LargeBtn,
    HeadingSectionTemplate,
  },
  props: {
    alt: {
      type: String,
      default: 'Turn your space into money'
    },
  },
  setup(props) {
    const { language, tp, t } = useI18n$(name)
    const { toPartners } = useRoutes()

    const title = computed(() => {
      return tp('title')
    })
    const description = computed(() => {
      return tp('description')
    })

    return {
      IMGKIT_URL,
      language,
      title,
      description,
      tp,
      t,
      toPartners,
    }
  }
})
