
import { computed, defineComponent, toRef } from 'vue'

export default defineComponent({
  name: 'icon-label',
  props: {
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'svg'
    },
    color: {
      type: String,
      default: 'black'
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  setup(props) {
    const size = toRef(props, 'size')

    const dimension = computed(() => {
      switch (size.value) {
        case 'xs':
          return '16'
        case 'sm':
          return '20'
        case 'md':
          return '24'
        case 'lg':
          return '28'
        case 'xl':
          return '32'
        default:
          return '16'
      }
    })

    return {
      dimension
    }
  }
})
