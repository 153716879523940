import { PreFetchOptions } from '@quasar/app-webpack'
import useBlogListHome from 'stores/blogListHome'
import useUserDetails from 'stores/userDetails'
import useAppData from 'stores/appData'

export default async function blogListPrefetch(options: PreFetchOptions<any>) {
  const { language } = useUserDetails(options.store)
  const { loadNext: loadBlogList } = useBlogListHome(options.store)
  const { loadReviews } = useAppData(options.store)

  await Promise.all([
    loadBlogList(language),
    loadReviews(language),
  ])
}
