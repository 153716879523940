import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_btn = _resolveComponent("auth-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!

  return (_openBlock(), _createBlock(_Transition, {
    mode: "out-in",
    "enter-active-class": "animated fadeIn",
    "leave-active-class": "animated fadeOut"
  }, {
    default: _withCtx(() => [
      (_ctx.firebaseReady)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_auth_btn, {
              "auth-type": "GOOGLE",
              class: "row col-12 q-mb-md",
              disable: _ctx.processing,
              loading: _ctx.processing && _ctx.type === 'GOOGLE',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.authenticate('GOOGLE')))
            }, null, 8, ["disable", "loading"]),
            _createVNode(_component_auth_btn, {
              "auth-type": "APPLE",
              class: "row col-12",
              disable: _ctx.processing,
              loading: _ctx.processing && _ctx.type === 'APPLE',
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.authenticate('APPLE')))
            }, null, 8, ["disable", "loading"]),
            (_ctx.showSeparator)
              ? (_openBlock(), _createBlock(_component_q_separator, {
                  key: 0,
                  class: "q-my-md"
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}