import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt", "height", "src", "width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    class: "icon-image",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        style: _normalizeStyle({ width: `${ _ctx.width }px`, height: `${ _ctx.height }px` }),
        alt: _ctx.alt ? _ctx.alt : _ctx.name,
        height: _ctx.height,
        src: `/images/${ _ctx.name }.${ _ctx.type }`,
        width: _ctx.width,
        loading: "lazy"
      }, null, 12, _hoisted_1)
    ]),
    _: 1
  }, 8, ["to"]))
}