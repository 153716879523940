import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_icon_image = _resolveComponent("icon-image")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!

  return (_openBlock(), _createBlock(_component_q_toolbar_title, { class: "toolbar-title" }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_q_skeleton, {
            key: 0,
            height: _ctx.dense ? '32px' : '50px',
            width: _ctx.dense ? '64px' : '96px',
            square: "",
            type: "rect",
            class: "q-my-xs"
          }, null, 8, ["height", "width"]))
        : (_openBlock(), _createBlock(_component_q_avatar, {
            key: 1,
            class: _normalizeClass({'toolbar-title__q-avatar_large': !_ctx.dense, 'toolbar-title__q-avatar_dense': _ctx.dense})
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_icon_image, {
                  height: _ctx.dense ? '32' : '50',
                  to: _ctx.toRoot(),
                  width: _ctx.dense ? '64' : '96',
                  alt: "Qeepl logo",
                  name: "logo"
                }, null, 8, ["height", "to", "width"])
              ])
            ]),
            _: 1
          }, 8, ["class"]))
    ]),
    _: 1
  }))
}