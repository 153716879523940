import { date as quasarDate, useQuasar } from 'quasar'
import { CHECKING_INTERVAL_MS } from 'src/model/constants'
import { DateRange, DateTime, Range, TimeRange } from 'src/model/common.model'
import daysInMonth = quasarDate.daysInMonth

export const QDATE_PATTERN = 'YYYY/MM/DD'
export const SHORT_DATE_PATTERN = 'D MMM'
export const YEAR_PATTERN = 'YYYY'
export const TIME_PATTERN = 'HH:mm'
export const ISO_DATE_PATTERN = 'YYYY-MM-DD'
export const ISO_LIKE_DATE_PATTERN = '[YYYY]-MM-DD'
export const DATE_PATTERN = 'D MMM YYYY'
export const US_DATE_PATTERN = 'MM/DD/YYYY'
export const WORLD_DATE_PATTERN = 'DD.MM.YYYY'
export const AM_PM_TIME_PATTERN = 'hh:mma'
export const TIME_FULL_PATTERN = 'HH:mm:ss'
export const DAY_OF_WEEK_FULL_TEXT = 'dddd'
export const MONTH_FULL_TEXT = 'MMMM'

export const MIDNIGHT = '00:00'
export const UTC = 'UTC'

export const DEFAULT_BETWEEN_OPTS = { inclusiveFrom: true, inclusiveTo: true, onlyDate: false }

export function atTimezone(date: Date, timezone: string): Date {
  return new Date(date.toLocaleString('en-US', { timeZone: timezone }))
}

export function atUTC(date: Date): Date {
  return atTimezone(date, UTC)
}

export function now(timezone: string = UTC): Date {
  return atTimezone(new Date(), timezone)
}

export function newDateUTC() {
  const utc = Date.UTC(1970, 0,)
  return atTimezone(new Date(utc), UTC)
}

export function parse(date: Date | string | number, pattern: string = ISO_DATE_PATTERN): Date {
  if (typeof date === 'string') {
    return quasarDate.extractDate(date, pattern)
  }
  if (typeof date === 'number') {
    return new Date(date)
  }
  return date
}

export function parseDate(date: Date | string | number): Date {
  return parse(date, ISO_DATE_PATTERN)
}

export function parseTime(time: Date | string | number): Date {
  time = parse(time, TIME_PATTERN)
  const newTime = newDateUTC()
  newTime.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds())
  return newTime
}

export function parseDateTime(dateTime: Date | string | number, timezone: string, midnightFix?: boolean): DateTime {
  let newDateTime = parseDateTimeToDate(dateTime, timezone)
  const time = formatTime(newDateTime)
  if (midnightFix && time === MIDNIGHT) {
    newDateTime = addDays(newDateTime, -1)
  }
  return {
    date: formatDate(newDateTime),
    time
  }
}

export function parseDateTimeToDate(dateTime: Date | string | number, timezone?: string): Date {
  let newDateTime
  if (typeof dateTime === 'string') {
    dateTime = new Date(dateTime)
    if (timezone) {
      newDateTime = atTimezone(dateTime, timezone)
    } else {
      newDateTime = newDateUTC()
      newDateTime.setFullYear(dateTime.getUTCFullYear(), dateTime.getUTCMonth(), dateTime.getUTCDate())
      newDateTime.setHours(dateTime.getUTCHours(), dateTime.getUTCMinutes(), dateTime.getUTCSeconds(), dateTime.getUTCMilliseconds())
    }
  } else {
    newDateTime = parse(dateTime)
  }
  return newDateTime
}

function getLocaleDate() {
  return useQuasar()?.lang?.date
}

export function formatDate(date: Date | string | number, pattern: string = ISO_DATE_PATTERN): string {
  date = parseDate(date)
  return quasarDate.formatDate(date, pattern, getLocaleDate())
}

export function formatTime(time: Date | string | number, pattern: string = TIME_PATTERN): string {
  time = parseTime(time)
  return quasarDate.formatDate(time, pattern, getLocaleDate())
}

export function createTimeRange(time: Date | string | number, after: boolean = true, intervalMs: number = CHECKING_INTERVAL_MS): TimeRange {
  time = parseTime(time)
  if (after) {
    return {
      from: formatTime(time),
      to: formatTime(new Date(time.getTime() + Math.abs(intervalMs)))
    }
  } else {
    return {
      from: formatTime(new Date(time.getTime() - Math.abs(intervalMs))),
      to: formatTime(time)
    }
  }
}

export function nextInterval(date: Date | string | number, intervalMs: number = CHECKING_INTERVAL_MS): Date {
  date = parse(date)
  return new Date(date.getTime() - (date.getTime() % intervalMs) + intervalMs)
}

export function currentInterval(date: Date | string | number, intervalMs: number = CHECKING_INTERVAL_MS): Date {
  date = parse(date)
  return new Date(date.getTime() - (date.getTime() % intervalMs))
}

export function getDayOfWeek(date: Date | string | number) {
  return quasarDate.getDayOfWeek(parse(date))
}

export function isBetweenDates(
  date: Date | number | string,
  from: Date | number | string,
  to: Date | number | string,
  opts?: { inclusiveFrom: boolean, inclusiveTo: boolean, onlyDate: boolean }
) {
  from = parseDate(from)
  to = parseDate(to)
  if (to.getTime() >= from.getTime()) {
    return quasarDate.isBetweenDates(parseDate(date), from, to, opts)
  } else {
    return !quasarDate.isBetweenDates(parseDate(date), to, from, opts)
  }
}

export function addMonths(date: Date | string | number, number: number): Date {
  return quasarDate.addToDate(parseDate(date), { months: number })
}

export function addDays(date: Date | string | number, number: number): Date {
  return quasarDate.addToDate(parseDate(date), { days: number })
}

export function addHours(date: Date | string | number, number: number): Date {
  return quasarDate.addToDate(parseDate(date), { hours: number })
}

export function addMinutes(date: Date | string | number, number: number): Date {
  return quasarDate.addToDate(parseDate(date), { minutes: number })
}

export function addSeconds(date: Date | string | number, number: number): Date {
  return quasarDate.addToDate(parseDate(date), { milliseconds: number })
}

export function addMilliseconds(date: Date | string | number, number: number): Date {
  return quasarDate.addToDate(parseDate(date), { milliseconds: number })
}

export function isToday(date: string, timezone?: string): boolean {
  const nowString = formatDate(now(timezone))
  return nowString === date
}

export function isTomorrow(date: string, timezone?: string): boolean {
  const nowString = formatDate(addDays(now(timezone), 1))
  return nowString === date
}

export function isSameDay(date1: Date | string | number, date2: Date | string | number): boolean {
  date1 = parseDate(date1)
  date2 = parseDate(date2)
  return quasarDate.isSameDate(date1, date2, 'day')
}

export function isSameDayOfWeek(date1: Date | string | number, date2: Date | string | number): boolean {
  const day1 = getDayOfWeek(date1)
  const day2 = getDayOfWeek(date2)
  return day1 === day2
}

export function isSameDayOfWeekAsToday(date: Date | string | number, timezone?: string): boolean {
  return isSameDayOfWeek(parseDate(date), now(timezone))
}

export function minDate(date: Date | number | string, ...args: (Date | number | string)[]): Date {
  return new Date(quasarDate.getMinDate(parseDate(date), ...args.map(d => parse(d))))
}

export function maxDate(date: Date | number | string, ...args: (Date | number | string)[]): Date {
  return new Date(quasarDate.getMaxDate(parseDate(date), ...args.map(d => parse(d))))
}

export function destructDate(date: Date | number | string): DateTime {
  date = parseDate(date)
  return {
    date: formatDate(date),
    time: formatTime(date)
  }
}

export function destructDateRange(dateRange: Range<Date>): Range<DateTime> {
  if (dateRange.to.getHours() === 0) {
    dateRange.to = addDays(dateRange.to, -1)
  }
  return {
    from: destructDate(dateRange.from),
    to: destructDate(dateRange.to)
  }
}

export function getAllWeekDays(): Date[] {
  let num = 1
  let start = new Date()
  while (getDayOfWeek(start) !== num) {
    start = addDays(start, -1)
  }
  const result = []
  while (num <= 7) {
    result.push(start)
    start = addDays(start, 1)
    num++
  }
  return result
}

export function getWeekDayName(date: Date | number | string): string {
  return formatDate(parseDate(date), DAY_OF_WEEK_FULL_TEXT)
}

export function getAllMonths(): Date[] {
  let start = quasarDate.startOfDate(new Date(), 'year')
  const result = []
  for (let i = 0; i < 12; i++) {
    result.push(start)
    start = addMonths(start, 1)
  }
  return result
}

export function getMonthName(date: Date | number | string): string {
  return formatDate(parse(date), MONTH_FULL_TEXT)
}

export function getDaysInMonth(date: Date | string | number): number {
  return daysInMonth(parse(date))
}

function fixDates(from: Date, to: Date, timeRange: TimeRange, useNextDay: boolean): Range<Date> {
  return {
    from,
    to: timeRange.to === MIDNIGHT || to.getTime() < from.getTime() ? addMilliseconds(addDays(to, 1), useNextDay ? 0 : -1) : to
  }
}

function fixTimes(from: Date, to: Date, timeRange: TimeRange, useNextDay: boolean): Range<Date> {
  return {
    from,
    to: timeRange.to === MIDNIGHT ? addMilliseconds(addDays(to, 1), useNextDay ? 0 : -1) : to
  }
}

export function buildDate(date: string, time: string): Date {
  const dateResult = parseDate(date)
  const timeResult = parseTime(time)
  return new Date(dateResult.getFullYear(), dateResult.getMonth(), dateResult.getDate(), timeResult.getHours(), timeResult.getMinutes(), timeResult.getSeconds(), timeResult.getMilliseconds())
}

export function buildTimeRange(timeRange: TimeRange, useNextDay: boolean = true): Range<Date> {
  const from = parseTime(timeRange.from)
  const to = parseTime(timeRange.to)
  return fixTimes(from, to, timeRange, useNextDay)
}

export function buildDateRange(date: string, timeRange: TimeRange, useNextDay: boolean = true): Range<Date> {
  const from = buildDate(date, timeRange.from)
  const to = buildDate(date, timeRange.to)
  return fixDates(from, to, timeRange, useNextDay)
}

export function buildDateTimeRange(dateRange: DateRange, timeRange: TimeRange, useNextDay: boolean = true): Range<Date> {
  const from = buildDate(dateRange.from, timeRange.from)
  const to = buildDate(dateRange.to, timeRange.to)
  return fixDates(from, to, timeRange, useNextDay)
}

export function formatToISOTimezone(dateTime: string, timezone: string = UTC) {
  const parts = new Date(dateTime).toLocaleString('sv', { timeZone: timezone, timeZoneName: 'short' }).split(' ')
  return `${ parts[0] }T${ parts[1] } ${ parts[2] }`
}

export function formatIsoLikeDate(date: Date | string | number): string {
  return formatDate(date, ISO_LIKE_DATE_PATTERN)
}
