import { InjectionKey } from 'vue'
import { inject } from 'src/compositions/common'
import { useI18n } from 'boot/i18n'
import { CityProjection } from 'src/model/city.model'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import { useRouter } from 'vue-router'
import { Attraction } from 'src/model/attraction.model'
import { keyToPath } from 'src/functions/utils'
import { ServiceTypeAlias } from 'src/model/common.model'

const routesKey: InjectionKey<ReturnType<typeof useRoutes_>> = Symbol('qeepl_routes')

function extractField<T>(object: T | string, key: string = 'id'): string {
  // @ts-ignore
  return typeof object === 'string' ? object : object[key] as string
}

function useRoutes_() {
  const { language } = useI18n()
  const { currentRoute } = useRouter()

  const toRoot = () => `/${ language.value }`
  const toRootLuggage = () => `${ toRoot() }/luggage-storage`
  const toRootSelf = () => `${ toRoot() }/self-storage`
  const toRootAlias = (alias: ServiceTypeAlias) => `${ toRoot() }/${ keyToPath(alias) }`
  const toPage = (page: string) => `/${ language.value }/${ page }`
  const toMap = (query?: string) => `/${ language.value }/map${ query ? `?${ query }` : '' }`
  const toMapCompany = (company: AnonymousCompanyProjection | string) => `/${ language.value }/map/${ extractField(company) }`
  const toMapCompanyBook = (company: AnonymousCompanyProjection | string, orderId?: string) => `${ toMapCompany(company) }/book${ orderId ? `?orderId=${ orderId }` : '' }`
  const toMapCompanyBookPay = (company: AnonymousCompanyProjection | string, orderId?: string) => `${ toMapCompanyBook(company) }/pay${ orderId ? `?orderId=${ orderId }` : '' }`
  const toMyOrders = () => toPage('orders')
  const toMyOrdersOpen = (orderId: string) => `${ toMyOrders() }/${ orderId }`
  const toMyOrdersOpenInstruction = (orderId: string, emitPurchase?: boolean) => `${ toMyOrders() }/${ orderId }/instruction${ emitPurchase ? '?purchased' : '' }`
  const toMyOrdersOpenEdit = (orderId: string) => `${ toMyOrders() }/${ orderId }/edit`
  const toPaymentSuccess = (orderId: string) => `${ toPage('payment/success') }?orderId=${ orderId }`
  const toPartners = (open?: true) => `/${ language.value }/partners${ open ? '?open' : '' }`
  const toLogin = (config: { partner?: boolean, returnURL?: string } = {}) => {
    const { partner, returnURL } = config
    const any = partner || returnURL
    const both = partner && returnURL
    return `${ toPage('login') }${ any ? '?' : '' }${ partner ? 'partner' : '' }${ both ? '&' : '' }${ returnURL ? `returnURL=${ encodeURIComponent(returnURL) }` : '' }`
  }
  const toCityLuggage = (city: CityProjection | string) => `${ toRootLuggage() }/${ keyToPath(extractField(city, 'key')) }`
  const toCitySelf = (city: CityProjection | string) => `${ toRootSelf() }/${ keyToPath(extractField(city, 'key')) }`
  const toCityAlias = (alias: ServiceTypeAlias, city: CityProjection | string) => `${ toRootAlias(alias) }/${ keyToPath(extractField(city, 'key')) }`
  const toAttractionLuggage = (city: CityProjection | string, attraction: Attraction | string) => `${ toCityLuggage(city) }/${ keyToPath(extractField(attraction, 'key')) }`
  const toAttractionSelf = (city: CityProjection | string, attraction: Attraction | string) => `${ toCitySelf(city) }/${ keyToPath(extractField(attraction, 'key')) }`
  const toAttractionAlias = (alias: ServiceTypeAlias, city: CityProjection | string, attraction: Attraction | string) => `${ toCityAlias(alias, city) }/${ keyToPath(extractField(attraction, 'key')) }`
  const toTerms = (partner?: boolean) => `/${ language.value }/terms${ partner ? '?type=partner' : '' }`
  const toPolicy = () => toPage('policy')
  const toProfile = () => toPage('profile')
  const toBlogList = () => toPage('blog')
  const toBlog = (path: string) => `${ toBlogList() }/${ path }`
  const toRecovery = () => toPage('recovery')
  const toReviews = () => toPage('reviews')
  const toContact = (open?: boolean) => `${ toPage('contact') }${ open ? '?open' : '' }`
  const toOnboarding = () => toPage('onboarding')
  const toMyBusiness = () => toPage('cabinet')
  const toAdmin = () => toPage('admin')
  const toNoAuthorization = () => toPage('error403')
  const toPrevPage = () => {
    const paths = currentRoute.value.path.split('/')
    if (paths.length > 2) {
      paths.pop()
      return paths.join('/')
    }
    return currentRoute.value.path
  }

  return {
    toRoot,
    toRootLuggage,
    toRootSelf,
    toRootAlias,
    toPage,
    toMap,
    toMapCompanyBook,
    toMapCompanyBookPay,
    toMapCompany,
    toMyOrders,
    toMyOrdersOpen,
    toMyOrdersOpenInstruction,
    toMyOrdersOpenEdit,
    toPaymentSuccess,
    toPrevPage,
    toPartners,
    toLogin,
    toCityLuggage,
    toCitySelf,
    toCityAlias,
    toAttractionLuggage,
    toAttractionSelf,
    toAttractionAlias,
    toTerms,
    toPolicy,
    toBlogList,
    toBlog,
    toProfile,
    toRecovery,
    toReviews,
    toContact,
    toOnboarding,
    toAdmin,
    toMyBusiness,
    toNoAuthorization,
  }
}

export default function useRoutes() {
  return inject(routesKey, () => useRoutes_())
}
