import { ref } from 'vue'

export default function useDialog(initModel: boolean = true) {
  const dialogModel = ref<boolean>(initModel)

  const openDialog = () => {
    dialogModel.value = true
  }
  const closeDialog = () => {
    dialogModel.value = false
  }

  return {
    dialogModel,
    openDialog,
    closeDialog
  }
}
