
import { computed, defineComponent, PropType } from 'vue'
import { useI18n$ } from 'boot/i18n'
import LuggageButton from 'components/advanced/luggage-button.vue'
import { IMGKIT_URL } from 'src/model/constants'
import { refStore } from 'stores/__common'
import useUserPreview from 'stores/userPreview'
import { isDefaultServiceType } from 'src/functions/utils'
import useUserOrder from 'src/compositions/user/userOrder'

import { ServiceType } from 'src/model/common.model'
import useUserPreviewAmounts from 'src/compositions/map/userPreviewAmounts'

const name = 'price-banner'

export default defineComponent({
  name,
  components: {
    LuggageButton,
  },
  props: {
    query: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<ServiceType>,
      default: 'DEFAULT'
    },
  },
  setup(props) {
    const { language, tp } = useI18n$(name)
    const { city, country } = refStore(useUserPreview())
    const { hourly, setHourly } = useUserOrder()
    const { prices } = useUserPreviewAmounts()

    const image = computed(() => {
      return `/home-all-page/${ isDefaultServiceType(props.type) ? 'luggage-in-room' : 'box-and-clothes' }.png`
    })
    const priceFormatted = computed(() => {
      if (isDefaultServiceType(props.type)) {
        return hourly.value ? prices.value.S.hourPrice : prices.value.S.dayPrice
      } else {
        return prices.value.S.monthPrice
      }
    })
    const title = computed(() => {
      return tp(`${ props.type }.startingWith`, { amount: priceFormatted.value })
    })
    const description = computed(() => {
      return tp(`${ props.type }.description`)
    })
    const label = computed(() => {
      return tp(`${ props.type }.label`)
    })
    const explanation = computed(() => {
      if (isDefaultServiceType(props.type)) {
        return tp('DEFAULT.explanation.main', { tariff: tp(hourly ? 'DEFAULT.explanation.hour' : 'DEFAULT.explanation.day') })
      }
      return tp('SELF.explanation.main', { tariff: tp('SELF.explanation.month') })
    })

    return {
      IMGKIT_URL,
      language,
      priceFormatted,
      hourly,
      image,
      title,
      description,
      explanation,
      label,
      tp,
      setHourly,
    }
  }
})
