import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["i18n"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_btn = _resolveComponent("icon-btn")!
  const _component_bags_dialog = _resolveComponent("bags-dialog")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock("div", {
    i18n: _ctx.$options.name,
    class: "bags-button"
  }, [
    _createVNode(_component_icon_btn, {
      "bg-color": _ctx.bgColor,
      dense: _ctx.$q.screen.xs,
      disable: _ctx.disable,
      label: _ctx.label,
      padding: _ctx.dense ? (_ctx.$q.screen.width <= 320 ? 'xs' : 'sm') : 'md sm',
      icon: "luggage",
      onClick: _ctx.openDialog
    }, null, 8, ["bg-color", "dense", "disable", "label", "padding", "onClick"]),
    _createVNode(_component_q_no_ssr, null, {
      default: _withCtx(() => [
        (_ctx.requestDialogLoading)
          ? (_openBlock(), _createBlock(_component_bags_dialog, {
              key: 0,
              modelValue: _ctx.dialogModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogModel) = $event))
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 8, _hoisted_1))
}