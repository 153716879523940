import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { Language } from 'src/model/language.model'
import { Authority, User, UserProjection } from 'src/model/user.model'
import useAnonymousApi from 'src/api/anonymous.api'
import useAsync from 'src/compositions/async'
import useUserApi from 'src/api/user.api'
import {
  ROLE_ADMIN,
  ROLE_ADMIN_SECONDARY,
  ROLE_MASTER_USER_OF_COMPANY,
  ROLE_ONBOARDING_USER_OF_COMPANY,
  ROLE_USER,
  ROLE_USER_OF_COMPANY, TOKEN_STORE_NAME
} from 'src/model/constants'
import { UpdateUserRequest } from 'src/api/dto/user'
import { getValue } from 'src/functions/bridge'

const ANONYMOUS: UserProjection = { authorities: [] as Authority[] } as User

function mapToArray(authorities: Authority | RegExp | (Authority | RegExp)[]): (Authority | RegExp)[] {
  if (!Array.isArray(authorities)) {
    authorities = [authorities]
  }
  return authorities as (Authority | RegExp)[]
}

function hasAnyAuthority(user: User | null, authorities: Authority | RegExp | (Authority | RegExp)[]): boolean {
  if (!user?.authorities) {
    return false
  }
  authorities = mapToArray(authorities) as (Authority | RegExp)[]
  for (let i = 0; i < authorities.length; i++) {
    const authority = authorities[i]
    const isRegexp = typeof authority !== 'string'
    if (isRegexp) {
      if (!!user.authorities.find(a => (authority as RegExp).test(a))) {
        return true
      }
    } else {
      if (user.authorities.includes(authorities[i] as Authority)) {
        return true
      }
    }
  }
  return false
}

const useUserDetails = defineStore('userDetails', () => {
  const api = useAnonymousApi()
  const userApi = useUserApi()

  const language = ref<Language>(Language.DEFAULT)
  const token = ref('')
  const user = ref<UserProjection>(ANONYMOUS)
  const blocked = ref<boolean>(false)

  const isAnonymous = computed(() => loading.value || !user.value?.authorities.length)
  const authenticated = computed(() => {
    return !loading.value && !isAnonymous.value
  })
  const isAdmin = computed(() => hasAnyAuthority(user.value, ROLE_ADMIN))
  const isSecondaryAdmin = computed(() => hasAnyAuthority(user.value, ROLE_ADMIN_SECONDARY))
  const isUser = computed(() => hasAnyAuthority(user.value, ROLE_USER))
  const isPartner = computed(() => hasAnyAuthority(user.value, ROLE_USER_OF_COMPANY))
  const isPartnerOwner = computed(() => hasAnyAuthority(user.value, ROLE_MASTER_USER_OF_COMPANY))
  const isOnboardingUser = computed(() => hasAnyAuthority(user.value, ROLE_ONBOARDING_USER_OF_COMPANY))

  const { run: load, running: loading } = useAsync(
    (token: string = '') => doLoad(token),
    result => user.value = result,
    () => user.value = ANONYMOUS
  )

  const { run: updateLanguage, running: updatingLanguage } = useAsync(
    (language: Language) => userApi.updateLanguage(language),
    (result, language) => user.value = { ...user.value, ...{ langKey: language } }
  )

  const { run: update, running: updating } = useAsync(
    (request: UpdateUserRequest) => userApi.update(request),
    result => user.value = result
  )
  const doLoad = (newToken: string = ''): Promise<UserProjection> => {
    if (newToken) {
      token.value = newToken
      return api.getAccount()
    } else {
      return getValue(TOKEN_STORE_NAME)
        .then(newToken => {
          if (newToken) {
            return doLoad(newToken)
          }
          token.value = ''
          return Promise.reject()
        })
    }
  }
  const setLanguage = (newLanguage: Language) => language.value = newLanguage
  const clear = () => {
    token.value = ''
    user.value = ANONYMOUS
  }
  const doesHaveAnyAuthority = (authorities: Authority | Authority[]) => hasAnyAuthority(user.value, authorities)
  const markUserBlocked = () => {
    blocked.value = true
  }

  return {
    token,
    user,
    language,
    isAnonymous,
    authenticated,
    isAdmin,
    isSecondaryAdmin,
    isUser,
    isPartner,
    isPartnerOwner,
    isOnboardingUser,
    load,
    loading,
    updateLanguage,
    updatingLanguage,
    update,
    updating,
    blocked,
    clear,
    setLanguage,
    markUserBlocked,
    hasAnyAuthority: doesHaveAnyAuthority,
    userHasAnyAuthority: hasAnyAuthority
  }
})

export default useUserDetails
