import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { refStore } from 'stores/__common'
import useGeography from 'stores/geography'
import useUserPreview from 'stores/userPreview'
import { CityProjection } from 'src/model/city.model'
import { Container, ServiceTypeAlias } from 'src/model/common.model'
import useUserLocation from 'stores/userLocation'
import { DEFAULT_COUNTRY_KEY, OUT_OF_WORLD_COUNTRIES } from 'src/model/constants'
import { Country } from 'src/model/country.model'
import { isWorldCountry, pathToKey } from 'src/functions/utils'
import { ATTRACTION_LUGGAGE, ATTRACTION_SELF, CITY_LUGGAGE, CITY_SELF, INDEX_LUGGAGE, INDEX_SELF } from 'pages/names'

function filterCities(cities: CityProjection[], country: Country | string): CityProjection[] {
  country = typeof country === 'string' ? country : country.key
  return cities.filter(c => c.country === country)
}

function filter5Cities(cities: CityProjection[], country?: string): CityProjection[] {
  const countryCities = country ? filterCities(cities || [], country) : cities
  const filteredCities = countryCities.map((c: CityProjection) => {
    c.priority = c.priority || Number.MAX_VALUE
    return c
  })
  filteredCities.sort((c1: CityProjection, c2: CityProjection) => c1.priority! < c2.priority! ? -1 : 1)
  return filteredCities
}

export default function useUserPreviewExtension() {
  const { currentRoute } = useRouter()
  const { initialLocation } = refStore(useUserLocation())
  const { cities, countries: storeCountries, loadAll: loadGeography } = refStore(useGeography())
  const { country, companies, loadAllByCity, city, loadAllNearest } = refStore(useUserPreview())

  const alias = computed(() => {
    const routeName = currentRoute.value?.name as string || ''
    if ([INDEX_LUGGAGE, CITY_LUGGAGE, ATTRACTION_LUGGAGE].includes(routeName)) {
      return 'LUGGAGE'
    }
    if ([INDEX_SELF, CITY_SELF, ATTRACTION_SELF].includes(routeName)) {
      return 'SELF'
    }
    const serviceTypeAlias = currentRoute.value.params.serviceTypeAlias as string
    if (serviceTypeAlias) {
      return pathToKey(serviceTypeAlias) as ServiceTypeAlias
    }
    return 'LUGGAGE'
  })
  const countryKey = computed(() => {
    return country.value?.key || initialLocation.value?.countryKey || DEFAULT_COUNTRY_KEY
  })
  const countries = computed(() => {
    return storeCountries.value.filter(c => countryAvailable(c.key))
  })
  const isWorldContext = computed(() => {
    return !OUT_OF_WORLD_COUNTRIES.includes(countryKey.value)
  })
  const countryAvailable = (key: string) => {
    const currentCountryKey = countryKey.value
    if (OUT_OF_WORLD_COUNTRIES.includes(currentCountryKey)) {
      return true
    }
    return isWorldCountry(key)
  }
  const countryCities = computed(() => {
    const result: Container<CityProjection[]> = {}
    cities.value.forEach((c: CityProjection) => {
      if (!result[c.country]) {
        result[c.country] = []
      }
      result[c.country].push(c)
    })
    return result
  })
  const main5Cities = computed(() => {
    const aliasTypeValue = alias.value
    const citiesWithService = cities.value
      .filter(c => {
        return c.services.includes(aliasTypeValue) && countryAvailable(c.country)
      })
    const key = countryCities.value[countryKey.value]?.length ? countryKey.value : DEFAULT_COUNTRY_KEY
    const filteredCities = filter5Cities(citiesWithService)
    filteredCities.sort((c1, c2) => {
      if (c1.country === key && c2.country !== key) {
        return -1
      }
      if (c1.country !== key && c2.country === key) {
        return 1
      }
      return 0
    })
    return filteredCities.slice(0, 5)
  })

  return {
    country,
    city,
    countryKey,
    main5Cities,
    countries,
    allCountries: storeCountries,
    cities,
    companies,
    countryCities,
    isWorldContext,
    loadGeography,
    loadAllByCity,
    loadAllNearest,
  }
}
