import { ValidationConfiguration } from 'src/types/validation'
import { computed, onMounted, ref } from 'vue'
import useEnvironment from 'src/compositions/environment'

export default function useValidation(configuration: ValidationConfiguration, onValidated?: (valid: boolean) => void) {
  const { ssr } = useEnvironment()

  const initialized = ref(false)
  const validStatus = ref(false)

  onMounted(() => checkValid().then(() => initialized.value = true))

  const valid = computed<boolean>(() => {
    if (initialized.value) {
      checkValid()
    }
    return validStatus.value
  })

  const checkValid = () => {
    const resultPromise = ssr ?
      Promise.resolve(false) :
      Promise.all(
        Object.keys(configuration)
          .map(field => {
            if (configuration[field].valid !== undefined) {
              return Promise.resolve(configuration[field].valid!.value)
            }
            const ref = configuration[field].ref
            const rules = configuration[field].rules
            if (!ref || !rules || !rules.length) {
              return Promise.resolve(true)
            }
            return Promise.all(rules.map(rule => rule(ref.value)))
              .then(res => !res.filter(r => r !== true).length)
          })
      )
        .then(res => !res.filter(r => r !== true).length)
    return resultPromise
      .then(res => {
        validStatus.value = res
        onValidated?.(res)
        return res
      })
  }

  return {
    valid,
    validations: {
      ...configuration
    },
    checkValid
  }
}
