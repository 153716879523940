
import { defineComponent } from 'vue'
import { VueClick } from 'components/models'
import IconLabel from 'components/simple/icon-label.vue'
import { QBtn } from 'quasar'

const name = 'location-select__icon-btn'

export default defineComponent({
  name,
  components: {
    QBtn,
    IconLabel
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'svg'
    },
    descriptionLabel: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    VueClick
  ],
  setup(props, { emit }) {
    return {
      onClick: () => emit(VueClick)
    }
  }
})
