import {
  DefaultOrder,
  OrderItemSize,
  OrderItemType,
  OrderProjection,
  OrderStatus,
  PartnerOrderProjection,
  SelfOrder
} from 'src/model/order.model'
import { isDefault, isSelf } from 'src/functions/utils'
import { ChargeType, ItemsMetadata } from 'src/model/common.model'

export const DEFAULT_ITEMS_METADATA: ItemsMetadata = { 'LUGGAGE': 1, 'BACKPACK': 1 }

export const ORDER_ITEM_SIZES: Record<OrderItemType, OrderItemSize> = {
  'BACKPACK': 'S',
  'BAG': 'S',
  'KEY': 'S',
  'HAND_BAGGAGE': 'S',
  'CHILD_CHAIR': 'L',
  'LUGGAGE': 'M',
  'SNOWBOARD': 'L',
  'TOURIST_BACKPACK': 'M',
  'SMALL_BOX': 'S',
  'MIDDLE_BOX': 'M',
  'LARGE_BOX': 'L',
  'BIKE': 'L',
  'E_SCOOTER': 'M',
  'BABY_CARRIAGE': 'L',
  'SURFBOARD': 'L'
}

export const CHARGE_TYPE_TO_ORDER_ITEM: Record<ChargeType, OrderItemType[]> = {
  'MONTHLY': [
    'SMALL_BOX',
    'MIDDLE_BOX',
    'LUGGAGE',
    'BIKE',
    'E_SCOOTER',
    'BABY_CARRIAGE',
  ],
  'DAILY': [
    'BACKPACK',
    'BAG',
    'HAND_BAGGAGE',
    'LUGGAGE',
    'TOURIST_BACKPACK',
    'CHILD_CHAIR',
    'KEY',
  ],
  'HOURLY': [
    'LUGGAGE'
  ]
}

export function getOrderStatusColor(status: OrderStatus): string {
  switch (status) {
    case 'ACTIVE':
      return 'positive'
    case 'HOLDING':
      return 'warning'
    case 'PAID':
      return 'positive'
    case 'CANCELED':
      return 'negative'
    case 'UNPAID':
      return 'negative'
    case 'CLOSED':
      return 'grey-6'
    case 'PAYING':
      return 'info'
    case 'WAIT_FOR_PAYING':
      return 'info'
  }
}

export function getOrderStatusLightColor(status: OrderStatus): string {
  switch (status) {
    case 'ACTIVE':
      return 'green-1'
    case 'HOLDING':
    case 'PAID':
      return 'orange-2'
    case 'CANCELED':
    case 'UNPAID':
    case 'CLOSED':
      return 'grey-3'
    case 'PAYING':
    case 'WAIT_FOR_PAYING':
      return 'blue-3'
  }
}

export function getStoringStatus(order: PartnerOrderProjection, type: 'client' | 'partner' = 'client'): 'RETURNED' | 'ACCEPTED' | 'NONE' {
  if (type === 'client') {
    return order.clientReturnDate ? 'RETURNED' : (order.clientAcceptDate ? 'ACCEPTED' : 'NONE')
  } else {
    return order.partnerReturnDate ? 'RETURNED' : (order.partnerAcceptDate ? 'ACCEPTED' : 'NONE')
  }
}

export function getCompleteStoringStatus(order: PartnerOrderProjection): 'RETURNED' | 'ACCEPTED' | 'NONE' {
  return order.clientReturnDate || order.partnerReturnDate ? 'RETURNED' : (order.clientAcceptDate || order.partnerAcceptDate ? 'ACCEPTED' : 'NONE')
}

export function shortenOrderStatus(status: OrderStatus): 'PAID' | 'UNPAID' | 'CLOSED' | 'CANCELED' {
  switch (status) {
    case 'ACTIVE':
    case 'HOLDING':
    case 'PAID':
      return 'PAID'
    case 'PAYING':
    case 'UNPAID':
    case 'WAIT_FOR_PAYING':
      return 'UNPAID'
    case 'CANCELED':
      return 'CANCELED'
    case 'CLOSED':
      return 'CLOSED'
  }
}

export function isDaily(order: OrderProjection): boolean {
  return isDefault(order) && order.chargeType == 'DAILY'
}

export function isMonthly(order: OrderProjection): boolean {
  return isDefault(order) && order.chargeType == 'MONTHLY'
}

export function isHourly(order: OrderProjection): boolean {
  return isDefault(order) && order.chargeType == 'HOURLY'
}

export function isHourlyOrSelf(order: OrderProjection): boolean {
  return isHourly(order) || isSelf(order)
}

export function whenDaily(order: OrderProjection, block: (default_: DefaultOrder) => void) {
  if (isDaily(order)) {
    block(order.default!)
  }
}

export function whenHourly(order: OrderProjection, block: (default_: DefaultOrder) => void) {
  if (isHourly(order)) {
    block(order.default!)
  }
}

export function whenMonthly(order: OrderProjection, block: (default_: DefaultOrder) => void) {
  if (isMonthly(order)) {
    block(order.default!)
  }
}

export function whenSelf(order: OrderProjection, block: (self: SelfOrder) => void) {
  if (isSelf(order)) {
    block(order.self!)
  }
}

export function computeItemsCount(itemsMetadata: ItemsMetadata): number {
  let result: number = 0
  Object.keys(itemsMetadata).forEach(key => {
    const value = itemsMetadata[key as OrderItemType] ?? 0
    const size = ORDER_ITEM_SIZES[key as OrderItemType] ?? 'L'
    switch (size) {
      case 'S':
        result += value * 0.5
        break
      case 'M':
        result += value
        break
      case 'L':
        result += value * 2
        break
    }
  })
  return result
}

export function getActualItemsMetadata(itemsMetadata: ItemsMetadata): ItemsMetadata {
  const result = { ...itemsMetadata }
  Object.keys(itemsMetadata).forEach(k => {
    if (!itemsMetadata[k as OrderItemType]) {
      delete result[k as OrderItemType]
    }
  })
  return result
}

export function convertToDomain(itemsMetadata: ItemsMetadata): {
  itemsCount: number,
  smallItemsCount: number,
  largeItemsCount: number
} {
  if (Object.keys(getActualItemsMetadata(itemsMetadata)).length === 0) {
    return { itemsCount: 1, smallItemsCount: 0, largeItemsCount: 0 }
  }
  let itemsCount = 0
  let smallItemsCount = 0
  let largeItemsCount = 0
  Object.keys(itemsMetadata).forEach(key => {
    const value = itemsMetadata[key as OrderItemType] ?? 0
    const size = ORDER_ITEM_SIZES[key as OrderItemType] ?? 'M'
    switch (size) {
      case 'S':
        smallItemsCount += value
        break
      case 'M':
        itemsCount += value
        break
      case 'L':
        largeItemsCount += value
        break
    }
  })
  return {
    itemsCount: itemsCount,
    smallItemsCount: smallItemsCount,
    largeItemsCount: largeItemsCount
  }
}

export function convertToInternal(default_: DefaultOrder, defaultItemsMetadata: ItemsMetadata = DEFAULT_ITEMS_METADATA): ItemsMetadata {
  if (
    (!default_.itemMetadata && !default_.smallItemsCount && !default_.largeItemsCount) ||
    !default_.itemMetadata ||
    Object.keys(default_.itemMetadata).filter(k => !!default_.itemMetadata![k as OrderItemType]).length === 0) {
    return defaultItemsMetadata
  }
  return default_.itemMetadata
}

export function getItemsMetadataOrDefault(itemsMetadata: ItemsMetadata): ItemsMetadata {
  return Object.keys(itemsMetadata).filter(k => !!itemsMetadata[k as OrderItemType]).length ?
    itemsMetadata : DEFAULT_ITEMS_METADATA
}

export function computeDefaultOrderItemsCount(domain?: {
  itemsCount?: number,
  smallItemsCount?: number,
  largeItemsCount?: number
}): number {
  return (domain?.itemsCount ?? 0) + (domain?.smallItemsCount ?? 0) + (domain?.largeItemsCount ?? 0)
}
