import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["i18n"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    i18n: _ctx.$options.name,
    class: "section-template relative-position"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{
                  'section-template__background__overflow-up': _ctx.overflowUp,
                  'section-template__background__overflow-down': _ctx.overflowDown,
                  'section-template__background__dense': _ctx.dense,
                }, "absolute-full section-template__background"])
    }, null, 2),
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}