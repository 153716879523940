
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue'
import PageTemplate from 'components/template/page-template.vue'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import useFormat from 'src/compositions/format'
import { QBtn } from 'quasar'
import policyPrefetch from 'src/compositions/prefetch/policyPrefetch'
import usePolicyHome, { PolicyCountries } from 'stores/policyHome'
import { refStore } from 'stores/__common'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import usePageExtensions from 'src/compositions/pageExtensions'
import ErrorHandleTemplate from 'components/template/error-handle-template.vue'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'
import { matArrowBack } from 'src/config/icons'

const name = 'policy'

type CountryFilterName = PolicyCountries | 'default'

const Markdown = defineAsyncComponent(() => import(/* webpackChunkName: "markdown", webpackMode: "lazy" */'components/simple/markdown.vue'))

export default defineComponent({
  name,
  components: {
    QBtn,
    PageTemplate,
    Markdown,
    Breadcrumbs,
    ErrorHandleTemplate,
  },
  async preFetch(options) {
    await policyPrefetch(options)
  },
  setup() {
    const {
      policy: policyHome,
      country,
      loadOne
    } = refStore(usePolicyHome())
    const { countryKey } = useUserPreviewExtension()
    const {
      tp,
      st,
      t
    } = useI18n$(name)
    const { fdf } = useFormat()
    const { toPrevPage } = useRoutes()

    const countryFilter = ref<CountryFilterName>(country.value || 'default')
    const body = computed(() => st(policyHome.value.body))
    const policy = computed(() => policyHome.value.required!)

    watch(countryFilter, newCountryFilter => {
      loadOne(newCountryFilter !== 'default' ? newCountryFilter : countryKey.value)
    })

    return {
      matArrowBack,
      policy,
      content: policyHome,
      body,
      countryFilter,
      tp,
      t,
      st,
      fdf,
      toPrevPage,
      loadOne: () => loadOne(countryFilter.value !== 'default' ? countryFilter.value : countryKey.value),
      ...usePageExtensions({
        page: name,
        breadcrumbs: (title) => ([{
          path: 'policy',
          label: t('global.pages.policy')
        }])
      })
    }
  }
})
