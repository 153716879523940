import { computed, InjectionKey, ref } from 'vue'
import { inject } from 'src/compositions/common'
import { Language } from 'src/model/language.model'

export interface VisitorData {
  distinct_id: string
  email: string
  name: string
  phone: string
}

export interface Chat {
  setVisitorData: (data: VisitorData | {}) => void
  open: () => void
  close: () => void
  show: () => void
  hide: () => void
  on: (type: 'ready' | 'open' | 'close', callback: () => void) => void
  track: (type: 'chatopened') => void
}

declare global {
  interface Document {
    tidioChatLang: Language
    tidioIdentify: VisitorData | {}
  }
}

declare global {
  interface Window {
    tidioChatApi: Chat
  }
}

const chatKey: InjectionKey<ReturnType<typeof useChat_>> = Symbol('qeepl_chat')

const scriptId = 'tidio-script'
const chatIframeId = 'tidio-chat-iframe'
const chatCodeId = 'tidio-chat-code'
const chatId = 'tidio-chat'

function useChat_() {
  const loading = ref(false)
  const showOnStart = ref(false)
  const chatApi = ref<Chat | null>(null)

  const loaded = computed(() => !!chatApi.value)

  const hide = () => chatApi.value?.hide()
  const show = () => chatApi.value?.show()
  const open = () => chatApi.value?.open()
  const close = () => chatApi.value?.close()

  return {
    scriptId,
    chatIframeId,
    chatCodeId,
    chatId,
    chatApi,
    loading,
    loaded,
    showOnStart,
    hide,
    show,
    close,
    open
  }
}

export default function useChat() {
  return inject(chatKey, () => useChat_())
}
