import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "row items-center q-mb-sm" }
const _hoisted_3 = { class: "col text-left text-weight-bolder text-body1 text-ellipsis" }
const _hoisted_4 = { class: "col-auto text-right" }
const _hoisted_5 = { class: "row items-center justify-between q-mb-md" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = {
  key: 0,
  class: "row justify-end"
}
const _hoisted_8 = { class: "col-12 text-grey-6" }
const _hoisted_9 = { class: "row items-center" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "review-card__text" }
const _hoisted_12 = { class: "col-auto row justify-between q-pt-sm" }
const _hoisted_13 = {
  key: 0,
  class: "col"
}
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "row items-center q-mb-sm" }
const _hoisted_16 = { class: "col text-left text-weight-bolder text-body1" }
const _hoisted_17 = { class: "col-auto text-right" }
const _hoisted_18 = { class: "row items-center justify-between q-mb-md" }
const _hoisted_19 = { class: "col-auto" }
const _hoisted_20 = {
  key: 0,
  class: "row justify-end"
}
const _hoisted_21 = { class: "col-12 text-grey-6" }
const _hoisted_22 = { class: "row items-center" }
const _hoisted_23 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_rating = _resolveComponent("q-rating")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_card, {
    ref: "cardRef",
    class: _normalizeClass([{'review-card__full': _ctx.full}, "rounded-borders-large review-card"]),
    bordered: "",
    flat: "",
    i18n: _ctx.$options.name
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "column fit q-pb-sm" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.reviewerName), 1),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_q_rating, {
                  modelValue: _ctx.review.mark,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.review.mark) = $event)),
                  size: "xs",
                  icon: "img:/images/other/rating-unselect.svg",
                  "icon-selected": "img:/images/other/rating.svg",
                  "icon-half": "img:/images/other/rating-half.svg",
                  readonly: ""
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.caption)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.caption), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.text || _ctx.tp('noComment')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_ctx.largeLength)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_q_btn, {
                    label: _ctx.tp('showFull'),
                    "no-caps": "",
                    color: "grey-7",
                    flat: "",
                    padding: "sm",
                    push: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_popup_proxy, {
                        class: _normalizeClass(["review-card__dialog", {'shadow-10 rounded-borders-large': _ctx.$q.screen.gt.xs}]),
                        "transition-show": "scale",
                        "transition-hide": "scale",
                        anchor: "center middle",
                        self: "center middle",
                        target: _ctx.cardRef?.$el
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_card, {
                            bordered: "",
                            class: _normalizeClass({'rounded-borders-large': _ctx.$q.screen.gt.xs})
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_card_section, { class: "column fit" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_14, [
                                    _createElementVNode("div", _hoisted_15, [
                                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.reviewerName), 1),
                                      _createElementVNode("div", _hoisted_17, [
                                        _createVNode(_component_q_rating, {
                                          modelValue: _ctx.review.mark,
                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.review.mark) = $event)),
                                          size: "xs",
                                          icon: "img:/images/other/rating-unselect.svg",
                                          "icon-selected": "img:/images/other/rating.svg",
                                          "icon-half": "img:/images/other/rating-half.svg",
                                          readonly: ""
                                        }, null, 8, ["modelValue"])
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_18, [
                                      _createElementVNode("div", _hoisted_19, [
                                        (_ctx.caption)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.caption), 1)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_22, [
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
                                      ])
                                    ])
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["class"])
                        ]),
                        _: 1
                      }, 8, ["class", "target"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class", "i18n"]))
}