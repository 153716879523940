import { ref } from 'vue'
import { defineStore, } from 'pinia'
import { Country } from 'src/model/country.model'
import { City } from 'src/model/city.model'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import { Attraction } from 'src/model/attraction.model'
import useAnonymousApi from 'src/api/anonymous.api'
import { CompaniesResponse, CompaniesResponseMeta } from 'src/api/dto/prefetch'
import { computeDirectDestination } from 'src/functions/map'
import { DEFAULT_MAX_DESTINATION_TO_CITY_IN_KM } from 'src/model/constants'
import { Coordinates, Location, ServiceType } from 'src/model/common.model'

const useUserPreview = defineStore('userPreview', () => {
  const api = useAnonymousApi()

  const country = ref<Country | null>(null)
  const city = ref<City | null>(null)
  const attraction = ref<Attraction | null>(null)
  const companies = ref<AnonymousCompanyProjection[]>([])
  const meta = ref<CompaniesResponseMeta>({})

  const setData = (response: CompaniesResponse) => {
    country.value = response.country
    city.value = response.city
    attraction.value = response.attraction || null
    companies.value = response.companies
    meta.value = response.meta || {}
  }
  const onByCityLoaded = (response: CompaniesResponse): void => {
    setData(response)
  }
  const onByAttractionLoaded = (response: CompaniesResponse) => {
    setData(response)
  }
  const onByCompanyIdLoaded = (response: CompaniesResponse) => {
    setData(response)
  }
  const loadAllByCity = async (key: string) => {
    if (city.value?.key !== key) {
      onByCityLoaded(await api.getCompanies({ cityKey: key }, res => onByCityLoaded(res.data)))
    }
  }
  const loadAllByAttraction = async (key: string) => {
    if (attraction.value?.key !== key) {
      onByAttractionLoaded(await api.getCompanies({ attractionKey: key, }, res => onByAttractionLoaded(res.data)))
    }
  }
  const loadAllByCompanyId = async (companyId: string) => {
    if (!findCompany(companyId)) {
      onByCompanyIdLoaded(await api.getCompanies({ companyId }, res => onByCompanyIdLoaded(res.data)))
    }
  }
  const loadAllNearest = async (location: Location) => {
    if (!city.value || computeDirectDestination(location.point, city.value.center) > DEFAULT_MAX_DESTINATION_TO_CITY_IN_KM * 1000) {
      setData(await api.getCompanies({ point: location.point }, res => setData(res.data)))
    }
  }
  const findCompany = (companyId: string) => {
    return companies.value.find(c => c.id === companyId)
  }
  const removeCompany = (companyId: string) => {
    const company = findCompany(companyId)
    if (company) {
      companies.value.splice(companies.value.indexOf(company), 1)
    }
  }

  return {
    country,
    city,
    attraction,
    companies,
    meta,
    loadAllByCity,
    loadAllByAttraction,
    loadAllByCompanyId,
    loadAllNearest,
    removeCompany,
  }
})

export default useUserPreview
