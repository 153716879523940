
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import useRoutes from 'src/compositions/routes'
import useAuthorization from 'src/compositions/authorization'
import { useI18n } from 'boot/i18n'
import IconLabel from 'components/simple/icon-label.vue'
import { QBtn } from 'quasar'
import useUserDetails from 'stores/userDetails'

const name = 'authorization-button'

const matLogout = 'M0 0h24v24H0z@@fill:none;&&M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z'
const matPersonOutline = 'M0 0h24v24H0z@@fill:none;&&M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z'

export default defineComponent({
  name,
  components: {
    QBtn,
    IconLabel
  },
  props: {
    noPaddings: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup () {
    const {
      authenticated,
      user
    } = storeToRefs(useUserDetails())
    const {
      logout,
      authorizing,
      loggingOut
    } = useAuthorization()
    const {
      toLogin,
      toProfile
    } = useRoutes()
    const { t } = useI18n()

    return {
      matLogout,
      matPersonOutline,
      authenticated,
      user,
      authorizing,
      loggingOut,
      toLogin,
      toProfile,
      logout,
      t
    }
  }
})
