import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_large_btn = _resolveComponent("large-btn")!

  return (_openBlock(), _createBlock(_component_large_btn, {
    fill: _ctx.fill,
    label: _ctx.buttonLabel,
    runnable: _ctx.mainRunnable,
    to: _ctx.to,
    disable: _ctx.disable,
    class: "luggage-button",
    color: "accent",
    sync: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, null, 8, ["fill", "label", "runnable", "to", "disable"]))
}