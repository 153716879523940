
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue'
import { QBtn, QInput, QNoSsr } from 'quasar'
import PhoneInput from 'components/simple/inputs/phone-input.vue'
import useLazyDialog from 'src/compositions/lazyDialog'
import useAuthorization from 'src/compositions/authorization'
import { JwtResponse } from 'src/api/dto/user'
import { refStore } from 'stores/__common'
import usePhoneInput from 'src/compositions/inputs/phoneInput'
import useAnalytics from 'src/compositions/analytics'
import useUserDetails from 'stores/userDetails'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'
import useValidation from 'src/compositions/validation/validation'
import useValidationRules from 'src/compositions/validation/validationRules'

const name = 'login-input-check'

export default defineComponent({
  name,
  components: {
    QNoSsr,
    QBtn,
    QInput,
    PhoneInput,
    LoginCheckCode: defineAsyncComponent(() => import(/* webpackChunkName: "login-code-check"*/ 'components/advanced/lazy/login-code-check.vue'))
  },
  props: {
    isPhone: {
      type: Boolean,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    redirect: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const { blocked } = refStore(useUserDetails())
    const {
      handleJwtResponse,
      handleAuthError
    } = useAuthorization()
    const {
      dialogModel,
      requestDialogLoading,
      openDialog
    } = useLazyDialog()
    const { logEvent } = useAnalytics()
    const { countryKey } = useUserPreviewExtension()
    const { email: emailRule, required, conditional, none } = useValidationRules()

    const {
      phone,
      valid: validPhone,
    } = usePhoneInput()
    const checkSuccess = ref<boolean | null>(null)
    const email = ref('')

    const {
      valid: emailFieldsValid,
      validations
    } = useValidation({
      email: {
        rules: [
          conditional(() => !props.isPhone, required, none),
          conditional(() => !props.isPhone, emailRule, none)
        ],
        ref: email
      },
    })

    const valid = computed(() => {
      return props.isPhone ? validPhone.value : emailFieldsValid.value
    })

    const startCheck = () => {
      if (!props.disable && valid.value) {
        openDialog()
      }
    }
    const onSuccess = (jwt: JwtResponse) => {
      checkSuccess.value = true
      handleJwtResponse(jwt, true, props.redirect)
        .then(() => {
          logEvent({
            name: 'sign_up',
            params: {
              method: props.isPhone ? 'Phone' : 'Email'
            }
          })
          dialogModel.value = false
        })
    }
    const onError = () => {
      checkSuccess.value = false
    }
    const onExpired = () => {
      checkSuccess.value = false
      handleAuthError()
    }

    watch(() => props.isPhone, newIsPhone => {
      if (newIsPhone) {
        phone.value = ''
      } else {
        email.value = ''
      }
    })

    return {
      phone,
      email,
      dialogModel,
      requestDialogLoading,
      checkSuccess,
      valid,
      blocked,
      countryKey,
      validations,
      emailFieldsValid,
      validPhone,
      onSuccess,
      onError,
      onExpired,
      startCheck
    }
  }
})
