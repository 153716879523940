import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["i18n"]
const _hoisted_2 = { class: "col-12 col-sm-auto" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "col-12 col-sm" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_luggage_button = _resolveComponent("luggage-button")!

  return (_openBlock(), _createElementBlock("div", {
    i18n: _ctx.$options.name,
    class: "price-banner row items-center justify-center"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["col-12 price-banner__col", {'q-pa-xl': _ctx.$q.screen.gt.md, 'q-pa-lg': _ctx.$q.screen.lt.lg}])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["row items-center justify-between", {'reverse': _ctx.type === 'DEFAULT', 'text-right': _ctx.type === 'SELF'}])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            loading: "lazy",
            src: `${ _ctx.IMGKIT_URL }/System${ _ctx.image }`,
            alt: _ctx.alt,
            width: "300",
            height: "300",
            class: _normalizeClass({'full-width': _ctx.$q.screen.xs})
          }, null, 10, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", {
            class: "text-h2 q-pt-md q-pb-lg",
            innerHTML: _ctx.title
          }, null, 8, _hoisted_5),
          _createElementVNode("p", {
            class: "q-pb-lg text-weight-bold",
            innerHTML: _ctx.explanation
          }, null, 8, _hoisted_6),
          _createElementVNode("div", {
            class: "q-mb-xl",
            innerHTML: _ctx.description
          }, null, 8, _hoisted_7),
          _createVNode(_component_luggage_button, {
            fill: _ctx.$q.screen.xs,
            query: _ctx.query,
            label: _ctx.label
          }, null, 8, ["fill", "query", "label"])
        ])
      ], 2)
    ], 2)
  ], 8, _hoisted_1))
}