import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute-bottom text-center object-card__title-container"
}
const _hoisted_2 = {
  key: 0,
  class: "text-small text-transform-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    class: "object-card block fit"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_img, {
        ratio: 5 / 7,
        src: _ctx.loadingError ? _ctx.fallbackSrc : `${ _ctx.src }?tr=w-200,h-300`,
        alt: _ctx.title,
        class: "fit object-card__img",
        loading: "lazy",
        onError: _ctx.onLoadError,
        onLoad: _ctx.onLoadFinished
      }, {
        default: _withCtx(() => [
          (_ctx.loadingFinished && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: _normalizeClass([{'q-pt-xs': _ctx.caption, 'q-py-sm': !_ctx.caption}, "text-weight-bold text-black"])
                }, _toDisplayString(_ctx.title), 3),
                (_ctx.caption)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.caption), 1))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 1,
                class: "absolute-full fit bg-grey-4"
              }))
        ]),
        loading: _withCtx(() => [
          _createVNode(_component_q_skeleton, { class: "absolute-full fit bg-grey-4" })
        ]),
        _: 1
      }, 8, ["src", "alt", "onError", "onLoad"])
    ]),
    _: 1
  }, 8, ["to"]))
}