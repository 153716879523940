import { ref } from 'vue'
import { defineStore } from 'pinia'
import useUserApi from 'src/api/user.api'
import { UserOrderProjection } from 'src/model/order.model'

const useExistingOrder = defineStore('existingOrder', () => {
  const api = useUserApi()

  const existingOrder = ref<UserOrderProjection | null>(null)

  const loadExistingOrder = async (orderId: string) => {
    existingOrder.value = await api.payOrder(orderId).catch(() => null)
  }

  return {
    existingOrder,
    loadExistingOrder
  }
})

export default useExistingOrder
