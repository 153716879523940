import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row items-center text-center q-py-sm home-ads-card rounded-borders-large" }
const _hoisted_2 = { class: "col-4 col-sm-2 col-lg-3 col-xl-2 full-height home-ads-card__text-col--full relative-position flex flex-center" }
const _hoisted_3 = ["src", "alt", "width", "height"]
const _hoisted_4 = { class: "home-ads-card__text-col col-8 col-sm-10 col-lg-9 col-xl-10" }
const _hoisted_5 = { class: "row items-center text-left q-pr-md" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `/images/home-page-template/${_ctx.src}`,
        alt: _ctx.title,
        loading: "lazy",
        width: _ctx.width,
        height: _ctx.height
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", {
          class: "col-12 q-pb-md text-h3 text-weight-bold",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_6),
        _createElementVNode("p", {
          class: "col-12",
          innerHTML: _ctx.description
        }, null, 8, _hoisted_7)
      ])
    ])
  ]))
}