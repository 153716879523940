import { computed, ref } from 'vue'
import { AsyncRunnable, ErrorHandler, SuccessHandler } from 'src/types/async'

const UNKNOWN_ERROR = new Error('UNKNOWN')

export default function useAsync<T = void>(runnable: AsyncRunnable<T>, successHandler?: SuccessHandler<T>, errorHandler?: ErrorHandler) {
  const running = ref(false)
  const finished = ref(false)
  const result = ref<T | null>(null)
  const errorResult = ref<any | null>(null)

  const success = computed(() => {
    return !running.value && !errorResult.value && finished.value
  })
  const error = computed(() => {
    return !running.value && !!errorResult.value && finished.value
  })

  const run = (...args: any) => {
    clear()
    running.value = true
    return runnable(...args)
      .then(res => {
        // @ts-ignore
        result.value = res
        successHandler ? successHandler(res, ...args) : void 0
      })
      .catch(err => {
        errorResult.value = err || UNKNOWN_ERROR
        errorHandler ? errorHandler(err, ...args) : void 0
      })
      .finally(() => {
        finished.value = true
        running.value = false
      })
  }

  const clear = () => {
    finished.value = false
    running.value = false
    result.value = null
    errorResult.value = null
  }

  return {
    running,
    result,
    errorResult,
    success,
    error,
    run,
    clear
  }
}
