
import { defineComponent } from 'vue'

const name = 'section-template'

export default defineComponent({
  name,
  props: {
    overflowUp: {
      type: Boolean,
      default: false
    },
    overflowDown: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    return {}
  }
})
