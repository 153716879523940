import { openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "col-12 col-sm-6" }
const _hoisted_3 = { class: "flex flex-center rounded-borders-large storing-card__img-container" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "col-12 col-sm-6" }
const _hoisted_6 = { class: "row fit justify-left items-center" }
const _hoisted_7 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_responsive = _resolveComponent("q-responsive")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    bordered: "",
    class: "rounded-borders-large storing-card",
    flat: "",
    i18n: _ctx.$options.name
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "row justify-center fit q-pa-lg" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["row q-col-gutter-lg", {'reverse': _ctx.pictureRight}])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_responsive, { ratio: 4 / 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_Transition, {
                      "enter-active-class": "animated fadeIn",
                      "leave-active-class": "animated fadeOut",
                      mode: "out-in"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          (_openBlock(), _createElementBlock("img", {
                            key: _ctx.entries[_ctx.model].item,
                            src: `${ _ctx.IMGKIT_URL }/System/${ _ctx.folder }/${ _ctx.entries[_ctx.model].item }.png`,
                            alt: _ctx.entries[_ctx.model].label,
                            class: "rounded-borders",
                            loading: "lazy"
                          }, null, 8, _hoisted_4))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (e, index) => {
                      return (_openBlock(), _createBlock(_component_q_btn, {
                        key: index,
                        label: e.label,
                        color: index === _ctx.model ? 'primary' : 'grey-7',
                        class: "text-weight-bold text-lowercase",
                        flat: "",
                        unelevated: "",
                        padding: "sm xs",
                        onClick: ($event: any) => (_ctx.model = index)
                      }, null, 8, ["label", "color", "onClick"]))
                    }), 128))
                  ])
                ])
              ])
            ], 2)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["i18n"]))
}