import { ref } from 'vue'

export default function usePhoneInput(initialValue = '') {
  const phone = ref(initialValue)
  const valid = ref(false)

  return {
    phone,
    valid
  }
}
