import { boot } from 'quasar/wrappers'
import axios from 'axios'
import { SITE_URL } from 'src/model/constants'
import { setupAxiosInterceptors } from 'boot/common/axios'

export default boot(({
                       app,
                       router,
                       urlPath,
                       ssrContext,
                       store
                     }) => {
  const self = axios.create({ baseURL: SITE_URL })
  store.api = { self }
  setupAxiosInterceptors(self, app, router, urlPath, store)
})
