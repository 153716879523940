import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-center items-stretch" }
const _hoisted_2 = { class: "row items-center justify-center q-pt-md q-pb-xl q-px-md" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = {
  key: 1,
  class: "col flex flex-center q-mb-xl q-pt-xl"
}
const _hoisted_5 = { class: "column col-auto justify-center text-center text-h3" }
const _hoisted_6 = { class: "row items-center justify-center q-pb-xl q-px-md" }
const _hoisted_7 = { class: "col-12 col-sm-auto q-pt-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_qeepl_rating = _resolveComponent("qeepl-rating")!
  const _component_review_card = _resolveComponent("review-card")!
  const _component_container_template = _resolveComponent("container-template")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_luggage_button = _resolveComponent("luggage-button")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.t('global.pages.reviews'),
    class: "reviews",
    column: "",
    position: "start"
  }, {
    breadcrumbs: _withCtx(() => [
      _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
    ]),
    "after-preview": _withCtx(() => [
      _createVNode(_component_qeepl_rating, {
        dense: _ctx.$q.screen.xs,
        class: "q-my-lg",
        fill: ""
      }, null, 8, ["dense"])
    ]),
    default: _withCtx(() => [
      (_ctx.reviews.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_container_template, { class: "q-pt-xl" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviews, (r) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: r.id,
                      class: "col-auto q-pa-md"
                    }, [
                      _createVNode(_component_review_card, { review: r }, null, 8, ["review"])
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_btn, {
                  label: _ctx.tp('loadMore'),
                  loading: _ctx.loadingReviews,
                  disable: _ctx.noMore,
                  class: "text-transform-none",
                  color: "primary",
                  flat: "",
                  onClick: _ctx.loadNext
                }, null, 8, ["label", "loading", "disable", "onClick"])
              ])
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.tp('noReviews')), 1)
          ])),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_luggage_button, {
            fill: _ctx.$q.screen.xs
          }, null, 8, ["fill"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}