import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "text-center storing-option-card__title-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_responsive = _resolveComponent("q-responsive")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    class: "storing-option-card rounded-borders-large block fit"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_responsive, {
        class: "storing-option-card rounded-borders-large",
        ratio: 5 / 6
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["col q-px-md", {'q-pt-lg': _ctx.$q.screen.gt.md, 'q-pt-md': _ctx.$q.screen.lt.lg, }])
            }, [
              _createVNode(_component_q_img, {
                src: `${ _ctx.IMGKIT_URL }/System${ _ctx.image }`,
                alt: _ctx.caption,
                class: "fit",
                loading: "lazy",
                onError: _ctx.onLoadError,
                onLoad: _ctx.onLoadFinished
              }, {
                loading: _withCtx(() => [
                  _createVNode(_component_q_skeleton, { class: "absolute-full fit bg-grey-4" })
                ]),
                _: 1
              }, 8, ["src", "alt", "onError", "onLoad"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-auto", {'q-pa-lg': _ctx.$q.screen.gt.md, 'q-pa-md': _ctx.$q.screen.lt.lg, }])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["text-transform-none", {'q-py-md': _ctx.$q.screen.gt.md, 'q-py-sm': _ctx.$q.screen.lt.lg, 'text-small': _ctx.$q.screen.lt.md }])
                }, _toDisplayString(_ctx.caption), 3)
              ])
            ], 2)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}