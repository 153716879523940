
import { defineComponent, onMounted, onUnmounted, watch, toRef } from 'vue'
import { storeToRefs } from 'pinia'
import useChat, { VisitorData } from 'src/compositions/chat'
import { UserProjection } from 'src/model/user.model'
import { useI18n } from 'boot/i18n'
import useLoadScript from 'src/compositions/loadScript'
import useUserDetails from 'stores/userDetails'
import useEnvironment from 'src/compositions/environment'

const name = 'chat'

export default defineComponent({
  name,
  props: {
    open: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const {
      scriptId,
      chatApi,
      chatIframeId,
      chatId,
      chatCodeId,
      loading,
      showOnStart,
      show: showChat,
      hide,
      open: openChat,
      close
    } = useChat()
    const { language } = useI18n()
    const { user, isAnonymous, isAdmin, isSecondaryAdmin } = storeToRefs(useUserDetails())
    const { load: scriptLoad } = useLoadScript({
      id: scriptId,
      url: 'https://code.tidio.co/fhpcfwhsfnpw9zgvnvputluhsvroxpgg.js'
    })
    const { ipad } = useEnvironment()

    showOnStart.value = props.show
    const open = toRef(props, 'open')
    const show = toRef(props, 'show')

    onMounted(() => {
      if (!(isAdmin.value && isSecondaryAdmin.value)) {
        load()
      }
    })

    onUnmounted(() => {
      hide()
    })

    const script = () => document.getElementById(scriptId)
    const iframe = () => document.getElementById(chatIframeId)
    const codeIframe = () => document.getElementById(chatCodeId)
    const chatDiv = () => document.getElementById(chatId)
    const setZIndex = (zIndex: '2100' | '7000') => {
      const element = iframe()
      if (element) {
        element.style.zIndex = zIndex
      }
    }
    const getUserName = (user: UserProjection) => {
      return !user.firstName && !user.lastName ? user.login : `${ user.firstName || 'Unknown' }${ user.lastName ? ` ${ user.lastName }` : '' }`
    }
    const getVisitorData = (): VisitorData | {} => {
      if (user.value && !isAnonymous.value) {
        return {
          distinct_id: user.value.id,
          email: user.value.email,
          name: getUserName(user.value),
          phone: user.value.login || '<none>'
        }
      }
      return {}
    }
    const onLoadedBase = () => {
      chatApi.value = window.tidioChatApi || null
      if (open.value) {
        openChat()
      } else if (show.value) {
        doShow()
      } else {
        hide()
      }
      if (ipad) {
        chatDiv()?.classList?.add('ipad')
      }
    }
    const onLoaded = () => {
      onLoadedBase()
      chatApi.value?.on('open', () => {
        setZIndex('7000')
        chatApi.value?.track('chatopened')
      })
      chatApi.value?.on('close', () => {
        setZIndex('2100')
        if (!showOnStart.value) {
          hide()
        }
      })
    }
    const doShow = () => {
      showChat()
      setZIndex('2100')
    }
    const doLoad = () => {
      loading.value = true
      document.tidioChatLang = language.value
      document.tidioIdentify = getVisitorData()
      remove()
      scriptLoad()
        .then(() => {
          if (chatApi.value) {
            chatApi.value.on('ready', onLoaded)
          } else {
            document.addEventListener('tidioChat-ready', onLoaded)
          }
        })
        .finally(() => loading.value = false)
    }
    const remove = () => {
      chatApi.value = null
      removeElement(script())
      removeElement(codeIframe())
      removeElement(chatDiv())
      removeElement(iframe())
    }
    const removeElement = (element: HTMLElement | null) => {
      if (element) {
        element.parentNode?.removeChild(element)
      }
    }
    const load = (force: boolean = false) => {
      if (force || document.tidioChatLang !== language.value || !chatApi.value) {
        doLoad()
      } else if (!loading.value) {
        onLoadedBase()
      }
    }

    watch(user, () => {
      if (!(isAdmin.value || isSecondaryAdmin.value)) {
        chatApi.value?.setVisitorData(getVisitorData())
      } else {
        hide()
      }
    })
    watch(language, () => load(true))
    watch(show, newShow => newShow ? doShow() : hide())
    watch(open, newOpen => newOpen ? openChat() : close())
  }
})
