
import { defineComponent, PropType } from 'vue'
import { AsyncRunnable } from 'src/types/async'
import useAsync from 'src/compositions/async'
import asyncProps from 'src/components/props/async'
import { QBtn } from 'quasar'

type Color = 'primary' | 'accent'

export default defineComponent({
  name: 'large-btn',
  components: {
    QBtn
  },
  props: {
    ...asyncProps,
    sync: {
      type: Boolean,
      default: false
    },
    color: {
      type: String as PropType<Color>,
      default: 'primary'
    },
    label: {
      type: String,
      required: true
    },
    to: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const runnable = (props.sync ? () => Promise.resolve<void>(props.runnable()) : props.runnable) as AsyncRunnable

    const { run, running } = useAsync(runnable, props.successHandler, props.errorHandler)

    return {
      running,
      onClick: run
    }
  }
})
