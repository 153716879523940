import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "full-width"
}
const _hoisted_2 = { class: "row col-12 justify-center q-mt-xs text-no-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_label = _resolveComponent("icon-label")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: _normalizeClass([(_ctx.bgColor ? `bg-${ _ctx.bgColor } ` : ' ') + (_ctx.dense ? 'text-small' : ''), "full-width text-transform-none rounded-borders location-select__icon-btn"]),
    disable: _ctx.disable,
    padding: _ctx.padding,
    flat: "",
    size: "md",
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon_label, {
        icon: _ctx.icon,
        "no-padding": !_ctx.label,
        size: _ctx.dense ? 'xs' : 'sm',
        type: _ctx.type
      }, _createSlots({ _: 2 }, [
        (_ctx.label)
          ? {
              name: "default",
              fn: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["icon", "no-padding", "size", "type"]),
      (_ctx.descriptionLabel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.descriptionLabel), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "disable", "padding", "onClick"]))
}