
import { defineComponent } from 'vue'
import { QBtn } from 'quasar'
import { useI18n$ } from 'boot/i18n'
import { REVIEWS } from 'pages/names'
import PageTemplate from 'components/template/page-template.vue'
import LuggageButton from 'components/advanced/luggage-button.vue'
import QeeplRating from 'components/advanced/qeepl-rating.vue'
import ContainerTemplate from 'components/template/container-template.vue'
import ReviewCard from 'components/simple/review-card.vue'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import { refStore } from 'stores/__common'
import reviewsPrefetch from 'src/compositions/prefetch/reviewsPrefetch'
import useReviews from 'stores/reviews'
import usePageExtensions from 'src/compositions/pageExtensions'
import { DEFAULT_LOGO_512 } from 'src/model/constants'
import useAppData from 'stores/appData'

const name = REVIEWS

export default defineComponent({
  name,
  components: {
    QBtn,
    PageTemplate,
    LuggageButton,
    QeeplRating,
    ContainerTemplate,
    ReviewCard,
    Breadcrumbs
  },
  async preFetch(options) {
    await reviewsPrefetch(options)
  },
  setup() {
    const { reviews, loadingReviews, noMore, loadNext } = refStore(useReviews())
    const { total: totalReview } = refStore(useAppData())
    const { tp, language, t } = useI18n$(name)

    const doLoadNext = () => loadNext(language.value)

    return {
      reviews,
      loadingReviews,
      noMore,
      loadNext: doLoadNext,
      t,
      tp,
      ...usePageExtensions({
        page: name,
        organization: {
          totalReview: totalReview.value,
          image: DEFAULT_LOGO_512
        },
        breadcrumbs: () => ([
          { path: 'reviews', label: t('global.pages.reviews') }
        ])
      })
    }
  }
})
