
import { defineComponent, PropType } from 'vue'
import { FAQEntry } from 'src/model/common.model'
import { useI18n$ } from 'boot/i18n'
import { QCard, QCardSection, QExpansionItem, QItemLabel, QItemSection } from 'quasar'
import { matKeyboardArrowDown, matKeyboardArrowUp } from 'src/config/icons'

const name = 'faq'

export default defineComponent({
  name,
  components: {
    QExpansionItem,
    QCard,
    QCardSection,
    QItemSection,
    QItemLabel
  },
  props: {
    entries: {
      type: Array as PropType<FAQEntry[]>,
      required: true
    }
  },
  setup () {
    const { tp } = useI18n$(name)

    return {
      matKeyboardArrowDown,
      matKeyboardArrowUp,
      tp
    }
  }
})
