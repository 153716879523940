import { InjectionKey } from 'vue'
import { useQuasar } from 'quasar'
import { APP, ENV, PROD, SSR } from 'src/model/constants'
import { inject } from 'src/compositions/common'

const environmentKey: InjectionKey<ReturnType<typeof useEnvironment_>> = Symbol('qeepl_environment')

function useEnvironment_() {
  const { platform } = useQuasar()

  const ssr = SSR
  const csr = !ssr
  const prod = PROD
  const app = APP
  const env = ENV
  const android = app && platform.is.android
  const chrome = !app && platform.is.chrome
  const safari = !app && platform.is.safari
  const mobile = !app && platform.is.mobile
  const ipad = app && platform.is.ipad
  const ios = app && platform.is.ios
  const name = app ? (platform.is.android ? 'android' : 'ios') : platform.is.name as string

  return {
    ssr,
    csr,
    prod,
    app,
    env,
    android,
    ios,
    chrome,
    safari,
    mobile,
    ipad,
    name
  }
}

export default function useEnvironment() {
  return inject(environmentKey, () => useEnvironment_())
}
