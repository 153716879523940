
  import { computed, defineComponent } from 'vue'
  import { QImg, QSkeleton } from 'quasar'
  import { useI18n$ } from 'boot/i18n'
  import LuggageButton from 'components/advanced/luggage-button.vue'
  import useLoadImage from 'src/compositions/loadImage'

  const name = 'banner-template'

export default defineComponent({
  name,
  components: {
    QImg,
    QSkeleton,
    LuggageButton
  },
  props: {
    alt: {
      type: String,
      default: ''
    },
    large: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const { language } = useI18n$(name)
    const { onLoadFinished: onBackgroundLoaded, loadingFinished: backgroundLoaded } = useLoadImage()
    const { onLoadFinished: onForegroundLoaded, loadingFinished: foregroundLoaded } = useLoadImage()

    const imagesLoaded = computed(() => backgroundLoaded.value && foregroundLoaded.value)

    return {
      language: computed(() => language.value !== 'ru' ? 'en' : 'ru'),
      imagesLoaded,
      onBackgroundLoaded,
      onForegroundLoaded,
    }
  }
})
