import { InjectionKey, ref } from 'vue'
import { useRouter } from 'vue-router'
import { inject } from 'src/compositions/common'
import useAnonymousApi from 'src/api/anonymous.api'
import { JwtResponse } from 'src/api/dto/user'
import { TOKEN_STORE_NAME } from 'src/model/constants'
import useNotify from 'src/compositions/notify'
import useUserDetails from 'stores/userDetails'
import { useI18n } from 'boot/i18n'
import { refStore } from 'stores/__common'
import useRoutes from 'src/compositions/routes'
import { removeValue, saveValue } from 'src/functions/bridge'
import { extractLanguage } from 'src/functions/utils'
import useEnvironment from 'src/compositions/environment'

const authorizationKey: InjectionKey<ReturnType<typeof useAuthorization_>> = Symbol('qeepl_authorization')

function useAuthorization_() {
  const router = useRouter()
  const {
    load,
    clear,
    isAdmin,
    isPartner,
    isOnboardingUser,
    isSecondaryAdmin,
    isPartnerOwner,
    token
  } = refStore(useUserDetails())
  const { authorize, logout } = useAnonymousApi()
  const { positive, negative } = useNotify()
  const { toAdmin, toMyBusiness, toOnboarding, toRoot } = useRoutes()
  const { language, t } = useI18n()
  const { app } = useEnvironment()

  const authorizing = ref(false)
  const loggingOut = ref(false)
  const returnUrl = ref('')

  const handleJwtResponse = (jwt: JwtResponse, rememberMe: boolean = true, redirect: boolean = true): Promise<void> => {
    const doHandleJwtResponse = () => {
      return Promise.resolve()
        .then(() => saveValue(TOKEN_STORE_NAME, jwt.idToken))
        .then(() => load(jwt.idToken))
        .then(() => positive(t('signingIn.success')))
        .then(() => {
          redirect && setTimeout(() => handleRedirect(), 1500)
        })
    }
    return doLogout()
      .then(() => doHandleJwtResponse())
      .catch(() => doHandleJwtResponse())
  }

  const handleAuthError = (error?: any) => {
    negative(t('signingIn.error'))
  }

  const saveReturnUrl = (url: string) => {
    returnUrl.value = url
  }

  const handleRoleRedirect = (): Promise<boolean> => {
    if (app) {
      return Promise.resolve(false)
    }
    let promise: Promise<any> | undefined
    if (isAdmin.value || isSecondaryAdmin.value) {
      promise = router.push(toAdmin())
    } else if (isPartner.value || isPartnerOwner.value) {
      promise = router.push(toMyBusiness())
    } else if (isOnboardingUser.value) {
      promise = router.push(toOnboarding())
    }
    return promise ? promise.then(() => true) : Promise.resolve(false)
  }

  const handleReturnRedirect = () => {
    if (returnUrl.value) {
      let url = returnUrl.value
      const routeLanguage = extractLanguage(url)
      if (routeLanguage && routeLanguage !== language.value) {
        url = url.replace(`/${ routeLanguage }`, `/${ language.value }`)
      }
      return router.push(url)
        .then(() => {
          returnUrl.value = ''
        })
    }
    return Promise.resolve()
  }

  const handleRedirect = () => {
    if (returnUrl.value) {
      return handleReturnRedirect()
    }
    return handleRoleRedirect()
      .then(res => !res ? router.push(toRoot()) : void 0)
      .then(() => {})
  }

  const tryLoadUser = (): Promise<void> => {
    return load()
      .then(() => handleReturnRedirect())
  }

  const doAuthorize = (username: string, password: string, rememberMe: boolean = true): Promise<boolean> => {
    authorizing.value = true
    return authorize({ username, password, rememberMe })
      .then((res: JwtResponse) => handleJwtResponse(res, rememberMe))
      .then(() => true)
      .catch(() => {
        handleAuthError()
        return false
      })
      .finally(() => authorizing.value = false)
  }

  const doLogout = () => {
    loggingOut.value = true
    return logout()
      .then(() => removeValue(TOKEN_STORE_NAME))
      .finally(() => {
        clear()
        loggingOut.value = false
      })
  }

  const getToken = () => token.value || null

  return {
    authorizing,
    loggingOut,
    authorize: doAuthorize,
    logout: doLogout,
    handleJwtResponse,
    handleAuthError,
    getToken,
    saveReturnUrl,
    tryLoadUser,
  }
}

export default function useAuthorization() {
  return inject(authorizationKey, () => useAuthorization_())
}
