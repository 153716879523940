import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "banner-template__info-box"
}
const _hoisted_2 = { class: "column full-height justify-center items-center text-white" }
const _hoisted_3 = { class: "col-auto full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'banner-template__large': _ctx.large,}, "relative-position fit banner-template"])
  }, [
    (_ctx.$q.screen.gt.xs)
      ? (_openBlock(), _createBlock(_component_q_img, {
          key: 0,
          alt: `${ _ctx.alt } background`,
          class: "banner-template__background",
          loading: "lazy",
          src: "https://ik.imagekit.io/f8xal2viyc0/System/background?ik-sdk-version=javascript-1.4.3&updatedAt=1665134175633",
          onLoad: _ctx.onBackgroundLoaded
        }, {
          loading: _withCtx(() => [
            _createVNode(_component_q_skeleton, {
              class: "full-width bg-grey-4",
              height: "300px"
            })
          ]),
          _: 1
        }, 8, ["alt", "onLoad"]))
      : _createCommentVNode("", true),
    (_ctx.$q.screen.gt.xs)
      ? (_openBlock(), _createBlock(_component_q_img, {
          key: 1,
          alt: `${ _ctx.alt } foreground`,
          src: `https://ik.imagekit.io/f8xal2viyc0/System/Qeepl_${ _ctx.language }.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1665134176308`,
          class: "absolute-bottom-right banner-template__foreground",
          loading: "lazy",
          onLoad: _ctx.onForegroundLoaded
        }, null, 8, ["alt", "src", "onLoad"]))
      : _createCommentVNode("", true),
    (_ctx.imagesLoaded || _ctx.$q.screen.xs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}