import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { Content } from 'src/model/content.model'

const useTermsHome = defineStore('termsHome', () => {
  const api = useAnonymousApi()

  const terms = ref<Content>(null as unknown as Content) as Ref<Content>
  const clientType = ref(false)

  const loadOne = async (country: string, client?: boolean) => {
    clientType.value = client || false
    terms.value = await api.getTerms(clientType.value, country === 'RU' ? 'RU' : '')
  }

  return {
    terms,
    clientType,
    loadOne
  }
})

export default useTermsHome
