import useCompaniesMap from 'stores/companiesMap'
import { PreFetchOptions } from '@quasar/app-webpack'
import useUserPreview from 'stores/userPreview'
import { COMPANIES_MAP } from 'pages/names'
import useExistingOrder from 'stores/existingOrder'
import useUserLocation, { LocationType } from 'stores/userLocation'
import { DEFAULT_COORDINATES } from 'src/model/constants'

export default async function companiesMapPrefetch (options: PreFetchOptions<any>) {
  const locationState = useUserLocation(options.store)
  const previewState = useUserPreview(options.store)
  const companiesMapState = useCompaniesMap(options.store)
  const userExistingOrder = useExistingOrder(options.store)

  const { currentRoute } = options

  if ('companyId' in currentRoute.params) {
    await previewState.loadAllByCompanyId(currentRoute.params.companyId as string)
    companiesMapState.setOpenCompany(previewState.companies.find(c => c.id === currentRoute.params.companyId) || null)
    if (!companiesMapState.openCompany) {
      await previewState.loadAllNearest(locationState.location)
      options.redirect({
        name: COMPANIES_MAP,
        params: { language: currentRoute.params.language },
        query: options.currentRoute.query
      })
    }
    locationState.setLocation({ point: previewState.attraction?.center || previewState.city?.center || DEFAULT_COORDINATES }, LocationType.GEO)
  } else if (locationState.locationType === LocationType.SEARCH) {
    await previewState.loadAllNearest(locationState.location)
  } else {
    companiesMapState.setOpenCompany(null)
    if ('attraction' in currentRoute.query) {
      await previewState.loadAllByAttraction(currentRoute.query.attraction as string)
      locationState.setLocation({ point: previewState.attraction?.center || DEFAULT_COORDINATES }, LocationType.GEO)
    } else if ('city' in currentRoute.query) {
      await previewState.loadAllByCity(currentRoute.query.city as string)
      locationState.setLocation({ point: previewState.city?.center || DEFAULT_COORDINATES }, LocationType.GEO)
    } else {
      await previewState.loadAllNearest(locationState.location)
    }
  }

  if ('orderId' in currentRoute.query) {
    await userExistingOrder.loadExistingOrder(currentRoute.query.orderId as string)
  }
}
