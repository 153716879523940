
import { computed, defineComponent, PropType } from 'vue'
import { Content } from 'src/model/content.model'
import { QCard, QCardSection, QResponsive } from 'quasar'
import LargeBtn from 'src/components/simple/large-btn.vue'
import useLoadImage from 'src/compositions/loadImage'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import useFormat from 'src/compositions/format'

const name = 'blog-preview'

export default defineComponent({
  name,
  components: {
    QCard,
    QCardSection,
    QResponsive,
    LargeBtn
  },
  props: {
    blog: {
      type: Object as PropType<Content>,
      required: true
    }
  },
  setup(props) {
    const { tp, st } = useI18n$(name)
    const { fdf } = useFormat()
    const { toBlog } = useRoutes()

    const to = computed(() => toBlog(props.blog.path))
    const blogInstance = computed(() => props.blog.blog!)

    return {
      blogInstance,
      to,
      fdf,
      tp,
      st,
      ...useLoadImage()
    }
  }
})
