import useAnonymousApi from 'src/api/anonymous.api'
import { defineStore } from 'pinia'
import { Content } from 'src/model/content.model'
import { Page, Pageable } from 'src/model/common.model'
import { ref } from 'vue'
import { Language } from 'src/model/language.model'

const pageSize = 50
const createPageable = (currentPage: Page<Content>): Pageable => {
  return {
    page: (currentPage.page ?? -1) + 1,
    size: pageSize,
    sortBy: 'createdDate',
    descending: true
  }
}

const useBlogListHome = defineStore('blogListHome', () => {
  const api = useAnonymousApi()

  const page = ref<Page<Content>>({ data: [], totalCount: 0 })
  const loading = ref<boolean>(false)
  const noMore = ref<boolean>(false)

  const loadNext = async (language: Language) => {
    loading.value = true
    return api.getBlogList(language, createPageable(page.value))
      .then(pageResult => {
        page.value = { ...pageResult, data: [...page.value.data, ...pageResult.data], }
        noMore.value = pageResult.totalCount === page.value.data.length
      })
      .finally(() => loading.value = false)
  }

  return {
    page,
    noMore,
    loading,
    loadNext
  }
})

export default useBlogListHome
