import { ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { Attraction } from 'src/model/attraction.model'
import { Content } from 'src/model/content.model'
import { ServiceType, ServiceTypeAlias } from 'src/model/common.model'

const useCityState = defineStore('cityHome', () => {
  const api = useAnonymousApi()

  const content = ref<Content | null>(null)
  const attractions = ref<Attraction[]>([])

  const onCityContentLoaded = (contentResult: Content | null) => {
    content.value = contentResult
  }
  const onAttractionsLoaded = (attractionsResult: Attraction[]) => {
    attractions.value = attractionsResult
  }
  const loadAll = async (key: string, serviceTypeAlias: ServiceTypeAlias) => {
    const [contentResult, attractionsResult] = await Promise.all([
      api.getCityContent(key, serviceTypeAlias, res => onCityContentLoaded(res.data)).catch(() => null),
      api.getAttractions(key, res => onAttractionsLoaded(res.data))
    ])
    onCityContentLoaded(contentResult || null)
    onAttractionsLoaded(attractionsResult)
  }

  return {
    content,
    attractions,
    loadAll
  }
})

export default useCityState
