import { computed, InjectionKey } from 'vue';
import { inject } from 'src/compositions/common';
import { UseI18n, useI18n$ } from 'boot/i18n'
import { Rule, SyncRule } from 'src/types/validation'

const validationRulesKey: InjectionKey<ReturnType<typeof useValidationRules_>> = Symbol('qeepl_validationRules')

export interface ValidationRules {
  required: SyncRule
  email: SyncRule
  phone: SyncRule
  password: SyncRule
  length: (min?: number, max?: number) => SyncRule
  min: (min?: number) => SyncRule
  max: (max?: number) => SyncRule
  inn: SyncRule
  kpp: SyncRule
  bankBic: SyncRule
  accountNumber: SyncRule
  cardNumber: SyncRule
  conditional: (condition: () => boolean, trueRule: Rule, falseRule?: Rule) => Rule
  none: SyncRule
}

async function loadAll(useI18n: UseI18n): Promise<ValidationRules> {
  return import(/* webpackChunkName: "validators"*/ 'src/compositions/validation/validators')
    .then((validators: any) => validators.default(useI18n))
}

function useValidationRules_() {
  const useI18n = useI18n$('validations')

  const rules = computed(() => loadAll(useI18n))

  return {
    required: (value?: any) => rules.value.then(rules => rules.required(value)),
    email: (value?: any) => rules.value.then(rules => rules.email(value)),
    phone: (value?: any) => rules.value.then(rules => rules.phone(value)),
    password: (value?: any) => rules.value.then(rules => rules.password(value)),
    length: (min?: number, max?: number) => (value?: any) => rules.value.then(rules => rules.length(min, max)(value)),
    min: (min?: number) => (value?: any) => rules.value.then(rules => rules.min(min)(value)),
    max: (max?: number) => (value?: any) => rules.value.then(rules => rules.max(max)(value)),
    cardNumber: (value?: any) => rules.value.then(rules => rules.cardNumber(value)),
    accountNumber: (value?: any) => rules.value.then(rules => rules.accountNumber(value)),
    inn: (value?: any) => rules.value.then(rules => rules.inn(value)),
    kpp: (value?: any) => rules.value.then(rules => rules.kpp(value)),
    bankBic: (value?: any) => rules.value.then(rules => rules.bankBic(value)),
    conditional: (condition: () => boolean, trueRule: Rule, falseRule: Rule) => ((value?: any) => condition() ? trueRule(value) : falseRule(value)) as Rule,
    none: (value?: any) => rules.value.then(rules => rules.none(value)),
  }
}

export default function useValidationRules() {
  return inject(validationRulesKey, () => useValidationRules_())
}
