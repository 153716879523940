
import { computed, defineComponent, PropType, defineAsyncComponent } from 'vue'
import PageTemplate from 'src/components/template/page-template.vue'
import HeadingSectionTemplate from 'src/components/template/heading-section-template.vue'
import ResponsivePreviewTemplate from 'src/components/template/responsive-preview-template.vue'
import LocationSelect from 'components/advanced/location-select.vue'
import QeeplRating from 'components/advanced/qeepl-rating.vue'
import AdvantageIcons from 'components/advanced/advantage-icons.vue'
import HowToItem from 'components/simple/how-to-item.vue'
import ReviewCard from 'components/simple/review-card.vue'
import AppLinksBanner from 'components/simple/home-page-template/app-links-banner.vue'
import Faq from 'components/simple/faq.vue'
import CompanyCard from 'components/advanced/company-card.vue'
import HomeValuesCard from 'components/simple/home-values-card.vue'
import PriceBanner from 'components/simple/home-page-template/price-banner.vue'
import { useI18n$ } from 'boot/i18n';
import { Amount, FAQEntry, ServiceType } from 'src/model/common.model'
import StoringCard from 'components/simple/storing-card.vue'
import { Company } from 'src/model/company.model'
import useRoutes from 'src/compositions/routes'
import { QBtn, QNoSsr } from 'quasar'
import useAppData from 'stores/appData'
import { refStore } from 'stores/__common'
import useAppTopBanner from 'src/compositions/appTopBanner'
import useUserOrder from 'src/compositions/user/userOrder'

const name = 'home-page-template'

export default defineComponent({
  name,
  components: {
    QBtn,
    QNoSsr,
    PageTemplate,
    HeadingSectionTemplate,
    ResponsivePreviewTemplate,
    LocationSelect,
    QeeplRating,
    AdvantageIcons,
    HowToItem,
    ReviewCard,
    AppLinksBanner,
    Faq,
    CompanyCard,
    PriceBanner,
    HomeValuesCard,
    StoringCard,
    AppTopBanner: defineAsyncComponent(() => import(/* webpackChunkName: "app-top-banner", webpackMode: "lazy" */ 'src/components/simple/home-page-template/app-top-banner.vue')),
    Articles: defineAsyncComponent(() => import(/* webpackChunkName: "articles", webpackMode: "lazy" */'src/components/simple/articles.vue'))
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    companiesTitle: {
      type: String,
      required: true
    },
    searchPlaceholder: {
      type: String,
      default: ''
    },
    urlQuery: {
      type: String,
      default: ''
    },
    faqEntries: {
      type: Array as PropType<FAQEntry[]>,
      default: []
    },
    companies: {
      type: Array as PropType<Company[]>,
      default: []
    },
    insurance: {
      type: Object as PropType<Amount>,
      required: false
    },
    type: {
      type: String as PropType<ServiceType | 'ALL'>,
      default: 'DEFAULT'
    },
    noLocationSelectOptions: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'leave-luggage'
  ],
  setup(props, { emit }) {
    const { last } = refStore(useAppData())
    const { tp, t, language } = useI18n$(name)
    const { chargeType, setChargeType } = useUserOrder()
    const { toMap, toReviews } = useRoutes()
    const { shown } = useAppTopBanner()

    const to = computed(() => toMap(props.urlQuery))
    const top5Companies = computed(() => props.companies.slice(0, 5))
    const items = computed(() => extractStoringItems('default'))

    const leaveLuggage = () => emit('leave-luggage')

    const extractStoringItems = (name: string) => {
      const items = (tp(`storingCards.${ name }.items`) as string).split('_')
      const translations = (tp(`storingCards.${ name }.translations`) as string).split('_')
      return items.map((item, i) => {
        return {
          item,
          label: translations[i]
        }
      })
    }

    if (props.type === 'SELF') {
      setChargeType('MONTHLY')
    } else {
      setChargeType('DAILY')
    }

    return {
      shown,
      reviews: last,
      top5Companies,
      to,
      language,
      chargeType,
      items,
      tp,
      t,
      leaveLuggage,
      toReviews
    }
  }
})
