import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row q-px-md q-mb-lg justify-center gt-xs"
}
const _hoisted_2 = { class: "col-12 col-md-10 text-center text-grey-6" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "row q-px-md q-pt-lg justify-center"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_page_drawer = _resolveComponent("page-drawer")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_section_template = _resolveComponent("section-template")!
  const _component_container_template = _resolveComponent("container-template")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_chat = _resolveComponent("chat")!
  const _component_app_page_footer = _resolveComponent("app-page-footer")!
  const _component_web_page_footer = _resolveComponent("web-page-footer")!
  const _component_consent_dialog = _resolveComponent("consent-dialog")!
  const _component_join_our_community_dialog = _resolveComponent("join-our-community-dialog")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    class: "page-template",
    view: _ctx.app ? 'lHh lpR lFf' : 'hHh lpR fff'
  }, {
    default: _withCtx(() => [
      (!_ctx.app)
        ? (_openBlock(), _createBlock(_component_page_header, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_q_no_ssr, null, {
        default: _withCtx(() => [
          (_ctx.$q.screen.lt.md)
            ? (_openBlock(), _createBlock(_component_page_drawer, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_q_page_container, { class: "page-template__container" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "page", {}, () => [
            _createVNode(_component_q_page, {
              class: _normalizeClass({'overflow-hidden': _ctx.noOverflow, 'column': _ctx.column, 'row': _ctx.row})
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([{
                  'items-start': _ctx.position === 'start',
                  'items-center': _ctx.position === 'center',
                  'items-end': _ctx.position === 'end',
                  'page-template__preview-dense': _ctx.previewDense
                }, "row full-width justify-center page-template__preview"])
                }, [
                  _createVNode(_component_container_template, { class: "col-12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_section_template, {
                        dense: _ctx.previewDense,
                        "overflow-down": _ctx.overflowDown,
                        "overflow-up": _ctx.overflowUp
                      }, {
                        default: _withCtx(() => [
                          (_ctx.$slots.breadcrumbs)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createElementVNode("div", _hoisted_2, [
                                  _renderSlot(_ctx.$slots, "breadcrumbs")
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", {
                            class: _normalizeClass([{'page-template__preview--title_only': !_ctx.description}, "row q-px-sm justify-center page-template__preview--title"])
                          }, [
                            _createElementVNode("h1", {
                              class: _normalizeClass(["text-center", _ctx.titleClass || 'col-12 col-md-10 col-lg-8']),
                              innerHTML: _ctx.title
                            }, null, 10, _hoisted_3)
                          ], 2),
                          (_ctx.description && !_ctx.noMobileDescription)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["text-center", _ctx.descriptionClass || 'col-12 col-md-10 col-lg-8']),
                                  innerHTML: _ctx.description
                                }, null, 10, _hoisted_5)
                              ]))
                            : _createCommentVNode("", true),
                          _renderSlot(_ctx.$slots, "after-preview")
                        ]),
                        _: 3
                      }, 8, ["dense", "overflow-down", "overflow-up"])
                    ]),
                    after: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "after-section-preview")
                    ]),
                    _: 3
                  })
                ], 2),
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 8, ["class"])
          ])
        ]),
        _: 3
      }),
      _createVNode(_component_q_no_ssr, null, {
        default: _withCtx(() => [
          _createVNode(_component_chat, {
            show: !_ctx.noChat
          }, null, 8, ["show"])
        ]),
        _: 1
      }),
      (_ctx.app)
        ? (_openBlock(), _createBlock(_component_app_page_footer, { key: 1 }))
        : (!_ctx.noFooter)
          ? (_openBlock(), _createBlock(_component_web_page_footer, { key: 2 }))
          : _createCommentVNode("", true),
      (_ctx.requestConsentDialog)
        ? (_openBlock(), _createBlock(_component_consent_dialog, { key: 3 }))
        : _createCommentVNode("", true),
      (_ctx.requestJoinOurCommunityDialog)
        ? (_openBlock(), _createBlock(_component_join_our_community_dialog, { key: 4 }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["view"]))
}