import { InjectionKey } from 'vue'
import { inject } from 'src/compositions/common'
import { useI18n } from 'boot/i18n'
import {
  AM_PM_TIME_PATTERN,
  DATE_PATTERN,
  formatDate,
  formatTime,
  parseDateTime,
  SHORT_DATE_PATTERN,
  TIME_PATTERN,
  UTC
} from 'src/functions/date'
import { Container, DateRange, LargeMeasure, Measure, Measures, TimeRange } from 'src/model/common.model'
import { refStore } from 'stores/__common'
import useUserPreview from 'stores/userPreview'
import { DEFAULT_COUNTRY_KEY, FT_TO_MI_MULTIPLIER } from 'src/model/constants'
import { convertFromFt, convertFromMI, convertToFt, convertToMI } from 'src/functions/utils'
import useUserLocation from 'stores/userLocation'
import useEnvironment from 'src/compositions/environment'

const formatKey: InjectionKey<ReturnType<typeof useFormat_>> = Symbol('qeepl_format')

const DEFAULT_TIME_PATTERN = TIME_PATTERN
const DEFAULT_DATE_PATTERN = SHORT_DATE_PATTERN

const countryToTimePattern: Container<string> = {
  US: AM_PM_TIME_PATTERN,
}

const countryToDatePattern: Container<string> = {
  US: DEFAULT_DATE_PATTERN,
}

const countryToSmallMeasure: Container<Measure> = {
  US: 'FT'
}

const countryToMeasure: Container<LargeMeasure> = {
  US: 'MI'
}

function useFormat_() {
  const { country } = refStore(useUserPreview())
  const { initialLocation } = refStore(useUserLocation())
  const { isoLanguage, t } = useI18n()
  const { ssr } = useEnvironment()

  const getMeasureFullTranslation = (countryKey: string, number: number, measure: Measure | LargeMeasure): string => {
    return t(`measureFull.${ measure }`, { number }, number)
  }
  const getSmallMeasureTranslation = (countryKey: string, measure: Measure | LargeMeasure): string => {
    return t(`measure.${ measure }`)
  }
  const getCountryKey = (): string => {
    if (ssr) {
      return initialLocation.value?.countryKey || country.value?.key || DEFAULT_COUNTRY_KEY
    }
    return country.value?.key || initialLocation.value?.countryKey || DEFAULT_COUNTRY_KEY
  }
  const convertMAndFTIfNeeded = (number: number, destMeasure: Measure, srcMeasure?: Measure): number => {
    if (!srcMeasure || srcMeasure === destMeasure) {
      return number
    }
    if (destMeasure === 'FT') {
      return convertToFt(number)
    } else {
      return convertFromFt(number)
    }
  }
  const convertKMAndMIIfNeeded = (number: number, destMeasure: LargeMeasure, srcMeasure?: LargeMeasure): number => {
    if (!srcMeasure || srcMeasure === destMeasure) {
      return number
    }
    if (destMeasure === 'MI') {
      return convertToMI(number)
    } else {
      return convertFromMI(number)
    }
  }

  const ft = (time: string | number | Date): string => {
    const countryKey = getCountryKey()
    const formattedTime = formatTime(time, countryToTimePattern[countryKey] || DEFAULT_TIME_PATTERN)
    if (formattedTime.startsWith('0')) {
      return formattedTime.substring(1)
    }
    return formattedTime
  }
  const ftr = (timeRange: TimeRange): string => `${ ft(timeRange.from) }—${ ft(timeRange.to) }`
  const fdr = (dateRange: DateRange): string => `${ fd(dateRange.from) }—${ fd(dateRange.to) }`
  const fd = (date: string | number | Date): string => {
    const countryKey = getCountryKey()
    return formatDate(date, countryToDatePattern[countryKey] || DEFAULT_DATE_PATTERN)
  }
  const fdt = (dateTime: string | number | Date, timezone?: string, midnightFix?: boolean): string => {
    const dateTimeValue = parseDateTime(dateTime, timezone || UTC, midnightFix)
    return `${ fdf(dateTimeValue.date) } ${ ft(dateTimeValue.time) }`
  }
  const fdd = (dateTime: string | number | Date, timezone?: string): string => {
    const dateTimeValue = parseDateTime(dateTime, timezone || UTC)
    return fdf(dateTimeValue.date)
  }
  const fdf = (date: string | number | Date): string => {
    return formatDate(date, DATE_PATTERN)
  }
  const flc = (number: number, config?: { srcMeasure?: Measure | LargeMeasure, full?: boolean }): string => {
    let srcMeasure = config?.srcMeasure || 'M'
    if (srcMeasure === 'M' && number > 1000) {
      number = number / 1000
      srcMeasure = 'KM'
    } else if (srcMeasure === 'FT' && number > FT_TO_MI_MULTIPLIER) {
      number = number / FT_TO_MI_MULTIPLIER
      srcMeasure = 'MI'
    }
    const countryKey = getCountryKey()
    const isSmallMeasure = Measures.includes(srcMeasure)
    let result: number
    let destMeasure: Measure | LargeMeasure
    if (isSmallMeasure) {
      destMeasure = countryToSmallMeasure[countryKey] || 'M'
      result = convertMAndFTIfNeeded(number, destMeasure, srcMeasure as Measure)
      result = Number(result.toFixed(1))
    } else {
      destMeasure = countryToMeasure[countryKey] || 'KM'
      result = convertKMAndMIIfNeeded(number, destMeasure, srcMeasure as LargeMeasure)
      result = Number(result.toFixed(2))
    }
    return config?.full ? getMeasureFullTranslation(countryKey, result, destMeasure) : `${ result }${ getSmallMeasureTranslation(countryKey, destMeasure) }`
  }
  const fl = (meters: number): string => {
    return flc(meters, { srcMeasure: 'M', full: true })
  }
  const flm = (meters: number): string => {
    return flc(meters, { srcMeasure: 'M' })
  }
  const fn = (number: number): string => number.toLocaleString(isoLanguage.value)
  const getMeasure = (countryKey: string): Measure => {
    return countryToSmallMeasure[countryKey] ?? 'M'
  }
  const getCurrentMeasure = (): Measure => {
    return getMeasure(getCountryKey())
  }
  const isMetric = () => getCurrentMeasure() === 'M'
  const isImperial = () => getCurrentMeasure() === 'FT'

  return {
    ft,
    ftr,
    fdr,
    fd,
    fdf,
    fdt,
    fdd,
    flc,
    fl,
    flm,
    fn,
    getMeasure,
    getCurrentMeasure,
    isMetric,
    isImperial,
  }
}

export default function useFormat() {
  return inject(formatKey, () => useFormat_())
}
