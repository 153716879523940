import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "column fit q-px-md" }
const _hoisted_2 = { class: "col-auto row q-pt-sm" }
const _hoisted_3 = { class: "col-12 text-h2 text-ellipsis" }
const _hoisted_4 = { class: "col q-pb-lg" }
const _hoisted_5 = { class: "row fit items-center text-center payment-success-page__card-content" }
const _hoisted_6 = {
  key: 0,
  class: "col-12"
}
const _hoisted_7 = {
  key: 1,
  class: "col-12 q-pb-xl"
}
const _hoisted_8 = { class: "text-h3 q-pt-sm" }
const _hoisted_9 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_large_btn = _resolveComponent("large-btn")!
  const _component_dialog_template = _resolveComponent("dialog-template")!

  return (_openBlock(), _createBlock(_component_dialog_template, {
    closable: false,
    i18n: _ctx.$options.name,
    "model-value": _ctx.dialogModel,
    class: "payment__success--dialog",
    "content-class": "payment__success",
    height: "674px",
    "no-paddings": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.tp('title')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.checking)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_q_spinner, {
                    thickness: 3,
                    color: "primary",
                    size: "9em"
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_q_icon, {
                    alt: `payment ${ _ctx.result }`,
                    name: `img:/images/other/payment-${ _ctx.result }.svg`,
                    size: "7.5rem"
                  }, null, 8, ["alt", "name"]),
                  _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.tp(_ctx.result)), 1)
                ]))
          ])
        ])
      ])
    ]),
    actions: _withCtx(() => [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_large_btn, {
          disable: _ctx.checking,
          loading: _ctx.checking,
          runnable: _ctx.toMyOrdersOpenPage,
          label: _ctx.tp('open'),
          color: "accent",
          fill: ""
        }, null, 8, ["disable", "loading", "runnable", "label"])
      ])
    ]),
    _: 1
  }, 8, ["i18n", "model-value"]))
}