
import { computed, defineComponent, onUnmounted, PropType, ref, toRef } from 'vue'
import { QCarousel, QCarouselSlide, QIcon, QResponsive, QSkeleton } from 'quasar'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import IconLabel from 'components/simple/icon-label.vue'
import VisibilityTemplate from 'components/template/visibility-template.vue'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'
import useFormat from 'src/compositions/format'
import useCompanyCache from 'src/compositions/cache/companyCache'
import useCompanyVisibility from 'src/compositions/company/companyVisibility'
import { COMPANY_LUGGAGE_FALLBACK_IMG, COMPANY_SELF_FALLBACK_IMG } from 'src/model/constants'
import { computeDirectDestination } from 'src/functions/map'
import useCompanyRating from 'src/compositions/company/companyRating'
import useUserLocation from 'stores/userLocation'
import { refStore } from 'stores/__common'
import useUserPreviewAmounts from 'src/compositions/map/userPreviewAmounts'
import { doWhen, isDefault, isSelf } from 'src/functions/utils'
import { ADDITION_TO_ICONS } from 'src/config/additions'
import { computePublicName } from 'src/functions/company'
import { ChargeType } from 'src/model/common.model'

export const name = 'company-card'

export default defineComponent({
  name,
  components: {
    QCarousel,
    QCarouselSlide,
    QSkeleton,
    QIcon,
    QResponsive,
    IconLabel,
    VisibilityTemplate
  },
  props: {
    company: {
      type: Object as PropType<AnonymousCompanyProjection>,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    noRouting: {
      type: Boolean,
      default: false
    },
    noPointerEvents: {
      type: Boolean,
      default: false
    },
    nonActive: {
      type: Boolean,
      default: false
    },
    noImage: {
      type: Boolean,
      default: false
    },
    noPriceLabel: {
      type: Boolean,
      default: false
    },
    eager: {
      type: Boolean,
      default: false
    },
    useH1: {
      type: Boolean,
      default: false
    },
    chargeType: {
      type: String as PropType<ChargeType>,
      default: 'DAILY'
    },
    titleOnly: {
      type: Boolean,
      default: false
    },
    showAllImages: {
      type: Boolean,
      default: false
    },
    imageDescription: {
      type: String,
      default: ''
    },
    largeHeight: {
      type: Boolean,
      default: false
    },
    showDestination: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    'choose',
    'visible',
    'hidden'
  ],
  setup(props, { emit }) {
    const { st, tp, tc, t } = useI18n$(name)
    const { toMapCompany } = useRoutes()
    const { flm } = useFormat()
    const { location } = refStore(useUserLocation())
    const { occupied, latestOrderHours, lastTwoWeeksOrderCount } = useCompanyCache()
    const { markVisible, markNonVisible } = useCompanyVisibility()
    const { labels, monthlyPrice, firstMonthPriceLabel } = useUserPreviewAmounts(toRef(props, 'company'))

    const to = toMapCompany(props.company)

    onUnmounted(() => hidden())

    const carouselModel = ref(0)

    const fallbackPhoto = computed(() => isDefault(props.company) ? COMPANY_LUGGAGE_FALLBACK_IMG : COMPANY_SELF_FALLBACK_IMG)
    const photoSrc = computed(() => {
      return props.company.photoURLs[0] ? `${ props.company.photoURLs[0] }${ props.dense ? '?tr=w-100,h-100' : '?tr=w-400,h-200' }` : fallbackPhoto.value
    })
    const photos = computed(() => props.company.photoURLs.length ? props.company.photoURLs : [fallbackPhoto.value])
    const containerClass = computed(() => {
      return `full-width bg-white company-card__intersection${ props.dense ? ' company-card__intersection--dense' : '' } ${ !props.flat ? (props.nonActive ? ' shadow-5' : ' shadow-10') : '' }`
    })
    const occupiedCompletely = computed(() => {
      const occupiedCount = occupied[props.company.id!] ?? 0
      return doWhen(
        props.company,
        default_ => occupiedCount >= default_.capacity,
        self => occupiedCount >= self.placesCount,
      )
    })
    const capacityLabel = computed(() => {
      const capacity = doWhen(props.company, luggage => luggage.capacity, () => 1)
      const occupiedValue = Math.floor(occupied[props.company.id!])
      if (occupiedValue >= capacity) {
        return tp('capacity.noPlace')
      } else if (occupiedValue >= 0) {
        const count = capacity - occupiedValue
        return tc('capacity.ok', { placesCount: count, total: props.company.default?.capacity }, count)
      } else if (occupiedValue === -1) {
        return t('capacity.error')
      } else {
        return null
      }
    })
    const destinationLabel = computed(() => {
      if (location.value) {
        return tp('destination.fromYou', { destination: flm(computeDirectDestination(props.company.coordinates, location.value.point)) })
      } else {
        return tp('destination.unknown')
      }
    })
    const latestOrderOrCountLabel = computed(() => {
      const latestOrderHoursValue = latestOrderHours[props.company.id!]
      if (latestOrderHoursValue === 0) {
        return tp('lastOrderTime.recently')
      }
      if (latestOrderHoursValue > 0 && latestOrderHoursValue <= 24) {
        return tc('lastOrderTime.hoursAgo', { hours: latestOrderHoursValue }, latestOrderHoursValue)
      }
      const lastTwoWeeksOrderCountValue = lastTwoWeeksOrderCount[props.company.id!]
      if (lastTwoWeeksOrderCountValue > 0) {
        return tc('lastOrderTime.weeksAgo', { orders: lastTwoWeeksOrderCountValue }, lastTwoWeeksOrderCountValue)
      } else if (lastTwoWeeksOrderCountValue === 0) {
        return tp('lastOrderTime.popular')
      } else {
        return null
      }
    })
    const description = computed(() => {
      return doWhen(
        props.company,
        () => latestOrderOrCountLabel.value,
        () => destinationLabel.value
      )
    })
    const defaultPriceLabel = computed(() => {
      const labelsValue = labels.value
      switch (props.chargeType) {
        case 'HOURLY':
          return t('global.startingFrom', { price: labelsValue.S.hourPrice })
        case 'DAILY':
          return t('global.startingFrom', { price: labelsValue.S.dayPrice })
        case 'MONTHLY':
          return t('global.startingFrom', { price: labelsValue.S.monthPrice })
      }
    })
    const selfPriceLabel = computed(() => {
      if (firstMonthPriceLabel.value) {
        return `<span class="text-primary">${ firstMonthPriceLabel.value }</span> <span class="text-crossed">${ monthlyPrice.value }</span>`
      }
      return `<span>${ monthlyPrice.value }</span> ${ t('global.monthlyPrice.base') }`
    })
    const publicName = computed(() => {
      return computePublicName(props.company, st(props.company.publicName) ?? '')
    })

    const visible = () => {
      markVisible(props.company)
      emit('visible')
    }
    const hidden = () => {
      markNonVisible(props.company)
      emit('hidden')
    }
    const choose = () => emit('choose', props.company)

    return {
      isDefault,
      isSelf,
      additionToIcons: ADDITION_TO_ICONS,
      photoSrc,
      containerClass,
      to,
      capacityLabel,
      description,
      defaultPriceLabel,
      selfPriceLabel,
      occupiedCompletely,
      photos,
      carouselModel,
      destinationLabel,
      publicName,
      visible,
      hidden,
      choose,
      tp,
      st,
      ...useCompanyRating(props.company),
    }
  }
})
