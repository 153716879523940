import { ref } from 'vue'

export default function useLazyDialog() {
  const dialogModel = ref<boolean>(false)
  const requestDialogLoading = ref<boolean>(false)

  const openDialog = () => {
    requestDialogLoading.value = true
    dialogModel.value = true
  }

  const closeDialog = () => {
    dialogModel.value = false
  }

  return {
    dialogModel,
    requestDialogLoading,
    openDialog,
    closeDialog
  }
}
