
import { computed, defineComponent, ref } from 'vue'
import PageTemplate from 'components/template/page-template.vue'
import LargeBtn from 'components/simple/large-btn.vue'
import { useI18n$ } from 'boot/i18n'
import { QBtn, QCard, QCardActions, QCardSection, QInput } from 'quasar'
import useValidation from 'src/compositions/validation/validation'
import useRoutes from 'src/compositions/routes'
import { useRouter } from 'vue-router'
import useUserDetails from 'stores/userDetails'
import { refStore } from 'stores/__common'
import useAsync from 'src/compositions/async'
import useAnonymousApi from 'src/api/anonymous.api'
import useNotify from 'src/compositions/notify'
import useValidationRules from 'src/compositions/validation/validationRules'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'
import usePageExtensions from 'src/compositions/pageExtensions'

const name = 'recovery'

export default defineComponent({
  name,
  components: {
    QBtn,
    QCard,
    QCardSection,
    QCardActions,
    QInput,
    PageTemplate,
    LargeBtn,
    Breadcrumbs
  },
  setup() {
    const {
      positive,
      negative
    } = useNotify()
    const {
      refreshPasswordInit,
      refreshPasswordFinish
    } = useAnonymousApi()
    const { toLogin } = useRoutes()
    const {
      push,
      currentRoute
    } = useRouter()
    const { user, blocked } = refStore(useUserDetails())
    const {
      tp,
      t,
      language
    } = useI18n$(name)
    const {
      required,
      email
    } = useValidationRules()

    const key = ref(currentRoute.value.query.key as string || '')
    const userEmail = ref('')
    const userPassword = ref('')

    const keyCheck = computed(() => !!key.value)

    const {
      run: sendCode,
      running: sendingCode,
      success: sendSuccess
    } = useAsync(
      () => refreshPasswordInit(userEmail.value!, ''),
      () => positive(tp('success.sent.title'), tp('success.sent.description')),
      () => {
        negative(tp('error.sent.title'), tp('error.sent.description'))
      }
    )

    const {
      run: checkCode,
      running: checkingCode,
      success: checkSuccess
    } = useAsync(
      () => refreshPasswordFinish(key.value!, userPassword.value!, ''),
      () => {
        positive(tp('success.check.title'), tp('success.check.description'))
        setTimeout(() => push(toLogin({ partner: true })), 3000)
      },
      () => {
        negative(tp('error.check.title'), tp('error.check.description'))
      },
    )

    const {
      valid: emailFieldValid,
      validations: emailValidations
    } = useValidation({
      userEmail: {
        rules: [required, email],
        ref: userEmail
      },
    })

    const {
      valid: passwordFieldValid,
      validations: passwordValidations
    } = useValidation({
      userPassword: {
        rules: [required],
        ref: userPassword
      },
    })

    return {
      user,
      userEmail,
      userPassword,
      keyCheck,
      sendSuccess,
      checkSuccess,
      blocked,
      processing: computed(() => sendingCode.value || checkingCode.value),
      emailFieldValid,
      passwordFieldValid,
      emailValidations,
      passwordValidations,
      tp,
      t,
      sendCode,
      checkCode, ...usePageExtensions({
        page: name,
        breadcrumbs: () => ([{
          path: 'recovery',
          label: t('global.pages.recovery')
        }])
      })
    }
  }
})
