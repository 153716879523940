
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  provide,
  Ref,
  ref,
  watch
} from 'vue'
import PageTemplate from 'components/template/page-template.vue'
import LocationSelect from 'components/advanced/location-select.vue'
import { QBtn, QNoSsr, QPage, useQuasar } from 'quasar'
import companiesMapPrefetch from 'src/compositions/prefetch/companiesMapPrefetch'
import { refStore } from 'stores/__common'
import useCompaniesMapStore from 'stores/companiesMap'
import useUserPreview from 'stores/userPreview'
import useUserLocation, { LocationType } from 'stores/userLocation'
import useUserOrder from 'src/compositions/user/userOrder'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import { buildDateTimeRange } from 'src/functions/date'
import { DailyStatus, getDailyStatus, supportsChargeType } from 'src/functions/company'
import { useRouter } from 'vue-router'
import { useI18n$ } from 'boot/i18n'
import useNotify from 'src/compositions/notify'
import { COMPANIES_MAP } from 'pages/names'
import useRoutes from 'src/compositions/routes'
import useEnvironment from 'src/compositions/environment'
import { computeDirectDestination, sortByDirectClosest } from 'src/functions/map'
import useCompanyVisibility from 'src/compositions/company/companyVisibility'
import { ChargeType, ChargeTypes, Location, PaymentProvider } from 'src/model/common.model'
import {
  DEFAULT_COORDINATES,
  DEFAULT_CURRENCY,
  DEFAULT_DAY_PRICE,
  DEFAULT_HOUR_PRICE,
  DEFAULT_MAX_DESTINATION_TO_SHOW_RELOAD_BUTTON_IN_KM,
  DEFAULT_MONTH_PRICE
} from 'src/model/constants'
import useAnalytics from 'src/compositions/analytics'
import useUserExistingOrder from 'src/compositions/map/userExistingOrder'
import useUserPreviewExtension from 'src/compositions/userPreviewExtension'
import TariffButtons from 'components/advanced/tariff-buttons.vue'
import { matRefresh } from 'src/config/icons'
import { doWhen, isDefault, isSelf, isSelfServiceType } from 'src/functions/utils'
import { getActualItemsMetadata, ORDER_ITEM_SIZES } from 'src/functions/order'
import { OrderItemType } from 'src/model/order.model'

const name = COMPANIES_MAP

const matMyLocation = 'M0 0h24v24H0z@@fill:none;&&M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z'

const AppleWebMap = defineAsyncComponent(() => import(/* webpackChunkName: "apple-web-map" */'components/advanced/apple-web-map/apple-web-map.vue'))

export default defineComponent({
  name,
  components: {
    QPage,
    QNoSsr,
    QBtn,
    PageTemplate,
    LocationSelect,
    TariffButtons,
    AppleWebMap
  },
  async preFetch(options) {
    await companiesMapPrefetch(options)
  },
  setup() {
    const {
      openCompany,
      setOpenCompany
    } = refStore(useCompaniesMapStore())
    const {
      companies,
      country,
      city,
      attraction,
      loadAllNearest
    } = refStore(useUserPreview())
    const {
      location: userLocation,
      locationType,
      resolveGpsLocationAndSet,
      resolveLocationText,
      setLocation
    } = refStore(useUserLocation())
    const {
      tp,
      t,
      language
    } = useI18n$(COMPANIES_MAP)
    const {
      push,
      replace,
      currentRoute
    } = useRouter()
    const {
      dateRange,
      timeRange,
      filterEnabled,
      chargeType,
      hourly,
      itemsMetadata,
      refreshDateTimeRange,
      setHourly,
      setCompany,
      setChargeType
    } = useUserOrder()
    const {
      loadExistingOrder,
      forgetExistingOrder
    } = useUserExistingOrder()
    const {
      occupied,
      reloadOccupiedCache,
      markVisible,
      markNonVisible
    } = useCompanyVisibility()
    const { isWorldContext } = useUserPreviewExtension()
    const {
      app,
      ssr,
      csr
    } = useEnvironment()
    const { negative } = useNotify()
    const { toMapCompany, toMap } = useRoutes()
    const { screen, dialog } = useQuasar()
    const { logEvent } = useAnalytics()

    const webMapRef = ref(null) as unknown as Ref<typeof AppleWebMap>
    const locationSelectRef = ref(null) as unknown as Ref<typeof LocationSelect>
    const chosenCompany = ref<AnonymousCompanyProjection | null>(openCompany.value)
    const bottomOccupiedSpace = ref(0)
    const showReloadButton = ref(false)

    onMounted(() => {
      const currentRouteValue = currentRoute.value
      if (!('companyId' in currentRouteValue.params || 'city' in currentRouteValue.query || 'attraction' in currentRouteValue.query)) {
        if (!app && locationType.value !== LocationType.SEARCH) {
          doResolveGPSLocation(true)
        }
      }
      if ('chargeType' in currentRouteValue.query && ChargeTypes.includes(currentRouteValue.query.chargeType as ChargeType)) {
        setChargeType(currentRouteValue.query.chargeType as ChargeType)
      }
      if (chosenCompany.value?.supportedChargeTypes?.includes('MONTHLY') && chosenCompany.value?.supportedChargeTypes?.length === 1) {
        setChargeType('MONTHLY')
      }
      loadExistingOrder()
    })

    onBeforeUnmount(() => {
      forgetExistingOrder()
    })

    const mapCenter = computed(() => webMapRef.value?.mapCenter ?? DEFAULT_COORDINATES)
    const center = computed(() => userLocation.value.point)
    const splitCompanies = computed(() => doSplitCompanies(companies.value))
    const filteredCompanies = computed(() => splitCompanies.value.filtered)
    const nonFilteredCompanies = computed(() => splitCompanies.value.nonFiltered)
    const hasSelfCompanies = computed(() => !!companies.value.find(isSelf))
    const chosenPaymentMethod = ref<PaymentProvider>(isWorldContext.value ? 'STRIPE' : 'YOOKASSA')
    const showingListCompanies = computed(() => {
      const chosenCompanyValue = chosenCompany.value
      if (chosenCompanyValue && !filteredCompanies.value.find(c => c.id === chosenCompanyValue.id)) {
        return [...filteredCompanies.value, chosenCompanyValue]
      }
      return filteredCompanies.value
    })
    const buttonsContainerBottom = computed(() => {
      return { bottom: `${ screen.gt.xs ? 0 : app ? 'calc(258px + env(safe-area-inset-bottom))' : '208px' }` }
    })
    const paddings = computed(() => {
      if (screen.xs) {
        return ({
          left: 86,
          right: 86,
          top: 178,
          bottom: 218
        })
      }
      return ({
        left: 128,
        right: 128,
        top: 196,
        bottom: 132
      })
    })

    const hasUserJustGrantedGeo = async (): Promise<boolean> => {
      try {
        const status = await navigator.permissions.query({ name: 'geolocation' })
        return status?.state === 'prompt'
      } catch (e) {
        return false
      }
    }
    const updateLocation = () => {
      const mapCenter = webMapRef.value?.mapCenter
      if (mapCenter) {
        const location = { point: mapCenter }
        setLocation(location, LocationType.SEARCH, true)
        resolveLocationText(location, language.value)
        loadAllNearest(userLocation.value)
      }
      showReloadButton.value = false
      return Promise.resolve()
    }
    const doSplitCompanies = (newCompanies: AnonymousCompanyProjection[]) => {
      const chargeTypeValue = chargeType.value
      const holidays = country.value?.holidays
      const thereAreChosenLargeItems = Object
        .keys(getActualItemsMetadata(itemsMetadata.value))
        .some(k => ORDER_ITEM_SIZES[k as OrderItemType] === 'L')

      newCompanies = sortByDirectClosest(newCompanies, userLocation.value?.point || null)
        .filter((c: AnonymousCompanyProjection) => {
          if (isSelfServiceType(c.type)) {
            const occupiedValue = occupied[c.id!] ?? 0
            return isSelf(c) && occupiedValue < c.self!.placesCount
          } else {
            return isDefault(c)
          }
        })
        .filter((c: AnonymousCompanyProjection) => supportsChargeType(c, chargeTypeValue))
        .filter((c: AnonymousCompanyProjection) => thereAreChosenLargeItems ? c.default?.acceptLarge : true)
      let splitResult: { company: AnonymousCompanyProjection, filterPassed: boolean }[]
      if (filterEnabled.value) {
        const {
          from,
          to
        } = buildDateTimeRange(dateRange.value, timeRange.value, false)
        splitResult = newCompanies.map((c: AnonymousCompanyProjection) => {
          const dailyStatusFrom = getDailyStatus(c, from, holidays)
          const dailyStatusTo = getDailyStatus(c, to, holidays)
          return {
            company: c,
            filterPassed: dailyStatusFrom === DailyStatus.OPEN && dailyStatusTo === DailyStatus.OPEN
          }
        })
      } else {
        splitResult = newCompanies.map((c: AnonymousCompanyProjection) => {
          return {
            company: c,
            filterPassed: true
          }
        })
      }
      return {
        filtered: splitResult.filter(c => c.filterPassed).map(r => r.company),
        nonFiltered: splitResult.filter(c => !c.filterPassed).map(r => r.company),
      }
    }
    const onNewFilteredCompanies = (newFilteredCompanies: AnonymousCompanyProjection[]) => {
      const openCompanyValue = openCompany.value
      const companyId = openCompanyValue?.id
      if (companyId) {
        if (!companies.value.find(c => c.id === companyId)) {
          chosenCompany.value = newFilteredCompanies[0] || null
          setOpenCompany(null)
        }
      } else {
        chosenCompany.value = newFilteredCompanies[0] || null
      }
    }
    const doResolveGPSLocation = (silent: boolean = false) => {
      return resolveGpsLocationAndSet(language.value)
        .then((res: boolean) => {
          showReloadButton.value = false
          if (!res) {
            !silent && negative(tp('locationUnknown'))
          } else {
            return loadAllNearest(userLocation.value)
          }
        })
    }
    const doSetOpenCompany = (company: AnonymousCompanyProjection | null) => {
      setOpenCompany(company)
      return company ? push(toMapCompany(company)) : push(toMap())
    }
    const doChooseOpenCompany = (newChosenCompany: AnonymousCompanyProjection) => {
      const price = doWhen(
        newChosenCompany,
        d => {
          switch (chargeType.value) {
            case 'HOURLY':
              return d.hourPrice ?? city.value?.hourPrice ?? country.value?.hourPrice ?? DEFAULT_HOUR_PRICE
            case 'DAILY':
              return d.dayPrice ?? city.value?.dayPrice ?? country.value?.dayPrice ?? DEFAULT_DAY_PRICE
            case 'MONTHLY':
              return d.monthPrice ?? city.value?.monthPrice ?? country.value?.monthPrice ?? DEFAULT_MONTH_PRICE
          }
        },
        self => self.monthlyPrice ?? city.value?.monthPrice ?? country.value?.monthPrice ?? DEFAULT_MONTH_PRICE
      )
      logEvent({
        name: 'view_item',
        params: {
          currency: country.value?.currency ?? DEFAULT_CURRENCY,
          value: price,
          items: [{
            item_id: newChosenCompany.id,
            item_name: newChosenCompany.publicName?.en,
            item_category: isDefault(newChosenCompany) ? 'Luggage' : 'Self',
            quantity: 1,
          }]
        }
      })
      chosenCompany.value = newChosenCompany
      setOpenCompany(null)
    }
    const setOrOpenCompany = (company: AnonymousCompanyProjection) => {
      if (company.id === chosenCompany.value?.id || (!!openCompany.value && openCompany.value.id !== company.id)) {
        if (nonFilteredCompanies.value.find(c => c.id === company.id)) {
          return new Promise<void>(resolve => {
            dialog({
              title: tp('warning.title'),
              message: tp('warning.description'),
              persistent: true,
              color: 'warning',
              ok: {
                label: t('action.continue'),
                color: 'accent',
                flat: true,
                noCaps: true
              },
              cancel: {
                label: t('action.cancel'),
                color: 'grey-7',
                flat: true,
                noCaps: true
              },
              focus: 'ok',
            })
              .onOk(() => doSetOpenCompany(company).then(() => resolve()))
              .onCancel(() => resolve())
          })
        } else if (!!openCompany.value) {
          doChooseOpenCompany(company)
        }
        return doSetOpenCompany(company)
      } else {
        return doChooseOpenCompany(company)
      }
    }

    watch(companies, () => {
      if (attraction.value?.center || city.value?.center) {
        setLocation({ point: attraction.value?.center ?? city.value?.center } as Location, LocationType.GEO)
      }
    })
    watch(filteredCompanies, (newFilteredCompanies, oldFilteredCompanies) => {
      onNewFilteredCompanies(newFilteredCompanies)
    })
    watch(dateRange, () => reloadOccupiedCache())
    watch(openCompany, newOpenCompany => {
      if (newOpenCompany) {
        setCompany(newOpenCompany)
      } else {
        replace(toMap())
      }
    })
    watch(mapCenter, newMapCenter => {
      showReloadButton.value =
        computeDirectDestination(userLocation.value.point, newMapCenter) > DEFAULT_MAX_DESTINATION_TO_SHOW_RELOAD_BUTTON_IN_KM * 1000
    })
    watch(city, (newCity, oldCity) => {
      if (newCity?.key !== oldCity?.key) {
        setOpenCompany(null)
        refreshDateTimeRange()
      }
    })

    if (openCompany.value) {
      setCompany(openCompany.value)
    }
    onNewFilteredCompanies(filteredCompanies.value)

    provide('bottomOccupiedSpace', bottomOccupiedSpace)
    provide('companies', companies)
    provide('country', country)
    provide('city', city)
    provide('filteredCompanies', filteredCompanies)
    provide('showingListCompanies', showingListCompanies)
    provide('center', center)
    provide('openCompany', openCompany)
    provide('chosenCompany', chosenCompany)
    provide('hourly', hourly)
    provide('chargeType', chargeType)
    provide('userLocation', userLocation)
    provide('chosenPaymentMethod', chosenPaymentMethod)
    provide('setChosenCompany', doChooseOpenCompany)
    provide('setOpenCompany', doSetOpenCompany)
    provide('setOrOpenCompany', setOrOpenCompany)
    provide('isWorldContext', isWorldContext)
    provide('markVisible', markVisible)
    provide('markNonVisible', markNonVisible)
    provide('setHourly', setHourly)

    return {
      isSelfServiceType,
      matRefresh,
      matMyLocation,
      csr,
      ssr,
      webMapRef,
      locationSelectRef,
      buttonsContainerBottom,
      companies,
      filteredCompanies,
      nonFilteredCompanies,
      chosenCompany,
      openCompany,
      location: userLocation,
      center,
      showReloadButton,
      paddings,
      hourly,
      hasSelfCompanies,
      updateLocation,
      setOrOpenCompany,
      tp,
      resolveGpsLocation: doResolveGPSLocation
    }
  }
})
