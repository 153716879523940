import { Boundaries, Coordinates } from 'src/model/common.model'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import { DEFAULT_RADIUS_IN_KM } from 'src/model/constants'

const R = 6371e3

export function computeDirectDestination(c1: Coordinates, c2: Coordinates): number {
  const fi1 = c1.latitude * Math.PI / 180
  const fi2 = c2.latitude * Math.PI / 180
  const dFi = (c2.latitude - c1.latitude) * Math.PI / 180
  const dLambda = (c2.longitude - c1.longitude) * Math.PI / 180

  const a = Math.sin(dFi / 2) * Math.sin(dFi / 2) + Math.cos(fi1) * Math.cos(fi2) * Math.sin(dLambda / 2) * Math.sin(dLambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

export function sortByDirectClosest(companies: AnonymousCompanyProjection[], point: Coordinates | null): AnonymousCompanyProjection[] {
  if (!point) {
    return companies
  }
  companies.sort((c1, c2) => {
    const d1 = computeDirectDestination(point, c1.coordinates)
    const d2 = computeDirectDestination(point, c2.coordinates)
    return d1 === d2 ? 0 : (d1 > d2 ? 1 : -1)
  })
  return [...companies]
}

export function createBoundariesWithCenter(coordinates: Coordinates, radius: number = DEFAULT_RADIUS_IN_KM * 1000): Boundaries {
  const dY = 360 * radius / R
  const dX = dY * Math.cos(coordinates.longitude * Math.PI / 180)

  return {
    leftLower: {
      longitude: coordinates.longitude - dY,
      latitude: coordinates.latitude - dX
    },
    rightUpper: {
      longitude: coordinates.longitude + dY,
      latitude: coordinates.latitude + dX,
    }
  }
}

export function centerBetween(point1: Coordinates, point2: Coordinates): Coordinates {
  return {
    latitude: (point1.latitude + point2.latitude) / 2,
    longitude: (point1.longitude + point2.longitude) / 2,
  }
}
