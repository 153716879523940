
import { computed, defineComponent, PropType } from 'vue'
import { Amount } from 'src/model/common.model'
import { DEFAULT_CURRENCY, DEFAULT_INSURANCE } from 'src/model/constants'
import { formatAmount } from 'src/functions/formatting'
import { useI18n$ } from 'boot/i18n'
import useUserPreview from 'stores/userPreview'
import { refStore } from 'stores/__common'
import useAppData from 'stores/appData'
import useFormat from 'src/compositions/format'
import { createAmount } from 'src/functions/utils'

const name = 'advantage-icons'

export default defineComponent({
  name,
  components: {
  },
  props: {
    linear: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Boolean,
      default: true
    },
    insurance: {
      type: Object as PropType<Amount>,
      required: false
    },
  },
  setup(props) {
    const { tp } = useI18n$(name)
    const { fn } = useFormat()
    const { country } = refStore(useUserPreview())
    const { totalBags } = refStore(useAppData())

    const columnClass = computed(() => props.linear ? 'col-12 q-pb-md' : 'col-6 col-sm-auto q-pr-xs q-pb-sm')
    const insuranceFormatted = computed(() => {
      return formatAmount(props.insurance || createAmount(country.value?.insurance ?? DEFAULT_INSURANCE, country.value?.currency ?? DEFAULT_CURRENCY), 0)
    })

    return {
      columnClass,
      insuranceFormatted,
      totalBags,
      tp,
      fn
    }
  }
})
