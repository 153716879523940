import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "become-partner-card row items-center justify-center" }
const _hoisted_2 = { class: "row items-center justify-between reverse q-col-gutter-md" }
const _hoisted_3 = { class: "col-12 col-sm-5" }
const _hoisted_4 = { class: "col-12 col-sm" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "row items-center justify-start" }
const _hoisted_8 = { class: "col-12 col-sm-auto q-pr-sm" }
const _hoisted_9 = { class: "row q-px-md items-center justify-center text-primary" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_large_btn = _resolveComponent("large-btn")!
  const _component_heading_section_template = _resolveComponent("heading-section-template")!

  return (_openBlock(), _createBlock(_component_heading_section_template, {
    title: _ctx.tp('titleMain'),
    "margin-bottom": "",
    i18n: _ctx.$options.name
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["col-12 col-lg-11 col-xl-9", {'q-pa-xl': _ctx.$q.screen.gt.md, 'q-py-md': _ctx.$q.screen.lt.lg}])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_img, {
                loading: "lazy",
                src: `${ _ctx.IMGKIT_URL }/System/home-all-page/shelves-main.png`,
                alt: _ctx.alt,
                ratio: 1,
                class: "rounded-borders-large"
              }, null, 8, ["src", "alt"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", {
                class: "text-h2 q-pt-md q-pb-lg",
                innerHTML: _ctx.title
              }, null, 8, _hoisted_5),
              _createElementVNode("div", {
                class: "q-mb-xl text-large",
                innerHTML: _ctx.description
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_large_btn, {
                    fill: _ctx.$q.screen.xs,
                    to: _ctx.toPartners(true),
                    label: _ctx.t('partners.joinUs'),
                    color: "accent"
                  }, null, 8, ["fill", "to", "label"])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-12 col-sm-auto", {'q-pt-lg': _ctx.$q.screen.xs}])
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-auto" }, [
                      _createElementVNode("svg", {
                        width: "20",
                        height: "16",
                        viewBox: "0 0 20 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M2 8L7.48571 14L18 2",
                          stroke: "url(#paint0_linear_3_1901)",
                          "stroke-width": "3",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }),
                        _createElementVNode("defs", null, [
                          _createElementVNode("linearGradient", {
                            id: "paint0_linear_3_1901",
                            x1: "10",
                            y1: "14",
                            x2: "10",
                            y2: "2",
                            gradientUnits: "userSpaceOnUse"
                          }, [
                            _createElementVNode("stop", { "stop-color": "#6B36E6" }),
                            _createElementVNode("stop", {
                              offset: "1",
                              "stop-color": "#70AEFF"
                            })
                          ])
                        ])
                      ])
                    ], -1)),
                    _createElementVNode("div", {
                      class: "col-auto text-center q-pl-xs",
                      innerHTML: _ctx.t('partners.becomeHost')
                    }, null, 8, _hoisted_10)
                  ])
                ], 2)
              ])
            ])
          ])
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["title", "i18n"]))
}