import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_intersection = _resolveComponent("q-intersection")!

  return (_ctx.eager)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.containerClass)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2))
    : (_openBlock(), _createBlock(_component_q_intersection, {
        key: 1,
        class: _normalizeClass(_ctx.containerClass),
        once: _ctx.once,
        root: _ctx.root,
        transition: "fade",
        "ssr-prerender": "",
        onVisibility: _ctx.visibilityChanged
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class", "once", "root", "onVisibility"]))
}