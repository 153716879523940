import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-py-lg" }
const _hoisted_2 = { class: "row items-center justify-center q-pt-xl q-px-md" }
const _hoisted_3 = { class: "col-12 col-sm-7 col-md-5 col-lg-3 col-xl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_large_btn = _resolveComponent("large-btn")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.t('global.pages.contact'),
    description: _ctx.tp('description'),
    class: "contact",
    column: "",
    position: "start"
  }, {
    breadcrumbs: _withCtx(() => [
      _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row items-center justify-center q-px-md" }, [
          _createElementVNode("div", { class: "col-auto" }, [
            _createElementVNode("img", {
              loading: "lazy",
              src: "/images/support.svg",
              width: "256",
              height: "256",
              alt: "no partners"
            })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_large_btn, {
              sync: "",
              fill: "",
              label: _ctx.tp('openChat'),
              loading: _ctx.loading,
              disable: !_ctx.loaded,
              runnable: _ctx.open
            }, null, 8, ["label", "loading", "disable", "runnable"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "description"]))
}