
import { computed, defineComponent, PropType, ref, Ref } from 'vue'
import { ReviewProjection } from 'src/model/review.model'
import { useI18n$ } from 'boot/i18n'
import useFormat from 'src/compositions/format'
import { QBtn, QCard, QCardSection, QIcon, QIntersection, QPopupProxy, QRating } from 'quasar'

const name = 'review-card'

export default defineComponent({
  name,
  components: {
    QPopupProxy,
    QCard,
    QCardSection,
    QIcon,
    QBtn,
    QRating,
  },
  props: {
    review: {
      type: Object as PropType<ReviewProjection>,
      required: true
    },
    full: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const {
      st,
      tp,
    } = useI18n$(name)
    const { fdf } = useFormat()

    const cardRef = ref(null) as unknown as Ref<QIntersection>

    const reviewerName = computed(() => {
      const name = (props.review.customer?.name ?? props.review.partner?.name)!
      return st(name) || 'Anonymous'
    })
    const caption = computed(() => {
      const partner = !!props.review.partner
      return partner ? st(props.review.city) : (props.review.created ? fdf(props.review.created) : '')
    })
    const text = computed(() => {
      return props.review.text ? st(props.review.text) : ''
    })
    const largeLength = computed(() => (text.value?.length ?? 0) > 64)

    return {
      cardRef,
      largeLength,
      text,
      reviewerName,
      caption,
      st,
      tp,
    }
  }
})
