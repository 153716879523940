import { PreFetchOptions } from '@quasar/app-webpack'
import useTermsHome from 'stores/termsHome'
import useUserPreview from 'stores/userPreview'
import { DEFAULT_COUNTRY_KEY } from 'src/model/constants'
import useUserLocation from 'stores/userLocation'
import useAppData from 'stores/appData'
import useUserDetails from 'stores/userDetails'

export default async function termsPrefetch (options: PreFetchOptions<any>) {
  const { language } = useUserDetails(options.store)
  const { initialLocation } = useUserLocation(options.store)
  const { country } = useUserPreview(options.store)
  const { loadOne } = useTermsHome(options.store)
  const { loadReviews } = useAppData(options.store)

  const userCountry = country?.key || initialLocation?.countryKey || DEFAULT_COUNTRY_KEY

  await Promise.all([
    loadOne(userCountry, options.currentRoute.query.type !== 'partner'),
    loadReviews(language),
  ])
}
