import { computed, ComputedRef, Ref } from 'vue'
import { formatAmount } from 'src/functions/formatting'
import {
  DEFAULT_CURRENCY,
  DEFAULT_DAY_PRICE,
  DEFAULT_HOUR_PRICE,
  DEFAULT_INSURANCE,
  DEFAULT_LARGE_DAY_PRICE,
  DEFAULT_LARGE_HOUR_PRICE,
  DEFAULT_LARGE_MONTH_PRICE,
  DEFAULT_MONTH_PRICE,
  DEFAULT_SMALL_DAY_PRICE,
  DEFAULT_SMALL_HOUR_PRICE,
  DEFAULT_SMALL_MONTH_PRICE
} from 'src/model/constants'
import { refStore } from 'stores/__common'
import useUserPreview from 'stores/userPreview'
import { useI18n } from 'boot/i18n'
import { AnonymousCompanyProjection } from 'src/model/company.model'
import { createAmount } from 'src/functions/utils'
import { Currency, HasPriceContainer, ItemSizeContainer, MayHavePriceContainer } from 'src/model/common.model'
import { NamedValue } from '@intlify/core-base'

export function computePrice(
  ...containers: (MayHavePriceContainer | HasPriceContainer | undefined | null)[]
): ItemSizeContainer<{ dayPrice: number, monthPrice: number, hourPrice: number }> {
  function doCompute(containers: (number | undefined)[], defaultValue: number): number {
    let result = defaultValue
    containers.forEach(price => result = price || result)
    return result
  }

  return {
    'S': {
      dayPrice: doCompute(containers.map(other => other?.smallItemPrices?.dayPrice), DEFAULT_SMALL_DAY_PRICE),
      monthPrice: doCompute(containers.map(other => other?.smallItemPrices?.monthPrice), DEFAULT_SMALL_MONTH_PRICE),
      hourPrice: doCompute(containers.map(other => other?.smallItemPrices?.hourPrice), DEFAULT_SMALL_HOUR_PRICE)
    },
    'M': {
      dayPrice: doCompute(containers.map(other => other?.dayPrice), DEFAULT_DAY_PRICE),
      monthPrice: doCompute(containers.map(other => other?.monthPrice), DEFAULT_MONTH_PRICE),
      hourPrice: doCompute(containers.map(other => other?.hourPrice), DEFAULT_HOUR_PRICE)
    },
    'L': {
      dayPrice: doCompute(containers.map(other => other?.largeItemPrices?.dayPrice), DEFAULT_LARGE_DAY_PRICE),
      monthPrice: doCompute(containers.map(other => other?.largeItemPrices?.monthPrice), DEFAULT_LARGE_MONTH_PRICE),
      hourPrice: doCompute(containers.map(other => other?.largeItemPrices?.hourPrice), DEFAULT_LARGE_HOUR_PRICE)
    }
  }
}

export function computePrices(
  values: ItemSizeContainer<{ dayPrice: number, monthPrice: number, hourPrice: number }>,
  currency: Currency
): ItemSizeContainer<{ dayPrice: string, monthPrice: string, hourPrice: string }> {

  return {
    'S': {
      dayPrice: formatAmount(createAmount(values.S.dayPrice, currency)),
      monthPrice: formatAmount(createAmount(values.S.monthPrice, currency)),
      hourPrice: formatAmount(createAmount(values.S.hourPrice, currency))
    },
    'M': {
      dayPrice: formatAmount(createAmount(values.M.dayPrice, currency)),
      monthPrice: formatAmount(createAmount(values.M.monthPrice, currency)),
      hourPrice: formatAmount(createAmount(values.M.hourPrice, currency))
    },
    'L': {
      dayPrice: formatAmount(createAmount(values.L.dayPrice, currency)),
      monthPrice: formatAmount(createAmount(values.L.monthPrice, currency)),
      hourPrice: formatAmount(createAmount(values.L.hourPrice, currency))
    }
  }
}

export function computeLabels(
  values: ItemSizeContainer<{ dayPrice: string, monthPrice: string, hourPrice: string }>,
  t: (key: string, args: NamedValue) => string,
): ItemSizeContainer<{ dayPrice: string, monthPrice: string, hourPrice: string }> {

  return {
    'S': {
      dayPrice: t('global.dayPrice.small', { price: values.S.dayPrice }),
      monthPrice: t('global.monthPrice.small', { price: values.S.monthPrice }),
      hourPrice: t('global.hourPrice.small', { price: values.S.hourPrice })
    },
    'M': {
      dayPrice: t('global.dayPrice.small', { price: values.M.dayPrice }),
      monthPrice: t('global.monthPrice.small', { price: values.M.monthPrice }),
      hourPrice: t('global.hourPrice.small', { price: values.M.hourPrice })
    },
    'L': {
      dayPrice: t('global.dayPrice.small', { price: values.L.dayPrice }),
      monthPrice: t('global.monthPrice.small', { price: values.L.monthPrice }),
      hourPrice: t('global.hourPrice.small', { price: values.L.hourPrice })
    }
  }
}

export default function useUserPreviewAmounts(company: ComputedRef<AnonymousCompanyProjection | null> | Ref<AnonymousCompanyProjection | null> | null = null) {
  const { t } = useI18n()
  const { city, country } = refStore(useUserPreview())

  const priceCurrency = computed(() => country.value?.currency ?? DEFAULT_CURRENCY)
  const priceValues = computed(() => computePrice(country.value, city.value, company?.value?.default))
  const prices = computed(() => computePrices(priceValues.value, priceCurrency.value))
  const labels = computed(() => computeLabels(prices.value, t))
  const insurance = computed(() => formatAmount(createAmount(country.value?.insurance ?? DEFAULT_INSURANCE, priceCurrency.value)))
  //self
  const monthlyPriceValue = computed(() => company?.value?.self?.monthlyPrice ?? country.value?.monthPrice ?? DEFAULT_MONTH_PRICE)
  const monthlyPrice = computed(() => formatAmount(createAmount(monthlyPriceValue.value, priceCurrency.value)))
  const firstMonthPriceValue = computed(() => company?.value?.self?.firstMonthDiscount ? Number((monthlyPriceValue.value / 2).toFixed(2)) : null)
  const firstMonthPrice = computed(() => firstMonthPriceValue.value ? formatAmount(createAmount(firstMonthPriceValue.value, priceCurrency.value)) : null)
  const firstMonthPriceLabel = computed(() => firstMonthPrice.value ? t('global.monthlyPrice.first.small', { price: firstMonthPrice.value }) : null)

  return {
    priceValues,
    priceCurrency,
    prices,
    labels,
    insurance,
    monthlyPrice,
    firstMonthPrice,
    firstMonthPriceLabel,
  }
}
