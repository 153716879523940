
import { defineComponent } from 'vue'
import { useI18n$ } from 'boot/i18n'
import { QRating } from 'quasar'
import useAppData from 'stores/appData'
import { refStore } from 'stores/__common'

const name = 'qeepl-rating'

export default defineComponent({
  name,
  components: {
    QRating
  },
  props: {
    size: {
      type: String,
      default: 'xs'
    },
    fill: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { tp } = useI18n$(name)
    const { total } = refStore(useAppData())

    return {
      totalReview: total,
      tp,
    }
  }
})
