
import { computed, defineAsyncComponent, defineComponent, PropType } from 'vue'
import { QNoSsr, useQuasar } from 'quasar'
import { createTimeRange, isToday, isTomorrow } from 'src/functions/date'
import useFormat from 'src/compositions/format'
import useLazyDialog from 'src/compositions/lazyDialog'
import IconBtn from 'components/simple/icon-btn.vue'
import { injectUserOrder } from 'src/compositions/user/userOrderCommon'
import { refStore } from 'stores/__common'
import useUserPreview from 'stores/userPreview'
import { useI18n } from 'boot/i18n'

export default defineComponent({
  name: 'date-time-button',
  components: {
    QNoSsr,
    IconBtn,
    DateTimeDialog: defineAsyncComponent(() => import(/* webpackChunkName: "date-time-dialog", webpackMode: "lazy" */ 'components/advanced/lazy/date-time-dialog.vue'))
  },
  props: {
    single: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    noCheckout: {
      type: Boolean,
      default: false
    },
    noTime: {
      type: Boolean,
      default: false
    },
    checkinDisabled: {
      type: Boolean,
      default: false
    },
    disabledUntil: {
      type: Object as PropType<Date | null>,
      default: null
    },
    timezone: {
      type: String,
      required: false
    },
    holidays: {
      type: Array as PropType<string[]>,
      default: () => []
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { city } = refStore(useUserPreview())
    const { dateRange, timeRange, company } = injectUserOrder()
    const { screen } = useQuasar()
    const { ftr, fd, fdr } = useFormat()
    const { dialogModel, requestDialogLoading, openDialog } = useLazyDialog()

    const datesSingleLabel = computed(() => {
      if (dateRange.value.from === dateRange.value.to) {
        return fd(dateRange.value.from)
      }
      return fdr(dateRange.value)
    })
    const checkInDateLabel = computed(() => formatInternal(dateRange.value.from))
    const checkOutDateLabel = computed(() => formatInternal(dateRange.value.to))
    const checkInTimeRangeLabel = computed(() => {
      const timeRangeResult = timeRange.value && !props.noTime ? createTimeRange(timeRange.value.from) : null
      return timeRangeResult ? ftr(timeRangeResult) : ''
    })
    const checkOutTimeRangeLabel = computed(() => {
      const timeRangeResult = timeRange.value && !props.noTime ? createTimeRange(timeRange.value.to, false) : null
      return timeRangeResult ? ftr(timeRangeResult) : ''
    })
    const padding = computed(() => props.dense ? (screen.width <= 320 ? 'xs' : 'sm') : 'md xs')

    const formatInternal = (date: string) => {
      const timezone = company.value?.timezone ?? city.value?.timezone
      if (isToday(date, timezone)) {
        return t('global.today')
      }
      if (isTomorrow(date, timezone)) {
        return t('global.tomorrow')
      }
      return fd(date)
    }

    return {
      datesSingleLabel,
      checkInDateLabel,
      checkOutDateLabel,
      checkInTimeRangeLabel,
      checkOutTimeRangeLabel,
      dialogModel,
      requestDialogLoading,
      padding,
      openDialog
    }
  }
})
