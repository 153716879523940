
import { defineComponent, onMounted, onUnmounted } from 'vue'
import { QIntersection } from 'quasar'

const name = 'visibility-template'

export default defineComponent({
  name,
  components: {
    QIntersection,
  },
  props: {
    eager: {
      type: Boolean,
      default: false
    },
    once: {
      type: Boolean,
      default: true
    },
    root: {
      type: Object,
      default: null
    },
    containerClass: {
      default: ''
    },
  },
  emits: [
    'visible',
    'hidden'
  ],
  setup(props, { emit }) {
    onMounted(() => {
      if (props.eager) {
        emit('visible')
      }
    })
    onUnmounted(() => {
      if (props.eager) {
        emit('hidden')
      }
    })

    const visibilityChanged = (visible: boolean) => {
      if (visible) {
        emit('visible')
      } else {
        emit('hidden')
      }
    }
    return {
      visibilityChanged
    }
  }
})
