
import { defineComponent } from 'vue'
import AttractionTemplate from 'components/template/attraction-template.vue'
import attractionPrefetch from 'src/compositions/prefetch/attractionPrefetch'
import { ATTRACTION_SELF } from 'pages/names'

const name = ATTRACTION_SELF

export default defineComponent({
  name,
  components: {
    AttractionTemplate,
  },
  async preFetch(options) {
    await attractionPrefetch(options, 'SELF')
  },
  setup() {
    return {
      name
    }
  }
})
