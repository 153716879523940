import { RouteRecordRaw } from 'vue-router'
import { Pinia } from 'pinia'
import { QSsrContext } from '@quasar/app-webpack'
import {
  ATTRACTION_ALIAS,
  ATTRACTION_LUGGAGE,
  ATTRACTION_SELF,
  BLOG,
  BLOG_LIST,
  CABINET,
  CABINET__BOOKINGS,
  CABINET__INSTRUCTION,
  CABINET__LOCATIONS,
  CABINET__PAYOUT_SETTINGS,
  CABINET__REPORTS,
  CITY_ALIAS,
  CITY_LUGGAGE,
  CITY_SELF,
  INDEX,
  INDEX_ALIAS,
  INDEX_LUGGAGE,
  INDEX_SELF,
  ONBOARDING,
  PARTNERS,
  RECOVERY,
  REVIEWS
} from 'pages/names'
import {
  ROLE_ADMIN,
  ROLE_ADMIN_SECONDARY,
  ROLE_MASTER_USER_OF_COMPANY,
  ROLE_ONBOARDING_USER_OF_COMPANY,
  ROLE_USER_OF_COMPANY
} from 'src/model/constants'
import commonRoutes from 'src/router/common'

const children: RouteRecordRaw[] = [
  {
    path: '',
    name: INDEX,
    component: () => import(/* webpackChunkName: "index", webpackMode: "eager" */ 'pages/anonymous/index.vue')
  },
  {
    path: 'luggage-storage',
    name: INDEX_LUGGAGE,
    component: () => import(/* webpackChunkName: "index-luggage", webpackMode: "eager" */ 'pages/anonymous/index-luggage.vue')
  },
  {
    path: 'self-storage',
    name: INDEX_SELF,
    component: () => import(/* webpackChunkName: "index-self", webpackMode: "eager" */ 'pages/anonymous/index-self.vue')
  },
  {
    path: 'luggage-storage/:city',
    name: CITY_LUGGAGE,
    component: () => import(/* webpackChunkName: "city", webpackMode: "eager" */ 'pages/anonymous/city-luggage.vue')
  },
  {
    path: 'self-storage/:city',
    name: CITY_SELF,
    component: () => import(/* webpackChunkName: "city", webpackMode: "eager" */ 'pages/anonymous/city-self.vue')
  },
  {
    path: 'luggage-storage/:city/:attraction',
    name: ATTRACTION_LUGGAGE,
    component: () => import(/* webpackChunkName: "attraction", webpackMode: "eager" */ 'pages/anonymous/attraction-luggage.vue')
  },
  {
    path: 'self-storage/:city/:attraction',
    name: ATTRACTION_SELF,
    component: () => import(/* webpackChunkName: "attraction", webpackMode: "eager" */ 'pages/anonymous/attraction-self.vue')
  },
  {
    path: 'blog',
    name: BLOG_LIST,
    component: () => import(/* webpackChunkName: "blog", webpackMode: "eager" */ 'pages/anonymous/blog-list.vue')
  },
  {
    path: 'blog/:blog',
    name: BLOG,
    component: () => import(/* webpackChunkName: "blog", webpackMode: "eager" */ 'pages/anonymous/blog.vue')
  },
  {
    path: 'recovery',
    name: RECOVERY,
    component: () => import(/* webpackChunkName: "recovery", webpackMode: "eager" */ 'pages/anonymous/recovery.vue')
  },
  {
    path: 'reviews',
    name: REVIEWS,
    component: () => import(/* webpackChunkName: "reviews", webpackMode: "eager" */ 'pages/anonymous/reviews.vue')
  },
  {
    path: 'partners',
    name: PARTNERS,
    component: () => import(/* webpackChunkName: "partners" */ 'pages/anonymous/partners.vue')
  },
  {
    path: 'onboarding',
    name: ONBOARDING,
    component: () => import(/* webpackChunkName: "onboarding" */ 'pages/onboarding/onboarding.vue'),
    meta: { authorities: [ROLE_ONBOARDING_USER_OF_COMPANY], emailLogin: true }
  },
  {
    path: 'cabinet',
    name: CABINET,
    component: () => import(/* webpackChunkName: "cabinet" */ 'pages/partner/cabinet.vue'),
    meta: { authorities: [ROLE_USER_OF_COMPANY, ROLE_MASTER_USER_OF_COMPANY], emailLogin: true }
  },
  {
    path: 'cabinet/bookings',
    name: CABINET__BOOKINGS,
    component: () => import(/* webpackChunkName: "cabinet" */ 'pages/partner/cabinet/bookings.vue'),
    meta: { authorities: [ROLE_USER_OF_COMPANY, ROLE_MASTER_USER_OF_COMPANY], emailLogin: true }
  },
  {
    path: 'cabinet/reports',
    name: CABINET__REPORTS,
    component: () => import(/* webpackChunkName: "cabinet" */ 'pages/partner/cabinet/reports.vue'),
    meta: { authorities: [ROLE_USER_OF_COMPANY, ROLE_MASTER_USER_OF_COMPANY], emailLogin: true }
  },
  {
    path: 'cabinet/locations',
    name: CABINET__LOCATIONS,
    component: () => import(/* webpackChunkName: "cabinet" */ 'pages/partner/cabinet/locations.vue'),
    meta: { authorities: [ROLE_USER_OF_COMPANY, ROLE_MASTER_USER_OF_COMPANY], emailLogin: true }
  },
  {
    path: 'cabinet/payout-settings',
    name: CABINET__PAYOUT_SETTINGS,
    component: () => import(/* webpackChunkName: "cabinet" */ 'pages/partner/cabinet/payout-settings.vue'),
    meta: { authorities: [ROLE_MASTER_USER_OF_COMPANY], emailLogin: true }
  },
  {
    path: 'cabinet/instruction',
    name: CABINET__INSTRUCTION,
    component: () => import(/* webpackChunkName: "cabinet" */ 'pages/partner/cabinet/instruction.vue'),
    meta: { authorities: [ROLE_USER_OF_COMPANY, ROLE_MASTER_USER_OF_COMPANY], emailLogin: true }
  },
  {
    path: 'admin',
    name: 'admin-all',
    component: () => import(/* webpackChunkName: "admin-all", webpackMode: "lazy" */ 'pages/admin/admin-all.vue'),
    meta: { authorities: [ROLE_ADMIN, ROLE_ADMIN_SECONDARY], emailLogin: true }
  },
  {
    path: ':serviceTypeAlias',
    name: INDEX_ALIAS,
    component: () => import(/* webpackChunkName: "index", webpackMode: "eager" */ 'pages/anonymous/index-alias.vue')
  },
  {
    path: ':serviceTypeAlias/:city',
    name: CITY_ALIAS,
    component: () => import(/* webpackChunkName: "city", webpackMode: "eager" */ 'pages/anonymous/city-alias.vue')
  },
  {
    path: ':serviceTypeAlias/:city/:attraction',
    name: ATTRACTION_ALIAS,
    component: () => import(/* webpackChunkName: "attraction", webpackMode: "eager" */ 'pages/anonymous/attraction-alias.vue')
  },
]

const routes: (store: Pinia, ssrContext?: QSsrContext | null) => RouteRecordRaw[] = (store, ssrContext) => {
  return commonRoutes(children, store, ssrContext)
}

export default routes
