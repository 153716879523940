import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-center q-pt-lg"
}
const _hoisted_2 = { class: "col-12 col-md-9 col-lg-6 q-px-md" }
const _hoisted_3 = {
  key: 0,
  class: "text-grey-7 q-pb-sm"
}
const _hoisted_4 = {
  key: 1,
  class: "col flex flex-center q-pt-xl"
}
const _hoisted_5 = { class: "column col-auto justify-center text-center text-h3" }
const _hoisted_6 = { class: "row col-12 col-md-9 col-lg-6 q-px-md" }
const _hoisted_7 = { class: "row items-center justify-center q-mb-xl q-pt-md q-px-md" }
const _hoisted_8 = { class: "col-12 col-sm-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_markdown = _resolveComponent("markdown")!
  const _component_luggage_button = _resolveComponent("luggage-button")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page_sticky = _resolveComponent("q-page-sticky")!
  const _component_error_handle_template = _resolveComponent("error-handle-template")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: _ctx.st(_ctx.blog.title) || '-',
    class: "blog",
    column: "",
    position: "start"
  }, {
    breadcrumbs: _withCtx(() => [
      _createVNode(_component_breadcrumbs, { values: _ctx.breadcrumbEntries }, null, 8, ["values"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_error_handle_template, {
        success: !!_ctx.content.id,
        reload: _ctx.loadOne
      }, {
        default: _withCtx(() => [
          (_ctx.body)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.content.createdDate)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.fdf(_ctx.content.createdDate)), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_markdown, {
                    text: _ctx.body,
                    onExtracted: _ctx.onExtracted
                  }, null, 8, ["text", "onExtracted"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.tp('noBlog')), 1)
                ])
              ])),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_luggage_button, {
                fill: _ctx.$q.screen.xs
              }, null, 8, ["fill"])
            ])
          ]),
          _createVNode(_component_q_page_sticky, {
            offset: [24, 32],
            position: "bottom-left",
            class: "z-top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                icon: _ctx.matArrowBack,
                label: _ctx.$q.screen.gt.xs ? _ctx.t('action.back') : '',
                padding: _ctx.$q.screen.gt.xs ? 'md': 'sm',
                to: _ctx.toPrevPage(),
                class: "shadow-5 text-grey-7 text-transform-none text-normal",
                color: "white",
                rounded: "",
                size: "lg"
              }, null, 8, ["icon", "label", "padding", "to"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["success", "reload"])
    ]),
    _: 1
  }, 8, ["title"]))
}