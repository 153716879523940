
import { defineComponent } from 'vue'
import { QBtn } from 'quasar'
import { VueClick } from 'components/models'
import { matClose } from 'src/config/icons'

const name = 'close-btn'

export default defineComponent({
  name,
  components: {
    QBtn
  },
  props: {
    to: {
      type: String,
      required: false
    },
    noOverflow: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
  },
  emits: [
    VueClick
  ],
  setup(props, { emit }) {

    const onClick = () => emit(VueClick)

    return {
      matClose,
      onClick
    }
  }
})
