import { boot } from 'quasar/wrappers'
import useUserDetails from 'stores/userDetails'
import { TOKEN_STORE_NAME } from 'src/model/constants'
import { refStore } from 'stores/__common'
import { Authority } from 'src/model/user.model'
import { RouteLocationAsRelativeGeneric, RouteLocationNormalizedLoaded } from 'vue-router'
import { getValue } from 'src/functions/bridge'

export default boot(async ({ store, router, ssrContext }) => {
  const { authenticated, hasAnyAuthority, token: storeToken } = refStore(useUserDetails(store))

  const checkAuthority = (route: RouteLocationNormalizedLoaded) => {
    if (route.meta?.authorities) {
      let loginRequired
      if (route.meta.authorities === 'ANY') {
        loginRequired = !authenticated.value
      } else {
        loginRequired = !hasAnyAuthority(route.meta!.authorities as Authority[])
      }
      const partner = route.meta.emailLogin ? { partner: 'true' } : undefined
      return loginRequired && route.meta.loginRedirect !== false ?
        router.resolve({
          ...route,
          name: 'login',
          query: { returnURL: route.fullPath, ...partner }
        } as unknown as RouteLocationAsRelativeGeneric) : undefined
    }
  }

  if (!ssrContext) {
    storeToken.value = await getValue(TOKEN_STORE_NAME) || ''
  }

  router.beforeEach(async (to, from, next) => {
    const routeTo = checkAuthority(to)
    routeTo ? next(routeTo) : next()
  })
})
