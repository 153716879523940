import {
  ALLOWED_LANGUAGES, DEFAULT_LOGO_512,
  DEFAULT_LOGO,
  EMAIL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  SITE_URL,
  TWITTER_URL
} from 'src/model/constants'
import { Language, LanguageContainer } from 'src/model/language.model'
import { Coordinates, Currency, FAQEntry, TotalReview } from 'src/model/common.model'
import { MetaTagOptions } from 'quasar/dist/types/meta'

export interface HasTitle {
  title: string
}

export interface HasDescription {
  description: string
}

export interface HasUrl {
  url: string
}

export interface HasMainProperties extends HasTitle, HasDescription, HasUrl {
}

export interface HasImage {
  image: string
}

export interface MayHaveImage {
  image?: string
}

export interface HasName {
  name: string
}

export interface HasCountry {
  country: string
}

export interface HasCity {
  city: string
}

export interface MayHaveAttraction {
  attraction?: string
}

export interface HasBody {
  body: string
}

export interface HasContactType {
  contactType: string
}

export interface HasAlternateName {
  alternateName: string
}

export interface MayHaveAlternateName {
  alternateName?: string
}

export interface MayHaveSlogan {
  slogan?: string
}

export interface HasCoordinates {
  coordinates: Coordinates
}

export interface HasCreatedDate {
  createdDate: string
}

export interface HasLanguage {
  language: Language
}

export interface HasTotalReview {
  totalReview: TotalReview
}

export interface PathEntry {
  path: string
  name?: string
}

export interface HasPrice {
  dayPrice: string
}

export interface HasCurrency {
  currency: Currency
}

export interface HasPriceValue {
  priceValue: number
}

export interface MetaHeaderConfiguration extends HasMainProperties, HasImage, MayHaveAlternateName, MayHaveSlogan {
}

export interface OrganizationJsonLdConfiguration extends HasLanguage, HasImage, HasContactType, HasAlternateName, HasUrl, HasDescription, HasLanguage, HasTotalReview {
}

export interface ArticleJsonLdConfiguration extends HasTitle, HasUrl, HasImage, HasCreatedDate, HasDescription, HasBody, HasTotalReview, HasPrice {
}

export interface WebpageJsonLdConfiguration extends HasDescription, HasUrl, HasAlternateName, HasLanguage {
}

export interface SelfStorageJsonLdConfiguration extends HasTitle, HasUrl, HasCountry, HasCoordinates, HasPrice, HasTotalReview, HasImage, HasCity, HasDescription {
}

export interface ImageJsonLdConfiguration extends HasTitle, HasImage {
}

export interface ProductJsonLdConfiguration extends HasMainProperties, MayHaveImage, HasAlternateName, HasPriceValue, HasCurrency, HasLanguage, HasTotalReview {
}

export function createDescriptionMetaHeader(description: string): MetaTagOptions {
  return {
    description: { name: 'description', content: description },
  }
}

export function createMetaHeaders(configuration: MetaHeaderConfiguration): MetaTagOptions {
  const { title, description, image, url, alternateName, slogan } = configuration
  return {
    description: { name: 'description', content: description },
    //twitter
    twitterTitle: { name: 'twitter:title', content: title },
    twitterDescription: { name: 'twitter:description', content: description },
    twitterCard: { name: 'twitter:card', content: 'summary_large_image' },
    twitterSite: { name: 'twitter:site', content: url },
    twitterImage: { name: 'twitter:image', content: image },
    //opengraph
    ogTitle: { property: 'og:title', content: title },
    ogDescription: { property: 'og:description', content: description },
    ogType: { property: 'og:type', content: 'website' },
    ogImage: { property: 'og:image', content: image },
    ogUrl: { property: 'og:url', content: url },
  }
}

export function createProductJsonLd({ title, description, url, alternateName, image, priceValue, currency, language, totalReview }: ProductJsonLdConfiguration): string {
  const id = url.replace(SITE_URL, '')
  return `{"@context":"https://schema.org","@type":"Product","name":"${ title }","image":"${ image || `${ SITE_URL }${ DEFAULT_LOGO_512 }` }","logo":"${ SITE_URL }${ DEFAULT_LOGO_512 }","description":"${ description }","productID":"${ url }","sku":"${ id }","mpn":"${ id }","brand":{"@type":"Brand","name":"Qeepl","alternateName":"${ alternateName }","slogan":"${ description }","logo":"${ SITE_URL }${ DEFAULT_LOGO_512 }","sameAs":["${ SITE_URL }/${ language }","${ INSTAGRAM_URL }","${ TWITTER_URL }","${ FACEBOOK_URL }","${ LINKEDIN_URL }"]},"offers":{"@type":"Offer","url":"${ url }","priceCurrency":"${ currency }","price":"${ priceValue.toFixed(2) }","availability":"OnlineOnly","priceValidUntil":"2040-01-01"},"review":{"@type":"Review","reviewRating":{"@type":"Rating","ratingValue":${ totalReview.value },"bestRating":"5"},"author":{"@type":"Person","name":"Adele Cannon"}},"aggregateRating":{"@type":"AggregateRating","reviewCount":"${ totalReview.count }","ratingValue":"${ totalReview.value }","bestRating":5,"worstRating":1}}`
}

export function createOrganizationJsonLd({ description, url, alternateName, contactType, language, totalReview }: OrganizationJsonLdConfiguration): string {
  return `{"@context":"https://schema.org","@type":"Organization","name":"Qeepl","alternateName":"${ alternateName }","image":"${ SITE_URL }${ DEFAULT_LOGO_512 }","logo":"${ SITE_URL }${ DEFAULT_LOGO_512 }","url":"${ url }","description":"${ description }","contactPoint":{"@type":"ContactPoint","email":"${ EMAIL }","contactType":"${ contactType }","url":"${ SITE_URL }/${ language }/contact"},"sameAs":["${ SITE_URL }/${ language }","${ INSTAGRAM_URL }","${ TWITTER_URL }","${ FACEBOOK_URL }","${ LINKEDIN_URL }"],"aggregateRating":{"@type":"AggregateRating","reviewCount":"${ totalReview.count }","ratingValue":"${ totalReview.value }","bestRating":5,"worstRating":1}}`
}

export function createArticleJsonLd({ title, url, image, createdDate }: ArticleJsonLdConfiguration): string {
  return `{"@context":"https://schema.org","@type":"Article","mainEntityOfPage":{"@type":"WebPage","@id":"${ url }"},"headline":"${ title }","image":["${ image }"],"datePublished":"${ createdDate }","dateModified":"${ createdDate }","author":{"@type":"Organization","name":"Qeepl","logo":{"@type":"ImageObject","url":"${ SITE_URL }${ DEFAULT_LOGO_512 }"}},"publisher":{"@type":"Organization","name":"Qeepl","logo":{"@type":"ImageObject","url":"${ SITE_URL }${ DEFAULT_LOGO_512 }"}}}`;
}

export function createSelfStorageJsonLd({ image: imageUrl, title, country, city, description, url, dayPrice, totalReview, coordinates }: SelfStorageJsonLdConfiguration): string {
  return `{"@context":"https://schema.org","@type":"SelfStorage","@id": "${ url }","name":"${ title }","url":"${ url }","priceRange": "${ dayPrice }","description": "${ description }","email":"${ EMAIL }","logo":"${ SITE_URL }${ DEFAULT_LOGO_512 }","address":{"@type":"PostalAddress","addressLocality":"${ city }","addressCountry":"${ country }","streetAddress":null},${ geo(coordinates) },${ aggregateRating(totalReview) },${ openingHoursSpecification },${ image(imageUrl, title) },"parentOrganization":{"@type":"Organization","name":"Qeepl","logo":"${ SITE_URL }${ DEFAULT_LOGO_512 }","url":"${ SITE_URL }","sameAs":["${ FACEBOOK_URL }","${ TWITTER_URL }","${ INSTAGRAM_URL }","${ LINKEDIN_URL }"]}}`
}

export function createImageJsonLd({ title, image }: ImageJsonLdConfiguration): any {
  return `{"@context":"https://schema.org","@type":"ImageObject","contentUrl":"${ image }","name":"${ title }","description":"${ title }"}`
}

export function createWebSiteJsonLd({ alternateName, description, url, language }: WebpageJsonLdConfiguration): any {
  return `{"@context":"https://schema.org","@type":"WebSite","name":"Qeepl","alternateName":"${ alternateName }","description":"${ description }","url":"${ url }","potentialAction":{"@id":"${ SITE_URL }/${ language }/map"}}`
}

export function createBreadcrumbJsonLd(entries: PathEntry[], language: Language): string {
  let path = `/${ language }`
  const items = entries.map((item, i) => {
    path += `/${ entries[i].path }`
    return `{"@type":"ListItem","position":${ i + 1 },"name":"${ entries[i].name || entries[i].path }","item":"${ SITE_URL }${ path }"}`
  })
  return `{"@context":"https://schema.org","@type":"BreadcrumbList","itemListElement":[${items.join(',')}]}`
}

export function createFAQJsonLd(entries: FAQEntry[]): string {
  const items = entries.map((item, i) => {
    return `{"@type":"Question","name":"${ item.question }","acceptedAnswer":{"@type": "Answer","text": "${ item.answer }"}}`
  })
  return `{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[${items.join(',')}]}`
}

export function createLanguageLinks(path: string, language: Language, excludeAlternate?: boolean): any {
  path = path.toLowerCase()
  const result: any = {
    canonical: { rel: 'canonical', href: `${ SITE_URL }/${ language }${ path }` },
  }
  if (!excludeAlternate) {
    ALLOWED_LANGUAGES
      .forEach(l => result[`alternate_${ l }`] = { rel: 'alternate', href: `${ SITE_URL }/${ l }${ path }`, hreflang: l })
  }
  return result
}

export function createPreloadImages(images: string[]): any {
  const result: any = {}
  images.forEach((image, index) => {
    result[`image_${index}`] = { rel: 'preload', as: 'image', href: image }
  })
  return result
}
const openingHoursSpecification = '"openingHoursSpecification": [{"@type": "OpeningHoursSpecification","dayOfWeek": ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],"opens": "00:00","closes": "23:59"}]'

function image(imageUrl: string, caption: string = 'Qeepl Luggage Storage'): string {
  return `"image": {"@type":"ImageObject","@id":"${ imageUrl }","url":"${ imageUrl }","width":640,"height":480,"caption":"${ caption }"}`
}

function aggregateRating(totalReview: TotalReview): string {
  return `"aggregateRating":{"@type":"AggregateRating","bestRating":"5","worstRating":"1","ratingValue":${ totalReview.value },"reviewCount":${ totalReview.count }}`
}

function geo(coordinates: Coordinates): string {
  return `"geo":{"@type":"GeoCoordinates","latitude":${ coordinates.latitude },"longitude":${ coordinates.longitude }}`
}
