import { InjectionKey, } from 'vue'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { inject } from 'src/compositions/common'
import useEnvironment from 'src/compositions/environment'

const analyticsKey: InjectionKey<ReturnType<typeof useAnalytics_>> = Symbol('qeepl_analytics')

export interface AnalyticsEvent {
  name: string
  params?: object
}

function useAnalytics_() {
  const { app, csr, prod } = useEnvironment()

  const logEvent = (event: AnalyticsEvent | (() => AnalyticsEvent)) => {
    try {
      event = typeof event === 'function' ? event() : event
      if (app) {
        // @ts-ignore
        FirebaseAnalytics?.logEvent({ params: {}, ...event })
      } else if (csr && prod) {
        // @ts-ignore
        window?.gtag?.('event', event.name, event.params || {})
      } else {
        console.info('Tracking is disabled, was a try to log: ', event)
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  return {
    logEvent
  }
}

export default function useAnalytics() {
  return inject(analyticsKey, () => useAnalytics_())
}
