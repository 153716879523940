
import { computed, defineAsyncComponent, defineComponent } from 'vue'
import useUserOrder from 'src/compositions/user/userOrder'
import useLazyDialog from 'src/compositions/lazyDialog'
import IconBtn from 'components/simple/icon-btn.vue'
import { useI18n$ } from 'boot/i18n'
import { QNoSsr } from 'quasar'
import { computeDefaultOrderItemsCount, convertToDomain } from 'src/functions/order'

const name = 'bags-button'

export default defineComponent({
  name,
  components: {
    QNoSsr,
    IconBtn,
    BagsDialog: defineAsyncComponent(() => import(/* webpackChunkName: "bags-dialog", webpackMode: "lazy" */'components/advanced/lazy/bags-dialog.vue'))
  },
  props: {
    bgColor: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const { t } = useI18n$(name)
    const { itemsMetadata } = useUserOrder()
    const { dialogModel, requestDialogLoading, openDialog } = useLazyDialog()

    const itemsResultCount = computed(() => computeDefaultOrderItemsCount(convertToDomain(itemsMetadata.value)) || 1)
    const label = computed(() => t('global.bag', { number: itemsResultCount.value }, itemsResultCount.value))

    return {
      label,
      dialogModel,
      requestDialogLoading,
      openDialog,
    }
  }
})
