
import { defineComponent } from 'vue'

const name = 'container-template'

export default defineComponent({
  name,
  props: {},
  setup() {
    return {}
  }
})
