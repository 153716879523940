import { ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { CountryProjection } from 'src/model/country.model'
import { CityProjection } from 'src/model/city.model'
import { GeographyResponse } from 'src/api/dto/prefetch'

const useGeography = defineStore('geography', () => {
  const api = useAnonymousApi()

  const countries = ref<CountryProjection[]>([])
  const cities = ref<CityProjection[]>([])

  const onGeographyLoaded = (response: GeographyResponse) => {
    cities.value = response.cities || []
    countries.value = response.countries || []
  }

  const loadAll = async () => {
    if (!countries.value.length || !cities.value.length) {
      onGeographyLoaded(await api.getGeography(res => onGeographyLoaded(res.data)))
    }
  }

  return {
    countries,
    cities,
    loadAll
  }
})

export default useGeography
