import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["i18n"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: "page-links",
    i18n: _ctx.$options.name
  }, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.$q.screen.lt.md)
            ? (_openBlock(), _createBlock(_component_q_item, {
                key: 0,
                to: _ctx.to
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, {
                    class: "text-primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.label), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]))
            : (_openBlock(), _createBlock(_component_q_btn, {
                key: 1,
                class: _normalizeClass([_ctx.btnClass, "text-transform-none text-primary bg-grey-2 q-mr-sm"]),
                label: _ctx.label,
                to: _ctx.to,
                padding: "sm md",
                rounded: "",
                unelevated: ""
              }, null, 8, ["class", "label", "to"]))
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}