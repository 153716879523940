import { ChargeType, Range, ServiceType } from 'src/model/common.model'
import {
  addDays,
  addHours,
  buildDateRange,
  DEFAULT_BETWEEN_OPTS,
  formatDate,
  formatIsoLikeDate,
  formatTime,
  getAllWeekDays,
  getDayOfWeek,
  isBetweenDates,
  isToday,
  MIDNIGHT,
  now,
  parseDate,
} from 'src/functions/date'
import { Addition, AnonymousCompanyProjection, WorkRange } from 'src/model/company.model'
import { COMPANY_LUGGAGE_FALLBACK_IMG, COMPANY_SELF_FALLBACK_IMG } from 'src/model/constants'
import { doWhen, isDefault, isDefaultServiceType } from 'src/functions/utils'

export enum DailyStatus {
  OPEN, CLOSE, WILL_OPEN, WILL_CLOSE, DAY_OFF
}

export const FLOOR_GROUND = 0 //replacer
export const FLOOR_HIGH = 6 //replacer

const WORK_ALL_TIME: WorkRange = { from: MIDNIGHT, to: MIDNIGHT }

export function alwaysOpened(from: Date | string | number, to: Date | string | number): boolean {
  if (typeof from !== 'string') {
    from = formatTime(from)
  }
  if (typeof to !== 'string') {
    to = formatTime(to)
  }
  return (from === MIDNIGHT) && (to === MIDNIGHT)
}

export function getWorkRange(company: AnonymousCompanyProjection, date: string | number | Date): WorkRange {
  const day = getDayOfWeek(date)
  if (isDefault(company)) {
    const default_ = company.default!
    if (day === 6 || day === 7) {
      return default_.workTime.weekEndsRange
    } else {
      return default_.workTime.weekDaysRange
    }
  }
  return WORK_ALL_TIME
}

export function buildDateWorkRange(company: AnonymousCompanyProjection, date: string | number | Date, useNextDay: boolean = true): Range<Date> {
  const workRange = getWorkRange(company, date)
  return buildDateRange(formatDate(date), workRange, useNextDay)
}

export function isDisabledOnHolidays(company: AnonymousCompanyProjection, date: Date | string | number, holidays: string[] = []) {
  if (company.disabledOnHolidays && holidays.length) {
    return holidays.includes(formatIsoLikeDate(date))
  }
  return company.holidays?.includes(formatIsoLikeDate(date))
}

export function getDailyStatus(company: AnonymousCompanyProjection, date: Date | string | number, publicHolidays: string[] = []): DailyStatus {
  const dateAtTimezone = parseDate(date)
  const dayOfWeek = getDayOfWeek(dateAtTimezone)
  if (company.default?.notWorkingWeekDays?.includes(dayOfWeek) || isDisabledOnHolidays(company, date, publicHolidays)) {
    return DailyStatus.DAY_OFF
  }
  const { from, to } = buildDateWorkRange(company, date)
  if (alwaysOpened(from, to)) {
    return DailyStatus.OPEN
  }
  if (isToday(formatDate(dateAtTimezone))) {
    if (isBetweenDates(date, from, to, DEFAULT_BETWEEN_OPTS)) {
      if (to.getTime() < addHours(now(company.timezone), 1).getTime()) {
        return DailyStatus.WILL_CLOSE
      } else {
        return DailyStatus.OPEN
      }
    }
    if (dateAtTimezone.getTime() < from.getTime()) {
      return DailyStatus.WILL_OPEN
    }
  } else {
    if (isBetweenDates(date, from, to, DEFAULT_BETWEEN_OPTS)) {
      return DailyStatus.OPEN
    }
    if (from.getTime() > dateAtTimezone.getTime()) {
      return DailyStatus.WILL_OPEN
    }
  }
  return DailyStatus.CLOSE
}

export function getCurrentDailyStatus(company: AnonymousCompanyProjection, publicHolidays: string[] = []): DailyStatus {
  return getDailyStatus(company, now(company.timezone), publicHolidays)
}

export function findNextWorkingDate(company: AnonymousCompanyProjection, publicHolidays: string[] = [], startDate?: string): string | null {
  if (company.default?.notWorkingWeekDays?.length === 7) {
    return null
  }
  let date: Date
  if (startDate) {
    date = parseDate(startDate)
    if (isToday(startDate, company.timezone)) {
      date = now(company.timezone)
    }
  } else {
    date = now(company.timezone)
  }
  let status = getDailyStatus(company, date, publicHolidays)
  for (let i = 0; i < 7; i++) {
    if (status === DailyStatus.OPEN || status === DailyStatus.WILL_OPEN) {
      return formatDate(date)
    }
    date.setHours(0, 0, 0, 0)
    date = addDays(date, 1)
    status = getDailyStatus(company, date, publicHolidays)
  }
  return null
}

export function extractFloorNumber(serviceType: ServiceType, additions?: Addition[]): number | undefined {
  if (isDefaultServiceType(serviceType)) {
    if (additions) {
      const floorAddition = additions?.find(a => a.startsWith('FLOOR_'))
      if (floorAddition === 'FLOOR_GROUND') {
        return FLOOR_GROUND
      }
      if (floorAddition === 'FLOOR_HIGH') {
        return FLOOR_HIGH
      }
      if (floorAddition) {
        return parseInt(floorAddition.replace('FLOOR_', ''))
      }
    } else {
      return FLOOR_GROUND
    }
  }
  return undefined
}

export function isOpen247(company: AnonymousCompanyProjection): boolean {
  return doWhen(
    company,
    luggage => {
      if (luggage.notWorkingWeekDays?.length) {
        return false
      }
      for (let date in getAllWeekDays()) {
        const workRange = getWorkRange(company, date)
        if (!alwaysOpened(workRange.from, workRange.to)) {
          return false
        }
      }
      return true
    },
    () => false
  )
}

export function computePublicName(company: AnonymousCompanyProjection, publicName: string): string {
  return doWhen(
    company,
    () => {
      if (publicName.startsWith('24/7') || !isOpen247(company)) {
        return publicName
      }
      return `24/7 ${ publicName }`
    },
    () => publicName
  )
}

export function photosAreSet(company: AnonymousCompanyProjection) {
  return !!company.photoURLs?.length && ![COMPANY_LUGGAGE_FALLBACK_IMG, COMPANY_SELF_FALLBACK_IMG].includes(company.photoURLs[0])
}

export function supportsChargeType(company: AnonymousCompanyProjection, chargeType: ChargeType): boolean {
  return company.supportedChargeTypes.includes(chargeType)
}

export function supportsHourly(company: AnonymousCompanyProjection): boolean {
  return supportsChargeType(company, 'HOURLY')
}

export function supportsMonthly(company: AnonymousCompanyProjection): boolean {
  return supportsChargeType(company, 'MONTHLY')
}

export function supportsDaily(company: AnonymousCompanyProjection): boolean {
  return supportsChargeType(company, 'DAILY')
}

export function getHolidays(company: AnonymousCompanyProjection, holidays?: string[]): string[] | undefined {
  return company.holidays?.length ? company.holidays : holidays
}
