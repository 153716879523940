import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "col-auto full-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_resize_observer = _resolveComponent("q-resize-observer")!
  const _component_close_btn = _resolveComponent("close-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    maximized: _ctx.$q.screen.xs,
    "model-value": _ctx.modelValue,
    persistent: !_ctx.nonPersistent,
    style: _normalizeStyle({ zIndex: _ctx.zIndex }),
    "transition-hide": _ctx.showMobile ? 'slide-right' : 'fade',
    "transition-show": _ctx.showMobile ? 'slide-left' : 'fade',
    class: "dialog-template",
    onHide: _ctx.onClose,
    onShow: _ctx.onOpen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_resize_observer, { onResize: _ctx.onDialogResize }, null, 8, ["onResize"]),
      _createVNode(_component_q_card, {
        ref: "cardRef",
        class: _normalizeClass([`${_ctx.contentClass}${_ctx.iosPadding ? ' dialog-template__card__with-ios-top' : ''} dialog-template__card`, "overflow-hidden"]),
        flat: _ctx.showMobile,
        style: _normalizeStyle((!_ctx.showMobile || _ctx.showMobileLandscape) && { minHeight: _ctx.minHeight, maxHeight: _ctx.maxHeight, minWidth: _ctx.minWidth, maxWidth: _ctx.maxWidth, height: _ctx.height, width: _ctx.width })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, {
            ref: "cardSectionRef",
            class: _normalizeClass([{'q-pa-md': !_ctx.noPaddings, 'q-pa-none': _ctx.noPaddings}, "column fit relative-position overflow-hidden-x dialog-template__card"]),
            style: _normalizeStyle(_ctx.cardSectionStyle)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_resize_observer, { onResize: _ctx.onResize }, null, 8, ["onResize"]),
              (_ctx.closable)
                ? (_openBlock(), _createBlock(_component_close_btn, {
                    key: 0,
                    disable: _ctx.disableClose,
                    class: "q-mt-xs q-mr-xs",
                    "no-overflow": "",
                    onClick: _ctx.closeDialog
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([{'overflow-auto': _ctx.$q.screen.xs}, "col full-width"])
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 2),
              (_ctx.$slots.actions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "actions")
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["class", "style"])
        ]),
        _: 3
      }, 8, ["class", "flat", "style"])
    ]),
    _: 3
  }, 8, ["maximized", "model-value", "persistent", "style", "transition-hide", "transition-show", "onHide", "onShow"]))
}