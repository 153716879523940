import { ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { Pageable } from 'src/model/common.model'
import { ReviewProjection } from 'src/model/review.model'
import { Language } from 'src/model/language.model'

const useReviews = defineStore('reviews', () => {
  const api = useAnonymousApi()

  const reviews = ref<ReviewProjection[]>([])
  const page = ref<number>(0)
  const noMore = ref<boolean>(false)
  const loading = ref<boolean>(false)

  const createPageable = (): Pageable => {
    return {
      page: page.value,
      size: 16
    }
  }
  const loadNext = async (language: Language) => {
    loading.value = true
    return api.getReviewsByLanguage(language, createPageable())
      .then(pageResult => {
        reviews.value = [...reviews.value, ...pageResult.data]
        page.value++
        noMore.value = pageResult.totalCount === reviews.value.length
      })
      .finally(() => loading.value = false)
  }

  return {
    reviews,
    loadingReviews: loading,
    noMore,
    loadNext
  }
})

export default useReviews
