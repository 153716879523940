
import { computed, defineComponent, PropType } from 'vue'
import { useI18n$ } from 'boot/i18n'
import HomeContentPageTemplate from 'components/template/home-content-page-template.vue'
import HeadingSectionTemplate from 'components/template/heading-section-template.vue'
import ObjectCard from 'components/simple/object-card.vue'
import useCityState from 'stores/cityHome'
import useHome from 'src/compositions/home/home'
import useRoutes from 'src/compositions/routes'
import { refStore } from 'stores/__common'
import usePageExtensions from 'src/compositions/pageExtensions'
import { CITY_FALLBACK_IMG } from 'src/model/constants'
import useAppData from 'stores/appData'
import { aliasToServiceType, isDefaultServiceType, keyToPath } from 'src/functions/utils'
import { ServiceType, ServiceTypeAlias, ServiceTypes } from 'src/model/common.model'

const name = 'city-template'

export default defineComponent({
  name,
  components: {
    HomeContentPageTemplate,
    HeadingSectionTemplate,
    ObjectCard,
  },
  props: {
    name: {
      type: String,
      required: true
    },
    alias: {
      type: String as PropType<ServiceTypeAlias>,
      default: 'LUGGAGE'
    },
  },
  setup(props) {
    const { content, attractions } = refStore(useCityState())
    const { total: totalReview } = refStore(useAppData())
    const { t, tp, tc, st } = useI18n$(props.name)
    const { cityParams: params, companies, allCompanies, city } = useHome(props.name)
    const { toAttractionLuggage, toAttractionSelf, toAttractionAlias } = useRoutes()

    const image = city.value?.photoURL || CITY_FALLBACK_IMG
    const type = aliasToServiceType(props.alias)
    const faq = type.toLowerCase()
    const path = props.alias === 'LUGGAGE' || props.alias === 'SELF' ? `${ props.alias.toLowerCase() }-storage` : keyToPath(props.alias)

    const toAttraction = (key: string) => {
      if (ServiceTypes.includes(props.alias as ServiceType) || props.alias === 'LUGGAGE' || props.alias === 'SELF') {
        return isDefaultServiceType(type) ? toAttractionLuggage(city.value!, key) : toAttractionSelf(city.value!, key)
      }
      return toAttractionAlias(props.alias, city.value!, key)
    }

    const urlQuery = computed(() => {
      return `city=${ params.value.cityKey }`
    })
    const filteredAttractions = computed(() => {
      return attractions.value.filter(a => a.services.includes(props.alias))
    })
    const priceValue = computed(() => {
      const paramsValue = params.value
      return isDefaultServiceType(type) ? {
        priceValue: paramsValue.dayPriceValue.value,
        currency: paramsValue.dayPriceValue.currency
      } : {
        priceValue: paramsValue.monthPriceValue.value,
        currency: paramsValue.monthPriceValue.currency
      }
    })

    const metaGetter = (name: string) => {
      const length = allCompanies.value.length
      return tc(
        `meta.${ name }.${ length ? 'locations' : 'noLocations' }`,
        { locationCount: length, ...params.value },
        length
      )
    }

    return {
      src: image,
      type,
      content,
      companies,
      params,
      urlQuery,
      filteredAttractions,
      tp,
      t,
      st,
      toAttraction,
      ...usePageExtensions({
        titleGetter: () => metaGetter('title'),
        descriptionGetter: props.alias === 'LUGGAGE' ? () => metaGetter('description') : undefined,
        page: props.name,
        faq: faq,
        product: {
          ...priceValue.value,
          totalReview: totalReview.value
        },
        organization: {
          totalReview: totalReview.value,
          image
        },
        breadcrumbs: (title, description, params) => {
          return [{
            path: path,
            name: tp('cityText') as string
          }, {
            path: params.cityPath as string,
            name: params.city as string,
            label: tp('title', params)
          }]
        },
        image,
        params,
        preloadImages: [image]
      })
    }
  }
})
