import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { Device } from '@capacitor/device'
import { Geolocation } from '@capacitor/geolocation'
import { LocalStorage } from 'quasar'
import { ALLOWED_LANGUAGES } from 'src/model/constants'
import { Language } from 'src/model/language.model'
import { Location } from 'src/model/common.model'

function isApp() {
  return Capacitor?.isNativePlatform() ?? false
}

export async function saveValue(key: string, value: string): Promise<void> {
  if (isApp()) {
    await Preferences.set({ key, value })
  } else {
    LocalStorage.remove(key)
    LocalStorage.set(key, value)
  }
}

export async function getValue(key: string): Promise<string | undefined> {
  if (isApp()) {
    return (await Preferences.get({ key }))?.value || undefined
  } else {
    return LocalStorage.getItem(key) as string || undefined
  }
}

export async function removeValue(key: string): Promise<void> {
  if (isApp()) {
    return (await Preferences.remove({ key }))
  } else {
    LocalStorage.remove(key)
  }
}

export async function getDeviceLanguage(): Promise<Language | undefined> {
  let language: Language | undefined
  if (isApp()) {
    language = (await Device.getLanguageCode())?.value as Language | undefined
  }
  if (language) {
    return ALLOWED_LANGUAGES.includes(language) ? language : undefined
  }
  return undefined
}

export async function getGPSLocation(): Promise<Location | null> {
  let position
  if (isApp()) {
    position = Geolocation.getCurrentPosition().catch(() => null)
  } else {
    position = new Promise(resolve => {
      return navigator?.geolocation?.getCurrentPosition ?
        navigator?.geolocation?.getCurrentPosition(
          result => resolve(result),
          () => resolve(null)
        ) : resolve(null)
    })
  }
  const coords = await position.then((res: any) => res ? res.coords : null)
  return coords ? {
    point: {
      latitude: coords.latitude,
      longitude: coords.longitude
    },
    accuracyInMeters: coords.accuracy
  } : null
}
