
import { defineComponent } from 'vue'
import { QImg, QResponsive, QSkeleton } from 'quasar'
import useLoadImage from 'src/compositions/loadImage'
import { IMGKIT_URL } from 'src/model/constants'

const name = 'storing-option-card'

export default defineComponent({
  name,
  components: {
    QSkeleton,
    QImg,
    QResponsive
  },
  props: {
    caption: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
  },
  setup() {
    return {
      IMGKIT_URL,
      ...useLoadImage()
    }
  }
})
