
import { computed, defineAsyncComponent, defineComponent, PropType } from 'vue'
import { BreadcrumbEntry, FAQEntry, ServiceType } from 'src/model/common.model'
import { useI18n$ } from 'boot/i18n'
import { QBtn, QImg, QResponsive, QSkeleton } from 'quasar'
import HomePageTemplate from 'components/template/home-page-template.vue'
import HeadingSectionTemplate from 'components/template/heading-section-template.vue'
import useLoadImage from 'src/compositions/loadImage'
import { Company } from 'src/model/company.model'
import useRoutes from 'src/compositions/routes'
import { Content } from 'src/model/content.model'
import ContentTemplate from 'components/template/content-template.vue'
import Breadcrumbs from 'components/simple/breadcrumbs.vue'

const name = 'home-content-page-template'

const Markdown = defineAsyncComponent(() => import(/* webpackChunkName: "markdown", webpackMode: "lazy" */'components/simple/markdown.vue'))

export default defineComponent({
  name,
  components: {
    ContentTemplate,
    QBtn,
    QResponsive,
    QImg,
    QSkeleton,
    HomePageTemplate,
    HeadingSectionTemplate,
    Markdown,
    Breadcrumbs,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    companies: {
      type: Array as PropType<Company[]>,
      required: true
    },
    companiesTitle: {
      type: String,
      required: true
    },
    faqEntries: {
      type: Array as PropType<FAQEntry[]>,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    fallbackSrc: {
      type: String,
      required: true
    },
    urlQuery: {
      type: String,
      required: true
    },
    breadcrumbs: {
      type: Array as PropType<BreadcrumbEntry[]>,
      default: []
    },
    articles: {
      type: Array as PropType<Array<{ title: string, content: string }>>,
      default: []
    },
    content: {
      type: Object as PropType<Content>,
      required: false
    },
    contentTitle: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<ServiceType>,
      required: false
    },
  },
  emits: [
    'leave-luggage'
  ],
  setup(props, { emit }) {
    const { t, tp, ste } = useI18n$(name)
    const { toMap } = useRoutes()

    const to = computed(() => toMap(`city=${ props.params.cityKey }`))
    const contentText = computed(() => props.content ? ste(props.content.body) : '')

    const leaveLuggage = () => emit('leave-luggage')

    return {
      to,
      contentText,
      t,
      tp,
      leaveLuggage,
      ...useLoadImage()
    }
  }
})
