import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "col flex flex-center q-mb-xl q-pt-xl"
}
const _hoisted_2 = { class: "column col-auto" }
const _hoisted_3 = { class: "row col-auto text-negative text-h3 items-center justify-center" }
const _hoisted_4 = {
  key: 0,
  class: "row items-center justify-center q-pt-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_ctx.success)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 })
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('global.operation.load')), 1),
          (_ctx.reload)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_q_btn, {
                  class: "col-auto text-transform-none",
                  flat: "",
                  color: "primary",
                  icon: _ctx.matRefresh,
                  size: "md",
                  padding: "md",
                  label: _ctx.t('action.reload'),
                  onClick: _ctx.reload
                }, null, 8, ["icon", "label", "onClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
}