import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_content_page_template = _resolveComponent("home-content-page-template")!

  return (_openBlock(), _createBlock(_component_home_content_page_template, {
    "fallback-src": "/images/attraction-fallback.jpg",
    title: _ctx.tp('title', _ctx.params),
    description: _ctx.tp('description', _ctx.params),
    "companies-title": _ctx.tp('companiesTitle', _ctx.params),
    "content-title": _ctx.tp('contentTitle', _ctx.params),
    params: _ctx.params,
    "faq-entries": _ctx.faqEntries,
    breadcrumbs: _ctx.breadcrumbEntries,
    companies: _ctx.companies,
    content: _ctx.content,
    src: _ctx.src,
    "url-query": _ctx.urlQuery,
    type: _ctx.type
  }, null, 8, ["title", "description", "companies-title", "content-title", "params", "faq-entries", "breadcrumbs", "companies", "content", "src", "url-query", "type"]))
}