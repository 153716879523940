import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_label = _resolveComponent("icon-label")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: _normalizeClass([{'text-transform-none': !_ctx.authenticated, 'text-lowercase': _ctx.authenticated}, "authorization-button"]),
    i18n: _ctx.$options.name,
    loading: _ctx.authorizing || _ctx.loggingOut,
    padding: _ctx.noPaddings ? 'none' : '',
    to: !_ctx.authenticated ? _ctx.toLogin() : _ctx.toProfile(),
    color: "primary",
    flat: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon_label, {
        icon: _ctx.authenticated ? 'user-profile' : 'sign-in',
        size: "sm"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.authenticated ? _ctx.user.login : _ctx.t('action.signIn')), 1)
        ]),
        _: 1
      }, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["class", "i18n", "loading", "padding", "to"]))
}