
import { defineComponent, computed, PropType } from 'vue'
import { QBtnToggle } from 'quasar'
import { useI18n$ } from 'boot/i18n'
import { VueModel } from 'components/models'

const name = 'tariff-buttons'

export default defineComponent({
  name,
  components: {
    QBtnToggle
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'grey-2'
    },
    textColor: {
      type: String,
      default: 'black'
    },
    toggleColor: {
      type: String,
      default: 'white'
    },
    toggleTextColor: {
      type: String,
      default: 'black'
    },
  },
  emits: [
    VueModel
  ],
  setup(props, { emit }) {
    const { t, tp } = useI18n$(name)

    const options = computed(() => {
      return [{
        value: true,
        label: 'Luggage storage',
        class: 'col-6 text-no-wrap'
      }, {
        value: false,
        label: 'Self storage',
        class: 'col-6 text-no-wrap'
      },]
    })

    const onModelValueUpdated = (value: boolean) => {
      emit(VueModel, value)
    }

    return {
      options,
      tp,
      t,
      onModelValueUpdated
    }
  }
})
