import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row full-width justify-center" }
const _hoisted_2 = { class: "col-12 col-lg-11 col-xl-9 q-px-md" }
const _hoisted_3 = { class: "col-6 col-sm-3" }
const _hoisted_4 = { class: "col-6 col-sm-3" }
const _hoisted_5 = { class: "col-6 col-sm-3" }
const _hoisted_6 = { class: "col-6 col-sm-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_storing_option_card = _resolveComponent("storing-option-card")!
  const _component_become_partner_card = _resolveComponent("become-partner-card")!
  const _component_index_template = _resolveComponent("index-template")!

  return (_openBlock(), _createBlock(_component_index_template, {
    name: _ctx.name,
    faq: _ctx.name
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["row items-center", {'q-col-gutter-xl': _ctx.$q.screen.gt.md, 'q-col-gutter-md': _ctx.$q.screen.lt.lg }])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_storing_option_card, {
                image: "/index-page/luggage.png",
                caption: _ctx.tp('luggage'),
                to: _ctx.toRootLuggage()
              }, null, 8, ["caption", "to"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_storing_option_card, {
                image: "/index-page/box.png",
                caption: _ctx.tp('self'),
                to: _ctx.toRootSelf()
              }, null, 8, ["caption", "to"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_storing_option_card, {
                image: "/index-page/shelves.png",
                caption: _ctx.tp('furniture'),
                to: _ctx.toRootSelf()
              }, null, 8, ["caption", "to"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_storing_option_card, {
                image: "/index-page/lockers.png",
                caption: _ctx.tp('lockers'),
                to: _ctx.toRootAlias('LOCKERS')
              }, null, 8, ["caption", "to"])
            ])
          ], 2)
        ])
      ])
    ]),
    "pre-faq": _withCtx(() => [
      _createVNode(_component_become_partner_card)
    ]),
    _: 1
  }, 8, ["name", "faq"]))
}