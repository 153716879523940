import { ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { TotalReview } from 'src/model/common.model'
import { ReviewProjection } from 'src/model/review.model'
import { ReviewsResponse } from 'src/api/dto/prefetch'
import { Language } from 'src/model/language.model'

const useAppData = defineStore('appData', () => {
  const api = useAnonymousApi()

  const total = ref<TotalReview>(null as unknown as TotalReview)
  const last = ref<ReviewProjection[]>([])
  const totalBags = ref(0)

  const onAppReviewsLoaded = ({ reviews, totalReview }: ReviewsResponse) => {
    total.value = totalReview
    last.value = reviews
  }
  const onBagsCountLoaded = (ordersCount: number) => {
    totalBags.value = ordersCount
  }
  const loadReviews = async (language: Language) => {
    if (!total.value) {
      const appReviews = await api.getAppReviews(language, res => onAppReviewsLoaded(res.data))
      onAppReviewsLoaded(appReviews)
    }
  }
  const loadBagsCount = async () => {
    if (!totalBags.value) {
      const itemsCount = await api.getBagsCount(res => onBagsCountLoaded(res.data))
      onBagsCountLoaded(itemsCount)
    }
  }

  return {
    total,
    last,
    totalBags,
    loadReviews,
    loadBagsCount
  }
})

export default useAppData
