import useUserOrder from 'src/compositions/user/userOrder'
import { parseDateTime } from 'src/functions/date'
import { InjectionKey, ref } from 'vue'
import { useRouter } from 'vue-router'
import { inject } from 'src/compositions/common'
import { refStore } from 'stores/__common'
import useExistingOrder from 'stores/existingOrder'
import { doWhen } from 'src/functions/utils'
import { convertToInternal } from 'src/functions/order'

const userExistingOrderKey: InjectionKey<ReturnType<typeof useUserExistingOrder_>> = Symbol('qeepl_userExistingOrder')

function useUserExistingOrder_() {
  const { existingOrder } = refStore(useExistingOrder())
  const { currentRoute } = useRouter()
  const { company, setAllLuggage, setAllSelf } = useUserOrder()

  const existingOrderId = ref('')

  const loadExistingOrder = () => {
    const existingOrderValue = existingOrder.value
    if (existingOrderValue && existingOrderValue.company.id === company.value?.id) {
      const fromDateTime = parseDateTime(existingOrderValue.from, existingOrderValue.timezone)
      const toDateTime = parseDateTime(existingOrderValue.to, existingOrderValue.timezone, true)
      doWhen(
        existingOrderValue,
        (default_) => {
          setAllLuggage(
            { from: fromDateTime.date, to: toDateTime.date },
            { from: fromDateTime.time, to: toDateTime.time },
            convertToInternal(default_),
            default_.discount || null,
            existingOrderValue.chargeType === 'HOURLY'
          )
        },
        (self) => {
          setAllSelf(
            { from: fromDateTime.date, to: toDateTime.date },
            { from: fromDateTime.time, to: toDateTime.time },
            self.orderLength
          )
        }
      )
      existingOrderId.value = currentRoute.value.query.orderId as string
    }
  }

  const forgetExistingOrder = () => {
    existingOrderId.value = ''
    existingOrder.value = null
  }

  return {
    existingOrderId,
    loadExistingOrder,
    forgetExistingOrder
  }
}

export default function useUserExistingOrder() {
  return inject(userExistingOrderKey, () => useUserExistingOrder_())
}
