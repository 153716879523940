
  import { computed, defineComponent } from 'vue'
  import useEnvironment from 'src/compositions/environment'
  import { SITE_URL } from 'src/model/constants'
  import useRoutes from 'src/compositions/routes'
  import { useI18n$ } from 'boot/i18n'

  const name = 'service-conditions'

  export default defineComponent({
    name,
    props: {
      dense: {
        type: Boolean,
        default: false
      },
      partner: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    setup(props) {
      const { app } = useEnvironment()
      const { toTerms, toPolicy } = useRoutes()
      const { tp } = useI18n$(name)

      const termsURL = computed(() => toTerms(props.partner))
      const policyURL = computed(() => toPolicy())

      return {
        app,
        siteURL: SITE_URL,
        termsURL,
        policyURL,
        tp
      }
    }
  })
