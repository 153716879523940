import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: _normalizeClass([{'full-width': _ctx.fill, 'rounded-borders': !_ctx.fab}, "large-btn"]),
    color: _ctx.color,
    disable: _ctx.disable || _ctx.running,
    fab: _ctx.fab,
    i18n: _ctx.$options.name,
    label: _ctx.label,
    loading: _ctx.loading || _ctx.running,
    to: _ctx.to,
    unelevated: "",
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "color", "disable", "fab", "i18n", "label", "loading", "to", "onClick"]))
}