import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row items-center text-center q-py-sm how-to-item" }
const _hoisted_2 = { class: "row inline relative-position how-to-item__icon" }
const _hoisted_3 = { class: "row items-center justify-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "col-12 col-sm-11 how-to-item__p-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({'col-4 col-sm-12 how-to-item__text-col--full': !_ctx.dense, 'col-auto': _ctx.dense})
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([{'col-8 col-sm-12': !_ctx.dense, 'col q-pl-md': _ctx.dense}, "how-to-item__text-col"])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", {
          class: "col-12 col-sm-11 q-pb-sm text-body1 text-weight-bold how-to-item__h3-text",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_4),
        (_ctx.description && !_ctx.$slots.description)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "col-12 col-sm-11 how-to-item__p-text",
              innerHTML: _ctx.description
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.$slots.description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
              _renderSlot(_ctx.$slots, "description")
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}