import { ref } from 'vue'
import { defineStore } from 'pinia'
import usePartnerApi from 'src/api/partner.api'
import { Partner } from 'src/model/partner.model'
import { BasePartnerProjection } from 'src/model/common.model'
import { Country } from 'src/model/country.model'
import useAnonymousApi from 'src/api/anonymous.api'

const usePartnerDetails = defineStore('partnerDetails', () => {
  const partnerApi = usePartnerApi()
  const anonymousApi = useAnonymousApi()

  const partner = ref<Partner | BasePartnerProjection | null>(null)
  const country = ref<Country | null>(null)

  const load = async () => {
    if (!partner.value) {
      partner.value = await partnerApi.getCurrent()
      await loadCountry()
    }
  }
  const loadFull = async () => {
    if (!partner.value) {
      partner.value = await partnerApi.getCurrentFull()
      await loadCountry()
    }
  }
  const loadCountry = async () => {
    if (partner.value) {
      country.value = await anonymousApi.getCountry(partner.value.country)
    }
  }

  const clear = async () => {
    partner.value = null
    country.value = null
  }

  return {
    partner,
    country,
    load,
    loadFull,
    clear
  }
})

export default usePartnerDetails
