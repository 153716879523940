
import { defineComponent } from 'vue'
import CityTemplate from 'components/template/city-template.vue'
import cityPrefetch from 'src/compositions/prefetch/cityPrefetch'
import { CITY_SELF } from 'pages/names'

const name = CITY_SELF

export default defineComponent({
  name,
  components: {
    CityTemplate
  },
  async preFetch(options) {
    await cityPrefetch(options, 'SELF')
  },
  setup() {

    return {
      name
    }
  }
})
