
import { defineComponent } from 'vue'

const name = 'heading-section-template'

export default defineComponent({
  name,
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    marginTop: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: Boolean,
      default: false
    },
    noSidePadding: {
      type: Boolean,
      default: false
    },
    bodyClass: {
      type: String,
      default: 'col-12 col-md-10 col-lg-8'
    },
  },
  setup() {
    return {}
  }
})
