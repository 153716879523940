import { InjectionKey, shallowReactive } from 'vue'
import { inject } from 'src/compositions/common'
import { Container, Coordinates, DistanceTo, NumberContainer, TotalReview } from 'src/model/common.model'
import useAnonymousApi from 'src/api/anonymous.api'
import useUserOrder from 'src/compositions/user/userOrder'
import { ReviewProjection } from 'src/model/review.model'
import { refStore } from 'stores/__common'
import useUserPreview from 'stores/userPreview'
import { Language } from 'src/model/language.model'

const companyCacheKey: InjectionKey<ReturnType<typeof useCompanyCache_>> = Symbol('qeepl_companyCache')

function useCompanyCache_() {
  const {
    getTotalCompanyReviews,
    getCompanyOccupiedCount,
    getCompanyActivity,
    getReviewsByCompany,
  } = useAnonymousApi()
  const { meta } = refStore(useUserPreview())
  const { dateRange } = useUserOrder()

  const totalReviewCache = shallowReactive<Container<TotalReview>>({})
  const occupiedCache = shallowReactive<NumberContainer>(meta.value?.occupied || {})
  const reviewsCache = shallowReactive<Container<ReviewProjection[]>>({})
  const latestOrderHoursCache = shallowReactive<NumberContainer>({})
  const lastTwoWeeksOrderCountCache = shallowReactive<NumberContainer>({})
  const closest3PlacesCache = shallowReactive<Container<DistanceTo[]>>({})
  const loading3PlacesCache = shallowReactive<Container<boolean>>({})

  const loadTotalReview = (companyId: string, force?: boolean) => {
    if (!totalReviewCache[companyId] || force) {
      getTotalCompanyReviews(companyId)
        .then(totalReview => {
          totalReviewCache[companyId] = totalReview
          return totalReview
        })
        .catch(() => totalReviewCache[companyId] = { count: 0, value: 5 })
    }
    return Promise.resolve(totalReviewCache[companyId])
  }
  const loadOccupied = (companyId: string, force?: boolean) => {
    if (!occupiedCache[companyId] || force) {
      return getCompanyOccupiedCount(companyId, dateRange.value.from, dateRange.value.to)
        .then(occupiedCount => {
          occupiedCache[companyId] = occupiedCount
          return occupiedCount
        })
        .catch(() => occupiedCache[companyId] = -1)
    }
  }
  const loadLatestOrderOrLastTwoWeeksOrderCountHours = (companyId: string, force?: boolean) => {
    if (force) {
      return doLoadLatestOrderOrLastTwoWeeksOrderCountHours(companyId)
    }
    if (latestOrderHoursCache[companyId] !== undefined) {
      return Promise.resolve(latestOrderHoursCache[companyId])
    }
    if (lastTwoWeeksOrderCountCache[companyId] !== undefined || force) {
      return Promise.resolve(lastTwoWeeksOrderCountCache[companyId])
    }
    return doLoadLatestOrderOrLastTwoWeeksOrderCountHours(companyId)
  }

  const doLoadLatestOrderOrLastTwoWeeksOrderCountHours = (companyId: string) => {
    return getCompanyActivity(companyId)
      .then(activity => {
        latestOrderHoursCache[companyId] = activity.lastOrderHoursAgo
        lastTwoWeeksOrderCountCache[companyId] = activity.lastTwoWeeksOrderCount
      })
      .catch(() => {
        latestOrderHoursCache[companyId] = -1
        lastTwoWeeksOrderCountCache[companyId] = -1
      })
  }
  const loadReviews = (companyId: string, force?: boolean) => {
    if (!reviewsCache[companyId] || force) {
      return getReviewsByCompany(companyId, { page: 0, size: 100, descending: true, sortBy: 'created' })
        .then(reviews => {
          reviewsCache[companyId] = reviews.data
          return reviews
        })
        .catch(() => reviewsCache[companyId] = [])
    }
  }
  const loadClosest3Places = (companyId: string, coordinates: Coordinates, language: Language) => {
    if (!closest3PlacesCache[`${ companyId }_${ language }`] && !loading3PlacesCache[companyId]) {
      //loading3PlacesCache[companyId] = true
      // return closestPlaces(coordinates, language)
      //   .then(places => {
      //     closest3PlacesCache[`${ companyId }_${ language }`] = places
      //     loading3PlacesCache[companyId] = false
      //     return places
      //   })
      return Promise.resolve([])
    }
    return Promise.resolve(closest3PlacesCache[`${ companyId }_${ language }`])
  }
  const clearOccupied = (companyId?: string) => {
    if (companyId) {
      delete occupiedCache[companyId]
    } else {
      Object.keys(occupiedCache).forEach(key => delete occupiedCache[key])
    }
  }

  return {
    totalReview: totalReviewCache,
    occupied: occupiedCache,
    latestOrderHours: latestOrderHoursCache,
    lastTwoWeeksOrderCount: lastTwoWeeksOrderCountCache,
    reviews: reviewsCache,
    closest3Places: closest3PlacesCache,
    loadTotalReview,
    loadOccupied,
    loadLatestOrderOrLastTwoWeeksOrderCountHours,
    loadReviews,
    loadClosest3Places,
    clearOccupied
  }
}

export default function useCompanyCache() {
  return inject(companyCacheKey, () => useCompanyCache_())
}
