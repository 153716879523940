import { PreFetchOptions } from '@quasar/app-webpack'
import usePolicyHome from 'stores/policyHome'
import useUserPreview from 'stores/userPreview'
import useUserLocation from 'stores/userLocation'
import { DEFAULT_COUNTRY_KEY } from 'src/model/constants'
import useUserDetails from 'stores/userDetails'
import useAppData from 'stores/appData'

export default async function policyPrefetch (options: PreFetchOptions<any>) {
  const { language } = useUserDetails(options.store)
  const { initialLocation } = useUserLocation(options.store)
  const { country } = useUserPreview(options.store)
  const { loadOne } = usePolicyHome(options.store)
  const { loadReviews } = useAppData(options.store)

  const userCountry = options.currentRoute.query.country as string || country?.key || initialLocation?.countryKey || DEFAULT_COUNTRY_KEY

  await Promise.all([
    loadOne(userCountry),
    loadReviews(language),
  ])
}
