import { PreFetchOptions } from '@quasar/app-webpack'
import useUserDetails from 'stores/userDetails'
import useAppData from 'stores/appData'

export default async function basePrefetch(options: PreFetchOptions<any>) {
  const { language } = useUserDetails(options.store)
  const { loadReviews } = useAppData(options.store)

  await loadReviews(language)
}
