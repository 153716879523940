import { Container } from 'src/model/common.model'

export const ADDITION_TO_ICONS: Container<string> = {
  'LIFT_YES': 'elevator',
  'LIFT_NO': 'no-elevator',
  'VIDEO_YES': 'cctv',
  'VIDEO_NO': 'no-camera',
  'DISCOUNT': 'coupon',
  'SOCKET': 'plug',
  'WC': 'toilet',
  'WIFI': 'signal',
  'REST_ROOM': 'sleep',
  'FLOOR_GROUND': 'no-stairs',
  'FLOOR_1': 'stairs',
  'FLOOR_2': 'stairs',
  'FLOOR_3': 'stairs',
  'FLOOR_4': 'stairs',
  'FLOOR_5': 'stairs',
  'FLOOR_HIGH': 'stairs',
  'AC': 'ac',
  'SMOKE_RESTRICT': 'no-smoking',
  'SMOKE_DETECTORS': 'smoke-detectors',
  'PRIVATE_ENTRANCE': 'private-entrance',
  'PRIVATE_SPACE': 'private-space',
  'LOCKED_AREA': 'locked-area',
  'NO_PETS': 'no-pets',
}
