import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import useAnonymousApi from 'src/api/anonymous.api'
import { Content } from 'src/model/content.model'
import { isWorldCountry } from 'src/functions/utils'

export type PolicyCountries = 'RU'

const usePolicyHome = defineStore('policyHome', () => {
  const api = useAnonymousApi()

  const policy = ref<Content>(null as unknown as Content) as Ref<Content>
  const country = ref<PolicyCountries | null>(null)

  const loadOne = async (userCountry: string) => {
    country.value = isWorldCountry(userCountry) ? null : 'RU'
    policy.value = await api.getPolicy(country.value || '')
  }

  return {
    policy,
    country,
    loadOne
  }
})

export default usePolicyHome
