import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["i18n"]
const _hoisted_2 = {
  key: 1,
  class: "col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`justify-${ _ctx.justify }`, "row q-col-gutter-sm content-selector"]),
    i18n: _ctx.$options.name
  }, [
    (_ctx.$q.screen.gt.xs || _ctx.btnOnly)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options, (o) => {
          return (_openBlock(), _createElementBlock("div", {
            key: o,
            class: _normalizeClass(_ctx.btnColClass)
          }, [
            _createVNode(_component_q_btn, {
              class: _normalizeClass([{'content-selector__btn--inactive': o.value !== _ctx.modelValue}, "text-transform-none full-width"]),
              color: _ctx.color,
              label: o.label,
              disable: _ctx.disabled,
              "text-color": _ctx.textColor,
              padding: "sm xl",
              rounded: "",
              unelevated: "",
              onClick: ($event: any) => (_ctx.updateModel(o.value))
            }, null, 8, ["class", "color", "label", "disable", "text-color", "onClick"])
          ], 2))
        }), 128))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_q_tabs, {
            "indicator-color": _ctx.color,
            "model-value": _ctx.modelValue,
            class: "full-width",
            dense: "",
            "onUpdate:modelValue": _ctx.updateModel
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (o) => {
                return (_openBlock(), _createBlock(_component_q_tab, {
                  key: o.value,
                  label: o.label,
                  name: o.value,
                  disable: _ctx.disabled,
                  class: "text-transform-none"
                }, null, 8, ["label", "name", "disable"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["indicator-color", "model-value", "onUpdate:modelValue"])
        ]))
  ], 10, _hoisted_1))
}