import { ref } from 'vue'

export default function useLoadImage() {
  const loadingError = ref(false)
  const loadingFinished = ref(false)

  const onLoadFinished = () => {
    loadingFinished.value = true
  }
  const onLoadError = () => {
    loadingError.value = true
  }

  return {
    loadingFinished,
    loadingError,
    onLoadFinished,
    onLoadError
  }
}
