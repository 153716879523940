
import { defineComponent, PropType, ref } from 'vue'
import { useI18n$ } from 'boot/i18n'
import { QBtn, QCard, QCardSection, QResponsive } from 'quasar'
import { IMGKIT_URL } from 'src/model/constants'

const name = 'storing-card'

export interface StoringEntry {
  label: string
  item: string
}

export default defineComponent({
  name,
  components: {
    QCard,
    QCardSection,
    QBtn,
    QResponsive,
  },
  props: {
    entries: {
      type: Array as PropType<StoringEntry[]>,
      required: true
    },
    folder: {
      type: String,
      required: true
    },
    pictureRight: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const {
      st,
      tp,
      language
    } = useI18n$(name)

    const model = ref(0)

    return {
      IMGKIT_URL,
      model,
      tp,
    }
  }
})
