import { PreFetchOptions } from '@quasar/app-webpack'
import useCityState from 'stores/cityHome'
import useUserPreview from 'stores/userPreview'
import useAppData from 'stores/appData'
import { isPath, pathToKey } from 'src/functions/utils'
import useUserDetails from 'stores/userDetails'
import useUserLocation, { LocationType } from 'stores/userLocation'

import { Error404, ServiceTypeAlias, ServiceTypeAliases } from 'src/model/common.model'
import { ERROR_404 } from 'pages/names'

export default async function cityPrefetch(options: PreFetchOptions<any>, serviceTypeAlias?: ServiceTypeAlias) {
  const detailsState = useUserDetails(options.store)
  const previewState = useUserPreview(options.store)
  const cityState = useCityState(options.store)
  const appDataState = useAppData(options.store)
  const locationState = useUserLocation(options.store)

  const path = options.currentRoute.params.serviceTypeAlias as string
  const cityPath = options.currentRoute.params.city as string
  const city = pathToKey(cityPath)
  const key = pathToKey(path) as ServiceTypeAlias
  serviceTypeAlias = serviceTypeAlias || key

  const redirect404 = () => {
    if (options.ssrContext) {
      throw new Error404()
    } else {
      options.redirect({ name: ERROR_404 })
      return
    }
  }

  if (
    !ServiceTypeAliases.includes(serviceTypeAlias) ||
    ['LUGGAGE', 'SELF'].includes(key) ||
    !(path ? isPath(path) : true) ||
    !isPath(cityPath)
  ) {
    redirect404()
    return
  }

  await Promise.all([
    previewState.loadAllByCity(city),
    cityState.loadAll(city, serviceTypeAlias),
    appDataState.loadReviews(detailsState.language),
    appDataState.loadBagsCount(),
  ])
  if (!previewState.city || !previewState.city.services.includes(serviceTypeAlias)) {
    redirect404()
    return
  }
  locationState.setLocation({ point: previewState.city.center }, LocationType.GEO)
}
