
import { computed, defineComponent, PropType } from 'vue'
import { Runnable } from 'src/types/async'
import LargeBtn from 'src/components/simple/large-btn.vue'
import { useI18n } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'

const name = 'luggage-button'

export default defineComponent({
  name,
  components: {
    LargeBtn
  },
  props: {
    label: {
      type: String,
      required: false
    },
    runnable: {
      type: Function as PropType<Runnable>,
      required: false
    },
    query: {
      type: String,
      required: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup(props) {
    const { toMap } = useRoutes()
    const { t } = useI18n()

    const buttonLabel = computed(() => props.label || t('action.ourLocations'))
    const to = computed(() => toMap(props.query))

    const mainRunnable = () => props.runnable?.()

    return {
      buttonLabel,
      to,
      mainRunnable
    }
  }
})
