
import { defineComponent } from 'vue'
import { QIcon } from 'quasar'

const name = 'home-ads-card'

export default defineComponent({
  name,
  components: {
    QIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  setup() {
    return {}
  }
})
