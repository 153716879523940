
import { defineComponent } from 'vue'
import { INDEX } from 'pages/names'
import { QBtn } from 'quasar'
import IndexTemplate from 'components/template/index-template.vue'
import HeadingSectionTemplate from 'components/template/heading-section-template.vue'
import BecomePartnerCard from 'components/simple/home-page-template/become-partner-card.vue'
import StoringOptionCard from 'components/simple/storing-option-card.vue'
import indexPrefetch from 'src/compositions/prefetch/indexPrefetch'
import { useI18n$ } from 'boot/i18n'
import useRoutes from 'src/compositions/routes'

const name = INDEX

export default defineComponent({
  name,
  components: {
    QBtn,
    IndexTemplate,
    HeadingSectionTemplate,
    BecomePartnerCard,
    StoringOptionCard,
  },
  async preFetch(options) {
    await indexPrefetch(options, 'LUGGAGE')
  },
  setup() {
    const { tp } = useI18n$(name)
    const { toMap, toRootLuggage, toRootSelf, toRootAlias } = useRoutes()
    return {
      name,
      tp,
      toMap,
      toRootLuggage,
      toRootSelf,
      toRootAlias
    }
  }
})
