
import { defineComponent } from 'vue'
import { QImg, QSkeleton } from 'quasar'
import useLoadImage from 'src/compositions/loadImage'

const name = 'object-card'

export default defineComponent({
  name,
  components: {
    QImg,
    QSkeleton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    fallbackSrc: {
      type: String,
      default: '/images/no-image.jpg'
    },
    to: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      ...useLoadImage()
    }
  }
})
